import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { EReportStatus } from "../../models/common/models.enum";
import { IStateApp } from "../../models/redux";
import PaymentInfor from "./components/PaymentInfor";
import PaymentMethod from "./components/PaymentMethod";
import PaymentResult from "./components/PaymentResult";
import PaymentSteps, { EPaymentSteps } from "./components/PaymentSteps";
import PaymentSummary from "./components/PaymentSummary";
import { PaymentWrapper } from "./styles";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IPlan } from "../../models/plan";
import { IUser } from "../../models/user";
import PaymentService from "../../services/api/payment.service";
import { ICreatePaymentReq } from "../../models/payment";
import { useLocation } from "react-router-dom";
import { setPlanInforState } from "../../redux/planInfor/planInfor.duck";

export default function Payment() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [currentStep, setCurrentStep] = useState<EPaymentSteps | null>(
    EPaymentSteps.CustomerInfor
  );
  const [currentMethod, setCurrentMethod] = useState<number | null>(null);
  const [isInvoice, setIsInvoice] = useState<boolean>(false);
  const currentUser = useSelector<IStateApp, IUser>(
    appState => appState.user.data
  );
  const currentPlan = useSelector<IStateApp, IPlan | null>(
    appState => appState.planInfor.planInfor
  );

  const [invoiceInfor, setInvoiceInfor] = useState<{
    customerName: string;
    companyName: string;
    phoneNumbers: string;
    taxNumbers: string;
    address: string;
  }>();

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearPlanInfor());
  //   };
  // }, [location.pathname]);

  const validationSchema = Yup.object().shape({
    customerName: Yup.string().required(`${"Vui lòng nhập tên!"}`),
    companyName: Yup.string().required(`${"Vui lòng nhập tên công ty!"}`),
    phoneNumbers: Yup.string().required(`${"Vui lòng nhập số điện thoại!"}`),
    taxNumbers: Yup.string().required(`${"Vui lòng nhập mã số thuế!"}`),
    address: Yup.string().required(`${"Vui lòng nhập địa chỉ!"}`),
  }) as any;

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<{
    fullName?: string;
    email?: string;
    phoneNumbersUser?: string;
    customerName?: string;
    companyName?: string;
    phoneNumbers?: string;
    taxNumbers?: string;
    address?: string;
    paymentId?: number;
    planPackageId?: number;
  }>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: isInvoice ? yupResolver(validationSchema) : undefined,
  });

  useEffect(() => {
    if (!!currentUser.userId) {
      setValue("fullName", currentUser.fullName);
      setValue("email", currentUser.email);
      setValue("phoneNumbersUser", currentUser.phoneNumbers);
    }
  }, [currentUser]);

  const onSubmit = (value: {
    fullName?: string;
    email?: string;
    phoneNumbersUser?: string;
    customerName?: string;
    companyName?: string;
    phoneNumbers?: string;
    taxNumbers?: string;
    address?: string;
    paymentId?: number;
    planPackageId?: number;
  }) => {
    const invoice = {
      customerName: value.customerName || "",
      companyName: value.companyName || "",
      phoneNumbers: value.phoneNumbers || "",
      taxNumbers: value.taxNumbers || "",
      address: value.address || "",
    };
    setInvoiceInfor(invoice);
    setCurrentStep(EPaymentSteps.PaymentMethod);
  };

  const handlePayment = async () => {
    if (currentMethod !== undefined && currentPlan !== undefined) {
      const orderData = {
        customerName: invoiceInfor?.customerName,
        companyName: invoiceInfor?.companyName,
        phoneNumbers: invoiceInfor?.phoneNumbers,
        taxNumbers: invoiceInfor?.taxNumbers,
        address: invoiceInfor?.taxNumbers,
        paymentId: currentMethod,
        planPackageId: currentPlan?.id,
      };
      const removeEmpty: any = Object.fromEntries(
        Object.entries(orderData).filter(([_, v]) => {
          if (typeof v === "string") {
            return v.length > 0;
          } else {
            return true;
          }
        })
      );
      const res = await PaymentService.createOrder(removeEmpty);
      if (!res.isError && !!res.data) {
        if (!!res.data.id && !!currentPlan && !!res.data.paymentId) {
          dispatch(
            setPlanInforState({
              ...currentPlan,
              orderId: res.data.id,
              paymentId: res.data.paymentId,
            })
          );
        }
        setCurrentStep(EPaymentSteps.PaymentResult);
        const paymentData: ICreatePaymentReq = {
          orderType: "other",
          orderId: res.data.id,
          paymentId: res.data.paymentId.toString(),
          amount: currentPlan?.price || 0,
          orderDescription: currentPlan?.title || "",
          name: "string",
        };

        const resPayment = await PaymentService.createPayment(paymentData);

        if (!resPayment.isError && !!resPayment.data) {
          const newWindow = window.open(
            `${resPayment.data}`,
            "_blank",
            "noopener,noreferrer"
          );
          if (newWindow) newWindow.opener = null;
        }
      }
    }
  };

  return (
    <PaymentWrapper>
      <PaymentSteps
        currentStep={currentStep}
        handleChange={step => {
          setCurrentStep(step);
        }}
      />
      <div className="payment-content scrollbar-small">
        <PaymentInfor
          className={`${currentStep !== null && currentStep !== EPaymentSteps.CustomerInfor ? "hidden-box" : "visible-box"} tab-content`}
          onChangeStep={() => {}}
          errors={errors}
          control={control}
          onChangeInvoice={value => {
            setIsInvoice(value);
          }}
          isInvoice={isInvoice}
        />
        <PaymentMethod
          className={`${currentStep !== null && currentStep !== EPaymentSteps.PaymentMethod ? "hidden-box" : "visible-box"} tab-content`}
          onChangeMethod={value => {
            setCurrentMethod(value);
          }}
          invoiceInfor={invoiceInfor}
        />
        <PaymentResult
          className={`${currentStep !== null && currentStep !== EPaymentSteps.PaymentResult ? "hidden-box" : "visible-box"} tab-content`}
          onChangeStep={() => {
            setCurrentStep(EPaymentSteps.CustomerInfor);
          }}
        />
      </div>
      {currentStep !== null && currentStep !== EPaymentSteps.PaymentResult && (
        <PaymentSummary
          currentTab={currentStep}
          handleChange={step => {
            if (currentStep === EPaymentSteps.CustomerInfor) {
              handleSubmit(onSubmit)();
            } else {
              handlePayment();
            }
          }}
        />
      )}
    </PaymentWrapper>
  );
}
