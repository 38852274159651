import React, { useEffect, useRef, useState } from "react";
import { ReportFormWrapper } from "./styles";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ControlForm, Modal } from "../..";
import SelectCustom from "../../SelectCustom";
import VALUE_LIST, {
  DEFAULT_BASE_PAGING_RES,
} from "../../../common/constants/value.constant";
import { IGetProjectReq, IProject } from "../../../models/project";
import { EReportType } from "../../../models/common/models.enum";
import PageAuditReport from "./components/CreateReportForms/PageAuditForm";
import PageCompetitiveReport from "./components/CreateReportForms/PageCompetitiveForm";
import ProjectService from "../../../services/api/project.service";
import {
  IBasePagingRes,
  IBaseResponseModel,
} from "../../../models/common/models.type";
import { toggleMessage } from "../../Toast/Toast";
import DomainAuditReport from "./components/CreateReportForms/DomainAuditForm";
import DomainCompetitiveReport from "./components/CreateReportForms/DomainCompetitiveForm";
import {
  ICreateDomainCompatitiveReq,
  ICreatePageAuditReq,
  ICreatePageCompatitiveReq,
  ICreateReportCommonReq,
} from "../../../models/report";
import ReportService from "../../../services/api/report.service";
import { BehaviorSubject } from "rxjs";
import InputForm from "../../controls/InputForm";
import { toggleConfirmModal } from "../../ConfirmModal/ConfirmModal";
import { toggleCreateProjectModal } from "../CreateProjectModal";
import { Button } from "@mui/material";

interface CreateReportModalProps {
  open?: boolean;
  onClose?: () => void;
  projectInfor?: IProject;
  onCreateSuccess: () => void;
  typeReportProps?: EReportType;
  disabledSelectType?: boolean;
}

const createReportModalSubject = new BehaviorSubject<CreateReportModalProps>({
  open: false,
  typeReportProps: EReportType.DomainAudit,
  onCreateSuccess: () => {},
  onClose: () => {},
  projectInfor: undefined,
  disabledSelectType: false,
});

const defaultState = {
  open: false,
  typeReportProps: EReportType.DomainAudit,
  onCreateSuccess: () => {},
  onClose: () => {},
  projectInfor: undefined,
  disabledSelectType: false,
};

export const toggleCreateReportModal = (value: CreateReportModalProps) => {
  createReportModalSubject.next(value);
};

export default function CreateReportModal() {
  const [data, setData] = useState<CreateReportModalProps>(defaultState);
  const [dataRes, setDataRes] = useState<IBasePagingRes<IProject>>({
    ...DEFAULT_BASE_PAGING_RES,
  });

  const [params, setParams] = useState<IGetProjectReq>({
    pageIndex: 1,
    pageSize: 10,
    keySearch: "",
  });
  const [selectedProject, setSelectedProject] = useState<IProject>(
    dataRes.items[0]
  );
  const [typeReport, setTypeReport] = useState<EReportType>(
    EReportType.DomainAudit
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChooseLinkCount, setIsChooseLinkCount] = useState<boolean>(false);
  const [brandNameState, setBrandNameState] = useState<string>();

  useEffect(() => {
    const subscribe = createReportModalSubject.subscribe(value => {
      let newData = { ...value };
      setData(newData);
      if (value.typeReportProps !== undefined) {
        setTypeReport(value.typeReportProps);
      }
    });
    return () => {
      subscribe.unsubscribe();
      resetDomainAudit();
      resetDomainCompetitive();
      resetPageAudit();
      resetPageCompetitive();
    };
  }, []);

  let abortControllerRef = useRef<AbortController>(new AbortController());

  useEffect(() => {
    if (!!data.open) {
      getProjectList();
    }
  }, [params, data.projectInfor, data.open]);

  useEffect(() => {
    if (!!data.projectInfor) {
      setSelectedProject(data.projectInfor);
    }
  }, [data.projectInfor]);

  const rexgex = /^https?:\/\//i;

  const rexgexPage =
    /^(https?:\/\/)?(([a-zd]([a-zd-]*[a-zd])*).)+[a-z]{2,}?(\/[-a-zd%_.~+]+)/;

  const validationDomainAuditSchema = Yup.object().shape({
    brandName: Yup.string().required(`${"Vui lòng nhập tên thương hiệu!"}`),
  }) as any;

  const validationDomainAuditmaxCrawlSchema = Yup.object().shape({
    maxCrawlPages: Yup.number()
      .required()
      .min(1, "Nhập tối thiểu 1")
      .max(10000, "Nhập tối đa 10000"),
  });

  const validationDomainCompetitiveSchema = Yup.object().shape({
    target: Yup.string()
      .required(`${"Vui lòng nhập url!"}`)
      .matches(rexgex, "Vui lòng nhập đúng url!"),
    brandName: Yup.string().required(`${"Vui lòng nhập tên thương hiệu!"}`),
    compareWith: Yup.array(
      Yup.object({
        brandName: Yup.string().required("Vui lòng nhập tên thương hiệu!"),
        domainUrl: Yup.string()
          .required("Vui lòng nhập url!")
          .matches(rexgex, "Vui lòng nhập đúng url!"),
      })
    ),
  }) as any;

  const validationPageAuditSchema = Yup.object().shape({
    target: Yup.string()
      .required(`${"Vui lòng nhập url!"}`)
      .matches(rexgexPage, "Vui lòng nhập đúng url!"),
    keyword: Yup.string().required(`${"Vui lòng nhập từ khoá!"}`),
  }) as any;

  const validationPageCompetitiveSchema = Yup.object().shape({
    target: Yup.string()
      .required(`${"Vui lòng nhập url!"}`)
      .matches(rexgexPage, "Vui lòng nhập đúng url!"),
    keyword: Yup.string().required(`${"Vui lòng nhập từ khoá!"}`),
    compareWith: Yup.array(
      Yup.object({
        url: Yup.string()
          .required("Vui lòng nhập url!")
          .matches(rexgexPage, "Vui lòng nhập đúng url!"),
      })
    ),
  }) as any;

  const {
    control: controlDomainAudit,
    handleSubmit: handleSubmitDomainAudit,
    reset: resetDomainAudit,
    formState: { errors: errorsDomainAudit },
  } = useForm<ICreateReportCommonReq>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      projectId: "",
      target: "",
      brandName: "",
    },
    resolver: yupResolver(
      isChooseLinkCount
        ? validationDomainAuditmaxCrawlSchema
        : validationDomainAuditSchema
    ),
  });

  const {
    control: controlDomainCompetitive,
    handleSubmit: handleSubmitDomainCompetitive,
    setValue: setValueDomainCompetitive,
    reset: resetDomainCompetitive,
    formState: { errors: errorsDomainCompetitive },
  } = useForm<ICreateDomainCompatitiveReq>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      projectId: "",
      target: "",
      brandName: "",
      compareWith: [{ brandName: "", domainUrl: "" }],
    },
    resolver: yupResolver(validationDomainCompetitiveSchema),
  });

  const {
    control: controlPageAudit,
    handleSubmit: handleSubmitPageAudit,
    reset: resetPageAudit,
    formState: { errors: errorsPageAudit },
  } = useForm<ICreatePageAuditReq>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      projectId: "",
      target: "",
      brandName: "",
      keyword: "",
    },
    resolver: yupResolver(validationPageAuditSchema),
  });

  const {
    control: controlPageCompetitive,
    handleSubmit: handleSubmitPageCompetitive,
    reset: resetPageCompetitive,
    formState: { errors: errorsPageCompetitive },
  } = useForm<ICreatePageCompatitiveReq>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      projectId: "",
      target: "",
      brandName: "",
      keyword: "",
      compareWith: [{ url: "" }],
    },
    resolver: yupResolver(validationPageCompetitiveSchema),
  });

  const getProjectList = async () => {
    const resData = await ProjectService.getProjectList(params);
    if (!!resData && !resData.isError) {
      const newArray = resData.data?.items?.map(item => ({
        ...item,
        id: item.projectId,
      }));
      if (resData.data.page === 1 && resData.data.items.length === 0) {
        toggleConfirmModal({
          open: true,
          title: `Không có dự án`,
          content: `Tài khoản của bạn hiện không có dự án nào, vui lòng tạo dự án trước khi tạo báo cáo!`,
          onSubmit: () => asyncFuncCreateProject(),
          submitText: "Tạo dự án",
          type: "infor",
          isNotCancel: true,
        });
      } else {
        convertData({ ...resData.data, items: newArray });
      }
    } else {
      toggleMessage({
        type: "error",
        title: "Không thành công",
        message: "Lấy dữ liệu không thành công!",
      });
    }
  };

  const convertData = (dataProject?: IBasePagingRes<IProject>) => {
    if (dataProject) {
      if (dataProject?.page === 1) {
        setDataRes(dataProject);
        if (!data.projectInfor) {
          setSelectedProject(dataProject.items[0]);
        }
      } else {
        setDataRes(pre => ({
          ...dataProject,
          items: [...pre.items, ...dataProject.items],
        }));
      }
    }
  };

  const asyncFuncCreateProject: () => Promise<void> = async () => {
    toggleCreateProjectModal({
      open: true,
      onCreateSuccess: () => {
        getProjectList();
      },
    });
  };

  const hanldeChangeParams = () => {
    if (dataRes.page * dataRes.pageSize < dataRes.total) {
      setParams({ ...params, pageIndex: params.pageIndex + 1 });
    }
  };

  const onSubmitDomainAudit = async (v: ICreateReportCommonReq) => {
    if (
      !v.maxCrawlPages &&
      (typeReport === EReportType.DomainAudit ||
        typeReport === EReportType.DomainAuditSEO)
    ) {
      setBrandNameState(v.brandName);
      setIsChooseLinkCount(true);
    } else {
      setIsLoading(true);
      if (!!selectedProject && !!selectedProject.projectId) {
        let remakeDomain = selectedProject.domain;

        if (selectedProject.domain.endsWith("/")) {
          remakeDomain = remakeDomain.substring(0, remakeDomain.length - 1);
        }
        const dataSubmit = {
          ...v,
          projectId: selectedProject.projectId,
          target: remakeDomain,
          brandName:
            typeReport === EReportType.DomainAudit ||
            typeReport === EReportType.DomainAuditSEO
              ? brandNameState
              : v.brandName,
        };
        let res: IBaseResponseModel<any> = {
          data: {},
          success: false,
          message: null,
          statusCode: 500,
          isError: true,
        };
        if (typeReport === EReportType.DomainAudit) {
          res = await ReportService.createDomainAudit(
            dataSubmit,
            abortControllerRef.current.signal
          );
        } else if (typeReport === EReportType.DomainAuditSEO) {
          res = await ReportService.createDomainAuditSEO(
            dataSubmit,
            abortControllerRef.current.signal
          );
        } else if (typeReport === EReportType.DomainAuditUX) {
          res = await ReportService.createDomainAuditUX(
            { ...dataSubmit, reportType: "" },
            abortControllerRef.current.signal
          );
        }
        handleCallApi(res);
      } else {
        setIsLoading(false);
        toggleMessage({
          type: "error",
          title: "Tạo không thành công",
          message: "Tạo báo cáo không thành công, vui lòng thử lại!",
        });
      }
    }
  };

  const onSubmitDomainCompetitive = async (v: ICreateDomainCompatitiveReq) => {
    setIsLoading(true);
    if (!!selectedProject && !!selectedProject.projectId) {
      const compareWithRemake = v.compareWith.map(value => {
        let remakeDomain = selectedProject.domain;

        if (selectedProject.domain.endsWith("/")) {
          remakeDomain = remakeDomain.substring(0, remakeDomain.length - 1);
        }

        return { ...value, domainUrl: remakeDomain };
      });
      let remakeDomain = "";
      if (v.target.startsWith("https://")) {
        remakeDomain = v.target.substring(8);
      } else {
        remakeDomain = v.target;
      }
      const dataSubmit = {
        ...v,
        projectId: selectedProject.projectId,
        brandName: selectedProject.projectName,
        compareWith: compareWithRemake,
        target: remakeDomain,
      };

      const res = await ReportService.createDomainCompetitive(
        dataSubmit,
        abortControllerRef.current.signal
      );

      handleCallApi(res);
    } else {
      setIsLoading(false);

      toggleMessage({
        type: "error",
        title: "Tạo không thành công",
        message: "Tạo báo cáo không thành công, vui lòng thử lại!",
      });
    }
  };

  const onSubmitPageAudit = async (v: ICreatePageAuditReq) => {
    setIsLoading(true);
    if (!!selectedProject && !!selectedProject.projectId) {
      const dataSubmit = {
        ...v,
        projectId: selectedProject.projectId,
      };
      delete dataSubmit.brandName;
      const res = await ReportService.createPageAudit(
        dataSubmit,
        abortControllerRef.current.signal
      );
      handleCallApi(res);
    } else {
      setIsLoading(false);
      toggleMessage({
        type: "error",
        title: "Tạo không thành công",
        message: "Tạo báo cáo không thành công, vui lòng thử lại!",
      });
    }
  };
  const onSubmitPageCompetitive = async (v: ICreatePageCompatitiveReq) => {
    setIsLoading(true);
    if (!!selectedProject && !!selectedProject.projectId) {
      const dataSubmit = {
        ...v,
        projectId: selectedProject.projectId,
      };

      delete dataSubmit.brandName;
      const res = await ReportService.createPageCompetitive(
        dataSubmit,
        abortControllerRef.current.signal
      );
      handleCallApi(res);
    } else {
      setIsLoading(false);
      toggleMessage({
        type: "error",
        title: "Tạo không thành công",
        message: "Tạo báo cáo không thành công, vui lòng thử lại!",
      });
    }
  };

  const handleCallApi = (value: IBaseResponseModel<any>) => {
    if (!value.isError) {
      data.onCreateSuccess();
      onCancel();
      toggleMessage({
        type: "success",
        title: "Tạo thành công",
        message: "Tạo thành công báo cáo",
      });
    } else if (value.message === "canceled") {
      toggleMessage({
        type: "error",
        title: "Thao tác bị huỷ",
        message: "Thao tác tạo báo cáo đã bị huỷ!",
      });
    } else {
      toggleMessage({
        type: "error",
        title: "Tạo không thành công",
        message: "Tạo báo cáo không thành công, vui lòng thử lại!",
      });
    }
    setIsLoading(false);
  };

  const HanldeCancelApi = () => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
  };

  const onCancel = () => {
    resetDomainAudit();
    resetDomainCompetitive();
    resetPageAudit();
    resetPageCompetitive();
    setIsChooseLinkCount(false);
    setBrandNameState(undefined);
    createReportModalSubject.next({
      open: false,
      typeReportProps: EReportType.DomainAudit,
      onCreateSuccess: () => {},
      onClose: () => {},
      projectInfor: undefined,
      disabledSelectType: false,
    });
    HanldeCancelApi();
    let params = { ...data };
    params.open = false;
    setData(params);
  };
  useEffect(() => {
    if (
      typeReport === EReportType.DomainCompetitive &&
      !!selectedProject.domain
    ) {
      if (selectedProject.domain.startsWith("https://")) {
        setValueDomainCompetitive("target", selectedProject.domain);
      } else {
        setValueDomainCompetitive(
          "target",
          `https://${selectedProject.domain}`
        );
      }
    }
  }, [typeReport, selectedProject]);

  return (
    <Modal
      title="Tạo mới báo cáo"
      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  eiusmo"
      open={data.open}
      onSubmit={() => {
        if (
          typeReport === EReportType.DomainAudit ||
          typeReport === EReportType.DomainAuditSEO ||
          typeReport === EReportType.DomainAuditUX
        ) {
          handleSubmitDomainAudit(onSubmitDomainAudit)();
        } else if (typeReport === EReportType.DomainCompetitive) {
          handleSubmitDomainCompetitive(onSubmitDomainCompetitive)();
        } else if (typeReport === EReportType.PageAudit) {
          handleSubmitPageAudit(onSubmitPageAudit)();
        } else {
          handleSubmitPageCompetitive(onSubmitPageCompetitive)();
        }
      }}
      onClose={onCancel}
      textSubmit={
        !isChooseLinkCount &&
        (typeReport === EReportType.DomainAudit ||
          typeReport === EReportType.DomainAuditSEO)
          ? "Chọn max Crawls"
          : "Tạo báo cáo"
      }
      textClose="Huỷ"
      disabledSubmit={typeReport === null}
      dataProject={dataRes}
      onChangeParams={hanldeChangeParams}
      valueProject={selectedProject}
      onChangeProject={v => {
        setSelectedProject(v);
      }}
      isLoading={isLoading}
      width="440px"
    >
      <Button
        size="xsmall"
        variant="contained"
        className="button-create-project"
        onClick={asyncFuncCreateProject}
        fullWidth
        sx={{ marginBottom: "12px" }}
      >
        <p className={`text-submit`}>Tạo dự án mới</p>
      </Button>
      <ReportFormWrapper>
        {isChooseLinkCount &&
        (typeReport === EReportType.DomainAudit ||
          typeReport === EReportType.DomainAuditSEO) ? (
          <ControlForm
            title="Số lượng page tối đa"
            infor="Số lượng page tối đa"
            classname="crawl-form"
          >
            <InputForm
              placeholder="1 - 10.000"
              required
              name="maxCrawlPages"
              errors={errorsDomainAudit}
              control={controlDomainAudit}
              size="small"
              type="number"
            />
          </ControlForm>
        ) : (
          <>
            <ControlForm
              title="Loại công cụ"
              infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            >
              <SelectCustom
                size="small"
                options={VALUE_LIST.REPORT_TYPE_OPTIONS}
                onChange={event => {
                  setTypeReport(event.target.value);
                }}
                placeholder="Chọn loại"
                isSelectedIcon
                value={typeReport}
                disabled={data.disabledSelectType}
              />
            </ControlForm>
            {typeReport === EReportType.DomainAudit ||
            typeReport === EReportType.DomainAuditSEO ||
            typeReport === EReportType.DomainAuditUX ? (
              <DomainAuditReport
                control={controlDomainAudit}
                errors={errorsDomainAudit}
                reset={resetDomainAudit}
              />
            ) : typeReport === EReportType.PageAudit ? (
              <PageAuditReport
                control={controlPageAudit}
                errors={errorsPageAudit}
                reset={resetPageAudit}
              />
            ) : typeReport === EReportType.PageCompetitive ? (
              <PageCompetitiveReport
                control={controlPageCompetitive}
                errors={errorsPageCompetitive}
                reset={resetPageCompetitive}
              />
            ) : (
              <DomainCompetitiveReport
                control={controlDomainCompetitive}
                errors={errorsDomainCompetitive}
                reset={resetDomainCompetitive}
              />
            )}
          </>
        )}
      </ReportFormWrapper>
    </Modal>
  );
}
