import { FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

interface AutoCompleteProps {
  error?: boolean;
}

export const Wrapper = styled(FormControl)<AutoCompleteProps>(
  ({ theme, error }) => ({
    "& .MuiAutocomplete-root": {
      "&.Mui-expanded": {
        "& .MuiInputAdornment-positionEnd": {
          transform: "rotate(-180deg)",
        },
        "& .MuiInputBase-root": {
          "&::after": {
            transform: "rotate(-180deg)",
          },
        },
      },
    },
    "& .MuiFormControl-root": {
      "&:hover": {
        "& fieldset": {
          border: `1px solid #0C97FF !important`,
        },
      },
    },
    "& .MuiInputAdornment-root": {
      transition: "all .2s linear",
    },
    "& .MuiButtonBase-root": {
      display: " none",
    },
    "& .MuiInputBase-root": {
      position: "relative",
      "&::after": {
        content: '""',
        position: "absolute",
        transition: "all .3s ease-in-out",
        background: `url() center/contain no-repeat`,
      },
      "& .MuiInputBase-input": {
        padding: "0 !important",
        width: "100% !important",
        fontWeight: variableStyles.fwMedium,
        color: variableStyles.NaturalColor950,
        "&::placeholder": {
          fontWeight: variableStyles.fwMedium,
          color: variableStyles.grey300,
          opacity: "1",
        },
      },
      "& fieldset": {
        padding: "0",
      },
      "&.Mui-disabled": {
        "& fieldset": {
          border: `1px solid ${variableStyles.NaturalColor100}!important`,
        },
      },
    },
    "& .helper-text-error": {
      display: "flex",
      alignItems: "center",
      gap: "4px",
      justifyContent: "flex-start",
      marginTop: "8px",
      "& .input-search-error": {
        fontWeight: variableStyles.fwRegular,
        fontSize: "13px",
        lineHeight: "18.2px",
        color: variableStyles.Warning800,
      },
    },

    "@media (min-width:768px)": {
      "& .MuiInputBase-root": {
        padding: "12px 16px !important",
        "&::after": {
          width: "20px",
          height: "20px",
          top: "calc(50% - 10px)",
          right: "16px",
        },

        "& fieldset": {
          borderRadius: "8px",
        },
      },
    },
  })
);
