import React, { useEffect, useState } from "react";
import CheckToolTitle from "../../components/CheckToolTitle";
import { BacklinkDetailWrapper } from "./styles";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTE_PATH } from "../../../../common/constants/app.constant";
import queryString from "query-string";
import { FreeAuditCheckTitle, TabsCustom } from "../../../../components";
import { IValue } from "../../../../models/common/models.type";
import { ETypeLinkCheck } from "../../../../models/common/models.enum";
import BacklinkOverviewTab from "./components/BacklinkTabs/BacklinkOverviewTab";
import BacklinkAnchorTextTab from "./components/BacklinkTabs/BacklinkAnchorTextTab";
import BacklinkIndexPageTab from "./components/BacklinkTabs/BacklinkIndexPageTab";
import BacklinkReferringDomainTab from "./components/BacklinkTabs/BacklinkReferringDomainTab";
import BacklinkReferringIPSTab from "./components/BacklinkTabs/BacklinkReferringIPSTab";

export enum EBacklinkCheckTabs {
  Overview = "overview",
  AnchorText = "anchortext",
  ReferringDomain = "referringdomain",
  ReferringIPS = "referringips",
  IndexPage = "indexpage",
}

export interface IDataFilterBacklinkType {
  target: string | null;
  type: string | null;
  tab: string | null;
  searchLink: string | null;
  searchLinkType: string | null;
  typeLink: string | null;
  followType: string | null;
  backLinkType: string | null;
  reputationFrom: string | null;
  reputationTo: string | null;
  anchorText: string | null;
  domainSearch: string | null;
  pageSize: string | null;
  pageIndex: string | null;
}

export default function BackLinkCheck() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dataFilter: IDataFilterBacklinkType = {
    target: searchParams.get("target"),
    type: searchParams.get("type"),
    tab: searchParams.get("tab") || EBacklinkCheckTabs.Overview,
    searchLink: searchParams.get("searchlink"),
    searchLinkType: searchParams.get("searchlinktype"),
    typeLink: searchParams.get("typelink"),
    followType: searchParams.get("followtype"),
    backLinkType: searchParams.get("backlinktype"),
    reputationFrom: searchParams.get("reputationfrom"),
    reputationTo: searchParams.get("reputationto"),
    anchorText: searchParams.get("anchortext"),
    domainSearch: searchParams.get("domainsearch"),
    pageSize: searchParams.get("pagesize") || "10",
    pageIndex: searchParams.get("pageindex") || "1",
  };

  const dataTabs: IValue[] = [
    { value: EBacklinkCheckTabs.Overview, title: "Overview" },
    { value: EBacklinkCheckTabs.AnchorText, title: "Anchor text" },
    { value: EBacklinkCheckTabs.ReferringDomain, title: "Referring Domain" },
    { value: EBacklinkCheckTabs.ReferringIPS, title: "Referring IPS" },
    { value: EBacklinkCheckTabs.IndexPage, title: "Index page" },
  ];

  return (
    <BacklinkDetailWrapper>
      <FreeAuditCheckTitle
        title="Backlink"
        domain={dataFilter.target || ""}
        onSubmit={value => {
          const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
          const newParams = queryString.stringify({
            ...value,
            tab: dataFilter.tab,
          });
          navigate({
            pathname: `${path}`,
            search: `${newParams}`,
          });
        }}
        value={{
          target: dataFilter.target,
          type: dataFilter.type as ETypeLinkCheck,
        }}
      />
      <TabsCustom
        data={dataTabs}
        onChange={(v: EBacklinkCheckTabs) => {
          const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
          const newParams = queryString.stringify({
            tab: v,
            target: dataFilter.target,
            type: dataFilter.type,
          });
          navigate({
            pathname: `${path}`,
            search: `${newParams}`,
          });
        }}
        selectedValue={dataFilter.tab}
      />
      {dataFilter.tab === EBacklinkCheckTabs.Overview ? (
        <BacklinkOverviewTab
          dataFilter={dataFilter}
          onChangeParamsURL={value => {
            const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
            navigate({
              pathname: `${path}`,
              search: `${value}`,
            });
          }}
        />
      ) : dataFilter.tab === EBacklinkCheckTabs.AnchorText ? (
        <BacklinkAnchorTextTab
          onChangeParamsURL={value => {
            const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
            navigate({
              pathname: `${path}`,
              search: `${value}`,
            });
          }}
          dataFilter={dataFilter}
        />
      ) : dataFilter.tab === EBacklinkCheckTabs.ReferringDomain ? (
        <BacklinkReferringDomainTab
          onChangeParamsURL={value => {
            const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
            navigate({
              pathname: `${path}`,
              search: `${value}`,
            });
          }}
          dataFilter={dataFilter}
        />
      ) : dataFilter.tab === EBacklinkCheckTabs.ReferringIPS ? (
        <BacklinkReferringIPSTab />
      ) : (
        <BacklinkIndexPageTab
          onChangeParamsURL={value => {
            const path = generatePath(ROUTE_PATH.BACKLINK_CHECK);
            navigate({
              pathname: `${path}`,
              search: `${value}`,
            });
          }}
          dataFilter={dataFilter}
        />
      )}
    </BacklinkDetailWrapper>
  );
}
