import React from "react";
import { LineChartCustom } from "../../../../../../../components";
import { IChartValue } from "../../../../../../../models/common/models.type";
import { IssueChartWrapper } from "./styles";

interface IssueChartProps {
  data: {
    title: string;
    index: number;
    diffrent: number;
    chartData: IChartValue[];
    status: "error" | "warning" | "notes";
  };
}

export default function IssueChart(props: IssueChartProps) {
  const { data } = props;

  return (
    <IssueChartWrapper>
      <div className="chart-header flex-between-align-center">
        <p className="header-title">{data.title}</p>
        <button className="header-button">Chi tiết</button>
      </div>
      <div className="chart-overview flex-start-align-center">
        <p className="overview-index">{data.index}</p>
        <p className="overview-difference">
          <span>{data.diffrent} </span>so với tuần trước
        </p>
      </div>
      <LineChartCustom
        data={data.chartData}
        onChangeFilter={(v: string) => {}}
        XAxisKey="date"
        YAxisKey="test"
        toolTipText="Lưu lượng"
        type={"linear"}
        chartHeight="80px"
        wrapperHeight="80px"
        YAxisWidth={20}
      />
    </IssueChartWrapper>
  );
}
