import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const ProfileWrapper = styled("div")(() => ({
  padding: "30px",
  maxWidth: "954px",
  margin: "0 auto",
  "@keyframes hidden-box": {
    "0%": {
      transform: "translateX(0)",
    },
    "75%": {
      transform: "translateX(calc(-100% - 10px))",
    },
    "100%": {
      display: "none",
      transform: "translateX(calc(-100% - 10px))",
    },
  },

  "@keyframes show-box": {
    "0%": {
      transform: "translateX(calc(-100% - 10px))",
    },
    "100%": { transform: "translateX(0)" },
  },
  "& .profile-header": {
    padding: "7px 0 32px",
    "& .header-infor": {
      gap: "16px",
      "& .infor-content": {
        "& .content-name": {
          color: variableStyles.NaturalColor1000,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "24px",
          lineHeight: "24px",
          marginBottom: "8px",
        },
        "& .content-email": {
          color: variableStyles.NaturalColor900,
          fontWeight: variableStyles.fwRegular,
          fontSize: "16px",
          lineHeight: "16px",
        },
      },
    },
  },
  "& .profile-tabs": {
    "& .tabs-list": {
      gap: "24px",
      borderBottom: `1px solid #0000001A`,
      marginBottom: "24px",
      "& li": {
        position: " relative",
        paddingBottom: "8px",
        "&::after": {
          content: '""',
          width: "100%",
          height: "1px",
          backgroundColor: variableStyles.GreenPrimaryColor500,
          position: "absolute",
          bottom: "-1px",
          left: "0",
          transform: "scale(0)",
          transformOrigin: "right",
          transition: "all .2s linear",
        },
        "&:hover, &.active": {
          "&::after": {
            transform: "scale(1)",
          },
        },
        "& button": {
          backgroundColor: "transparent",
          color: variableStyles.GreenPrimaryColor500,
          fontWeight: variableStyles.fwBold,
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
    },
  },
  "& .profile-box-wrapper": {
    backgroundColor: variableStyles.colorWhite,
    border: `1px solid ${variableStyles.NaturalColor200}`,
    boxShadow: "0px 2px 3.5px 0px #0000000A",
    borderRadius: "12px",
    padding: "24px",
    height: "calc(100vh - 316px)",
    overflow: "auto",
  },
  "& .header-plan-box": {
    minWidth: "204px",
  },
  "& .visible-box": {
    willChange: "display, transform",
    animation: "show-box .2s linear forwards",
  },
  "& .hidden-box": {
    willChange: "display, transform",
    animation: "hidden-box .2s linear forwards",
  },
}));

export const ProfileInforBoxWrapper = styled("form")(() => ({
  "& .infor-form": {
    "&:first-of-type": {
      paddingBottom: "36px",
      marginBottom: "36px",
      borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
    },
    "& .infor-form-title": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwMedium,
      fontSize: "20px",
      lineHeight: "20px",
      marginBottom: "24px",
    },
    "& .infor-form-wrapper": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gap: "16px",
    },
    "& .infor-business-form-wrapper": {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gridTemplateRows: "1fr 1fr",
      gap: "16px",
      marginBottom: "36px",
      "& .address-input": {
        gridColumn: "1 / 4",
        gridRow: "2",
      },
    },
  },
}));

export const ProfilePaymentBoxWrapper = styled("div")(() => ({
  "& .payment-title": {
    color: variableStyles.NaturalColor1000,
    fontWeight: variableStyles.fwMedium,
    fontSize: "20px",
    lineHeight: "20px",
    marginBottom: "24px",
  },
  "& .payment-top-wrapper": {
    paddingBottom: "24px",
    marginBottom: "24px",
    borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
    "& .payment-overview": {
      backgroundColor: variableStyles.Info50,
      borderRadius: "12px",
      border: `1px solid ${variableStyles.Info200}`,
      padding: "24px 22px",
      maxWidth: "464px",
      "& .overview-text": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwMedium,
        fontSize: "13px",
        lineHeight: "18.2px",
        marginBottom: "24px",
      },
      "& .overview-credit-wrapper": {
        "& .credit-number": {
          color: variableStyles.blueSecondary950,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "40px",
          lineHeight: "40px",
        },
        "& .credit-text": {
          color: variableStyles.blueSecondary950,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "16px",
          lineHeight: "22.4px",
        },
      },
    },
  },
  "& .payment-bottom-wrapper": {
    "& .payment-methods": {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      marginBottom: "16px",
    },
    "& .payment-add-card": {
      backgroundColor: "transparent",
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "14px",
      lineHeight: "14px",
      gap: "6px",
      padding: "14px",
      borderRadius: "10px",
      border: `1px solid ${variableStyles.NaturalColor400}`,
      transition: "all .2s linear",
      "&:hover": {
        backgroundColor: variableStyles.GreenPrimaryColor400,
        border: `1px solid ${variableStyles.GreenPrimaryColor500}`,
      },
    },
  },
}));


export const HistoryBoxWrapper = styled("div")(() => ({
  "& .history-title": {
    color: variableStyles.NaturalColor1000,
    fontWeight: variableStyles.fwMedium,
    fontSize: "20px",
    lineHeight: "20px",
    marginBottom: "24px",
  },
  "& .history-status-tag": {
    padding: "4px 8px",
    borderRadius: "100px",
    fontWeight: variableStyles.fwRegular,
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    width: "max-content",
    "&.done": {
      border: `1px solid ${variableStyles.GreenPrimaryColor500}`,
      backgroundColor: variableStyles.GreenPrimaryColor100,
      color: variableStyles.GreenPrimaryColor500,
    },
    "&.fail": {
      border: `1px solid ${variableStyles.Error500}`,
      backgroundColor: variableStyles.Error100,
      color: variableStyles.Error500,
    },
  },
  "& .history-action-wrapper": {
    gap: "10px",
    "& button": {
      backgroundColor: "transparent",
      transition: "all 0.2s linear",
      padding: "5px",
      borderRadius: "6px",
      "&:hover": {
        backgroundColor: variableStyles.NaturalColor200,
      },
    },
  },
}));
