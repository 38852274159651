// style
import GlobalStyles from "../theme/globalStyles";
// theme
import ThemeConfig from "../theme";
// library
import { useDispatch, useSelector } from "react-redux";
// routes
import appRoutes from "../routes";
import { useRoutes } from "react-router";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { USER_ROLE_KEY, USER_TOKEN_KEY } from "../services/local/local-storage";
import { EReportStatus, Roles } from "../models/common/models.enum";
import {
  setPaymentSignalRState,
  setSignalRState,
  setSocketCrawlState,
} from "../redux/signalR/signalR.duck";
import { IStateApp } from "../models/redux";
import { IUser } from "../models/user";
import UserService from "../services/api/user.service";
import { setUserState } from "../redux/user/user.duck";

const Main = (props: {
  auditsignal?: {
    auditId: string;
    status: EReportStatus;
  };
  paymentauditsignal?: {
    orderId: string;
    status: string;
  };
}) => {
  const { auditsignal, paymentauditsignal } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const token = localStorage.getItem(USER_TOKEN_KEY);
  const role = localStorage.getItem(USER_ROLE_KEY);
  const content = useRoutes(appRoutes((role as Roles) || Roles.ViewReport));
  const currentUser = useSelector<IStateApp, IUser>(
    appState => appState.user.data
  );

  const [serverMessage, setServerMessage] = useState<string>("");
  const [webSocket, setWebSocket] = useState(
    new WebSocket(
      `ws://crawl.webaudit.io:8802/?x-authkey=pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp&userId=${currentUser?.userId}`
    )
  );
  useEffect(() => {
    if (
      !!auditsignal &&
      !!auditsignal.auditId &&
      auditsignal.auditId?.length > 0
    ) {
      dispatch(setSignalRState(auditsignal.auditId, auditsignal.status));
    }
  }, [auditsignal]);

  useEffect(() => {
    if (
      !!paymentauditsignal &&
      !!paymentauditsignal.orderId &&
      paymentauditsignal.orderId?.length > 0
    ) {
      dispatch(
        setPaymentSignalRState(
          paymentauditsignal.orderId,
          paymentauditsignal.status
        )
      );
    }
  }, [paymentauditsignal]);

  useEffect(() => {
    if (!!token && token.length > 0) {
      getUserInfor();
    }
  }, [token]);

  useEffect(() => {
    if (!!serverMessage && serverMessage.includes("auditId")) {
      const data = JSON.parse(serverMessage);

      if (!!data?.auditId && data.auditId?.length > 0) {
        dispatch(setSocketCrawlState(data.auditId, data.status));
      }
    }
  }, [serverMessage]);

  const getUserInfor = async () => {
    const res = await UserService.getSinglebyToken();
    if (!res.isError) {
      const data = res.data;
      dispatch(
        setUserState({
          userId: data.userId,
          fullName: data.fullName,
          phoneNumbers: data.phoneNumbers,
          email: data.email,
          companyName: data.companyName,
          isVerify: data.isVerify,
          status: data.status,
          role: data.role,
          avatar: "",
        })
      );
    }
  };
  return (
    <ThemeConfig>
      <GlobalStyles />
      {content}
    </ThemeConfig>
  );
};

export default Main;
