import React, { useEffect, useRef, useState } from "react";
import Modal from "..";
import { ControlForm, FormAutocomplete } from "../..";
import InputForm from "../../controls/InputForm";
import { useForm, useWatch } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { BehaviorSubject } from "rxjs";
import IconLink from "../../../assets/images/common/icon_link_line_grey.svg";
import { IProject, IProjectUpdateCreateReq } from "../../../models/project";
import { CreateProjectFormWrapper } from "./styles";
import ProjectService from "../../../services/api/project.service";
import { toggleMessage } from "../../Toast/Toast";
import { IValue } from "../../../models/common/models.type";
import RegionService from "../../../services/api/region.service";
import { IPlaceDataforSEOTemp } from "../../../models/models-temp";
import Autocomplete from "../../Autocomplete";

interface CreateProjectModalProps {
  open?: boolean;
  onCreateSuccess?: () => void;
  onUpdateSuccess?: () => void;
  onCancel?: () => void;
  dataProject?: IProject;
}

const createProjectModalSubject = new BehaviorSubject<CreateProjectModalProps>({
  open: false,
  onCreateSuccess: undefined,
  onUpdateSuccess: undefined,
  dataProject: undefined,
});

export const toggleCreateProjectModal = (value: CreateProjectModalProps) => {
  createProjectModalSubject.next(value);
};

export default function CreateProjectModal() {
  const [data, setData] = useState<CreateProjectModalProps>({
    open: false,
    onCreateSuccess: () => {},
    onUpdateSuccess: undefined,
    dataProject: undefined,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingLocation, setIsLoadingLocation] = useState<boolean>(false);
  const [listLocation, setListLocation] = useState<IValue[]>([]);
  const [dataResCountries, setDataResCountries] = useState<IValue[]>([]);
  const [companyInput, setCompanyInput] = useState<string>("");

  const timerOut = useRef<number>();

  useEffect(() => {
    const subscribe = createProjectModalSubject.subscribe(value => {
      let params = { ...value };
      setData(params);
      getCountrys();
    });

    return () => {
      subscribe.unsubscribe();
      reset();
      setData({
        open: false,
        onCreateSuccess: () => {},
        onUpdateSuccess: undefined,
        dataProject: undefined,
      });
    };
  }, []);

  const regex =
    /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}(\/)?$/;

  const validationSchema = Yup.object().shape({
    projectName: Yup.string().required(`${"Vui lòng nhập tên dự án"}`),
    domain: Yup.string()
      .required(`${"Vui lòng nhập URL"}`)
      .matches(regex, "Vui lòng nhập đúng URL"),
  }) as any;

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IProjectUpdateCreateReq>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      locationName: "",
      languageName: "Vietnamese",
      projectName: "",
      domain: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const countryWatch = useWatch({ control, name: "locationName" });

  const onCancel = () => {
    createProjectModalSubject.next({
      open: false,
      onCreateSuccess: undefined,
      onUpdateSuccess: undefined,
      dataProject: undefined,
    });
    data.onCancel && data.onCancel();
    reset();
    setData({ ...data, open: false, dataProject: undefined });
  };

  const onSubmit = async (value: IProjectUpdateCreateReq) => {
    // setIsLoading(true);
    if (!!data.dataProject && !!data.onUpdateSuccess) {
      const dataUpdate = { ...value, projectId: data.dataProject.projectId };
      const res = await ProjectService.updateProject(dataUpdate);
      if (!!res && !res.isError) {
        toggleMessage({
          type: "success",
          title: "Chỉnh sửa thành công",
          message: "Chỉnh sửa dự án thành công!",
        });
        data.onUpdateSuccess();
        setIsLoading(false);
        reset();
        setData({ ...data, open: false });
      } else {
        toggleMessage({
          type: "error",
          title: "Chỉnh sửa không thành công",
          message: "Chỉnh sửa dự án không thành công!",
        });
        setIsLoading(false);
      }
    } else if (!!data.onCreateSuccess) {
      const valueRemake = { ...value };
      const locationSelected = listLocation.find(
        l => l.value === valueRemake.projectName
      );
      let placeId = "";
      if (!!locationSelected) {
        placeId = locationSelected.value;
        valueRemake.projectName = locationSelected.title;
      }
      if (valueRemake.domain.startsWith("https://")) {
        valueRemake.domain = valueRemake.domain.substring(8);
      } else if (valueRemake.domain.startsWith("http://")) {
        valueRemake.domain = valueRemake.domain.substring(7);
      }
      if (valueRemake.domain.endsWith("/")) {
        valueRemake.domain = valueRemake.domain.substring(
          0,
          valueRemake.domain.length - 1
        );
      }
      if (value.locationName === "Vietnam") {
        valueRemake.languageName = "Vietnamese";
      } else {
        valueRemake.languageName = "English";
      }
      const res = await ProjectService.createProject({
        ...valueRemake,
        placeId: placeId,
      });
      if (!!res && !res.isError) {
        toggleMessage({
          type: "success",
          title: "Tạo thành công",
          message: "Tạo dự án thành công!",
        });
        setIsLoading(false);
        data.onCreateSuccess();
        reset();
        setData({ ...data, open: false });
      } else {
        toggleMessage({
          type: "error",
          title: "Tạo không thành công",
          message: "Tạo dự án không thành công!",
        });
        setIsLoading(false);
      }
    }
  };

  const getCountrys = async () => {
    const res = await RegionService.getAllCountries();
    if (!res.isError && !!res.data) {
      const countryState = res.data.items
        .filter(
          f =>
            !f.displayName.includes("mobile") && !f.displayName.includes("Ext")
        )
        .map(item => ({
          title: item.displayName,
          value: item.displayName,
        }));
      setDataResCountries(countryState);
    }
  };

  const getListSearch = async (keyword: string) => {
    if (!!keyword && keyword.length > 0) {
      setIsLoadingLocation(true);
      const res: IPlaceDataforSEOTemp = await RegionService.getPlaceDataforSEO({
        language_name: "Vietnamese",
        location_name: countryWatch || "Ho Chi Minh City,Vietnam",
        depth: 5,
        keyword: keyword,
      });

      if (
        res.status_message === "Ok." &&
        res.tasks_error === 0 &&
        res?.tasks[0]?.result[0]?.items?.length > 0
      ) {
        const data: IValue[] = res.tasks[0].result[0].items.map(item => ({
          title: item.title,
          value: item.place_id,
          description: item.address || "",
        }));
        setListLocation(data);
        setIsLoadingLocation(false);
      } else {
        setIsLoadingLocation(false);
      }
    }
  };

  const onChangeInput = (keyword: string) => {
    if (timerOut.current) {
      clearTimeout(timerOut.current);
    }
    timerOut.current = window.setTimeout(() => {
      getListSearch(keyword);
    }, 1000);
  };

  useEffect(() => {
    if (!!data.dataProject && !!data.dataProject.projectId) {
      setValue("projectName", data.dataProject.projectName);
      setValue("domain", data.dataProject.domain);
    }
  }, [data.dataProject]);

  return (
    <Modal
      title={!!data.onUpdateSuccess ? "Chỉnh sửa dự án" : "Tạo mới dự án"}
      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  eiusmo"
      open={data.open}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onCancel}
      textSubmit={!!data.onUpdateSuccess ? "Chỉnh sửa" : "Tạo dự án"}
      textClose={"Huỷ"}
      isLoading={isLoading}
    >
      <CreateProjectFormWrapper onSubmit={handleSubmit(onSubmit)}>
        <ControlForm
          title="Quốc gia"
          infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
          classname="brand-form"
        >
          <FormAutocomplete
            name="locationName"
            control={control}
            errors={errors}
            maxHeight="350px"
            options={dataResCountries}
            placeholder="Tên quốc gia"
          />
        </ControlForm>
        <ControlForm
          title="Tên doanh nghiệp"
          infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
          classname="brand-form"
        >
          <Autocomplete
            maxHeight="350px"
            options={listLocation}
            placeholder="Tên công ty"
            onInputChange={value => {
              onChangeInput(value);
              setCompanyInput(value);
            }}
            isLoading={isLoadingLocation}
            isKeepClose
            onChange={v => {
              setValue("projectName", v);
              setCompanyInput(v);
            }}
            errors={errors.projectName}
            disabled={!countryWatch}
            value={companyInput}
          />
        </ControlForm>
        <ControlForm
          title="Domain"
          infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
          classname="url-form"
        >
          <InputForm
            placeholder="https://..."
            required
            name="domain"
            errors={errors}
            control={control}
            size="small"
            startIcon={IconLink}
            isLink
            disabled={!!data.onUpdateSuccess}
          />
        </ControlForm>
      </CreateProjectFormWrapper>
    </Modal>
  );
}
