import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";
import IconCheck from "../../../../assets/images/common/icon_check_line_white.svg";

export const PricingDetailWrapper = styled("div")(() => ({
  padding: "24px",
  maxWidth: "1132px",
  margin: "0 auto",
  height: "calc(100vh - 52px)",
  overflow: "auto",
  "& .pack-infor": {
    borderRadius: "16px",
    backgroundColor: variableStyles.blueSecondary50,
    padding: "62px",
    gap: "62px",
    marginBottom: "40px",
    "& .infor-detail": {
      flex: "1 1 auto",
      "& .detail-tag": {
        borderRadius: "6px",
        backgroundColor: variableStyles.colorWhite,
        padding: "9px 12px",
        width: "max-content",
        gap: "8px",
        boxShadow: "0px 2px 4px 0px #00000029",
        marginBottom: "32px",
        "& p": {
          color: variableStyles.NaturalColor950,
          fontWeight: variableStyles.fwMedium,
          fontSize: "14px",
          lineHeight: "20px",
        },
      },
      "& .detail-title": {
        color: variableStyles.Info900,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "32px",
        lineHeight: "48px",
        marginBottom: "32px",
      },
      "& .detail-description": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwRegular,
        fontSize: "16px",
        lineHeight: "24px",
        marginBottom: "32px",
      },
      "& .list-benefit": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        gap: "22px",
        marginBottom: "32px",
        "& span": {
          paddingLeft: "32px",
          color: variableStyles.NaturalColor1000,
          fontWeight: variableStyles.fwMedium,
          textAlign: "left",
          fontSize: "18px",
          lineHeight: "26px",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            left: "4px",
            top: "50%",
            width: "18px",
            height: "18px",
            background: `url(${IconCheck}) center/contain no-repeat`,
            zIndex: "1",
            transform: "translateY(-50%)",
          },
          "&::after": {
            content: '""',
            position: "absolute",
            left: "0",
            top: "50%",
            width: "26px",
            height: "26px",
            backgroundColor: variableStyles.GreenPrimaryColor400,
            borderRadius: "50%",
            transform: "translateY(-50%)",
          },
        },
      },
      "& .detail-demo-button": {
        border: `1px solid ${variableStyles.NaturalColor400}`,
        borderRadius: "10px",
        padding: "16px 27px",
        backgroundColor: "transparent",
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "14px",
        lineHeight: "14px",
      },
    },
  },
  "& .infor-package": {
    width: "382px",
    flex: "0 0 auto",
  },
  "& .package-relate": {
    "& .slider": {
      position: "relative",
    },
    "& .slider-package": {
      maxWidth: "382px",
    },
    "& .slick-list": {
      padding: "10px 0",
    },
    "& .slick-track": { display: "flex !important" },
    "& .slick-slide": {
      height: "inherit !important",
      "&  > div:first-child": {
        height: "100%",
      },
    },
    "& .slider-button": {
      width: "50px",
      height: "50px",
      backgroundColor: "rgba(255, 255, 255, 0.3)",
      position: "absolute",
      top: "50%",
      zIndex: "99999",
      borderRadius: "50%",
      transition: "all .2s linear",
      boxShadow: "0px 2px 4px 0px #00000029",
      "&:hover": {
        backgroundColor: variableStyles.colorWhite,
      },
      "&.button-prev": { left: "0", transform: "translateY(-50%)" },
      "&.button-next": {
        right: "0",
        transform: "translateY(-50%) rotateY(180deg)",
      },
    },
  },
  "@media (min-width:1001px)": {
    "& .slider-package": {
      margin: "0 20px",
      width: "calc(100% - 40px)",
    },
  },
  "@media (max-width:1000px)": {
    "& .slider-package": {
      margin: "0 10px",
      width: "calc(100% - 20px)",
    },
  },
}));
