import React from "react";
import ImageCustom from "../Image";
import { PaymentMethodBoxWrapper } from "./styles";
import IconDelete from "../../assets/images/common/icon_delete_line_grey.svg";
import IconCheckBox from "../../assets/images/common/icon_checkbox_circle.svg";
import IconCheckBoxActive from "../../assets/images/common/icon_checkbox_circle_active.svg";
import { CheckBoxWrapper } from "../Checkbox/CheckBoxCustom/styles";
import { IOtherPaymentMethod } from "../../models/payment";
import LogoMomo from "../../assets/images/temp/logo_momo.png";
import LogoVNPAY from "../../assets/images/common/logo_vnpay.png";

interface PaymentMethodBoxProps {
  isDefault?: boolean;
  hasCheckbox?: boolean;
  className?: string;
  dataCard?: IOtherPaymentMethod;
  isSelected?: boolean;
  onChange?: (value: number) => void;
}

export default function PaymentMethodBox(props: PaymentMethodBoxProps) {
  const {
    isDefault = false,
    hasCheckbox = false,
    className = "",
    dataCard,
    isSelected = false,
    onChange,
  } = props;

  return (
    <PaymentMethodBoxWrapper
      className={`flex-between-align-top ${!!hasCheckbox ? "hasCheckbox" : ""} ${!!hasCheckbox && isSelected ? "active" : ""} ${className}`}
      onClick={() => {
        if (!!hasCheckbox && !!onChange && !!dataCard) {
          onChange(dataCard?.id);
        }
      }}
    >
      {!!dataCard && (
        <>
          <div className="box-card flex-start-align-top">
            <figure className="card-image">
              <ImageCustom
                src={dataCard.title === "MOMO" ? LogoMomo : LogoVNPAY}
                alt="Icon-Check-Active"
                width={"68px"}
                height={"68px"}
              />
            </figure>
            <div className="card-infor">
              {!!dataCard.title && (
                <p className="infor-number fit-one-line">{dataCard.title}</p>
              )}
              {/* {!!dataCard.cardName && (
                <p className="infor-name fit-one-line">{dataCard.cardName}</p>
              )}
              {!!dataCard.cardCVV && (
                <p className="infor-cvv fit-one-line">CVV {dataCard.cardCVV}</p>
              )} */}
            </div>
            {!!hasCheckbox && (
              <CheckBoxWrapper
                id={`checkbox-${"value"}`}
                onChange={() => {
                  if (!!hasCheckbox && !!onChange) {
                    onChange(dataCard?.id);
                  }
                }}
                value={""}
                checked={isSelected}
                className="infor-checkbox"
                checkedIcon={
                  <ImageCustom
                    src={IconCheckBoxActive}
                    alt="Icon-Check-Active"
                    width={"24px"}
                    height={"24px"}
                  />
                }
                icon={
                  <ImageCustom
                    src={IconCheckBox}
                    alt="Icon-Check"
                    width={"24px"}
                    height={"24px"}
                  />
                }
              />
            )}
          </div>
          {!hasCheckbox && (
            <div className="box-action flex-end-align-center">
              {isDefault ? (
                <p className="action-default-payment">Mặc định</p>
              ) : (
                <button>Đặt làm mặc định</button>
              )}
              <button>
                <ImageCustom
                  isAvatar
                  src={IconDelete}
                  alt={"Delete-icon"}
                  width={"18px"}
                  height={"18px"}
                />
              </button>
            </div>
          )}
        </>
      )}
    </PaymentMethodBoxWrapper>
  );
}
