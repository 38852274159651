import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import { IBaseResponseModel } from "../../models/common/models.type";
import {
  ICreateOrderReq,
  ICreateOrderRes,
  ICreatePaymentReq,
  IOtherPaymentMethod,
} from "../../models/payment";

const PAYMENT_API = ApiEndpoints.PAYMENT_API;
const ORDER_API = ApiEndpoints.ORDER_API;
const baseURL = envConfig.API_KEY_PROJECT_ENDPOINT;

const getPaymentMethods = (): Promise<
  IBaseResponseModel<IOtherPaymentMethod[]>
> => {
  return api.get(baseURL, PAYMENT_API.GET_PAYMENT_LIST.url);
};

const createOrder = (
  data: ICreateOrderReq
): Promise<IBaseResponseModel<ICreateOrderRes>> => {
  return api.post(baseURL, ORDER_API.CREATE_ORDER.url, data);
};

const createPayment = (
  data: ICreatePaymentReq
): Promise<IBaseResponseModel<any>> => {
  return api.post(baseURL, PAYMENT_API.CREATE_PAYMENT.url, data);
};

const PaymentService = { getPaymentMethods, createOrder, createPayment };

export default PaymentService;
