import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import {
  IBasePagingRes,
  IBaseResponseModel,
} from "../../models/common/models.type";

import { IRegionRes } from "../../models/region";
import axios from "axios";
import { IPlaceDataforSEOTemp } from "../../models/models-temp";

const API_REGION = ApiEndpoints.REGION;
const baseURL = envConfig.API_KEY_ENDPOINT;
const baseGooglePlaceURL = envConfig.API_KEY_GOOGLE_PLACE_ENDPOINT;

const getAllCountries = (): Promise<
  IBaseResponseModel<IBasePagingRes<IRegionRes>>
> => {
  return api.get(baseURL, API_REGION.GET_ALL_COUNTRIES.url);
};

const getAllLanguage = (): Promise<
  IBaseResponseModel<IBasePagingRes<IRegionRes>>
> => {
  return api.get(baseURL, API_REGION.GET_ALL_LANGUAGE.url);
};

const getGGMapsPlacePlatform = () => {
  const options = {
    method: "POST",
    url: "https://places.googleapis.com/v1/places:searchText",
    body: { textQuery: "Spicy Vegetarian Food in Sydney, Australia" },
    headers: {
      "X-Goog-Api-Key": "your-rapid-key",
      "X-Goog-FieldMask": "AIzaSyDVffL2hR9JadX7TnfMoe1DSO8Dp3l3vNA",
    },
  };
  axios
    .request(options)
    .then(function ({ data }: { data: IBaseResponseModel<any> }) {
      return data;
    })
    .catch(function (error: any) {
      return error;
    });
};

const getPlaceDataforSEO: (params: {
  language_name: string;
  location_name: string;
  depth: number;
  keyword: string;
}) => Promise<IPlaceDataforSEOTemp> = (params: {
  language_name: string;
  location_name: string;
  depth: number;
  keyword: string;
}) => {
  const options = {
    method: "POST",
    url: "https://api.dataforseo.com/v3/serp/google/maps/live/advanced",
    data: { params },
    auth: { username: "tech@mikotech.vn", password: "a7db19b7fe55a641" },
  };
  const res = axios
    .request(options)
    .then(function ({ data }: { data: IPlaceDataforSEOTemp }) {
      return data;
    })
    .catch(function (error: any) {
      return error;
    });
  return res;
};

const RegionService = {
  getAllCountries,
  getAllLanguage,
  getGGMapsPlacePlatform,
  getPlaceDataforSEO,
};

export default RegionService;
