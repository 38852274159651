import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ImageCustom } from "../../../../components";
import { IValue } from "../../../../models/common/models.type";
import { IStateApp } from "../../../../models/redux";
import { PaymentStepsWrapper } from "../../styles";
import IconCheck from "../../../../assets/images/common/icon_check_line_white.svg";
import IconX from "../../../../assets/images/common/icon_close_line_white.svg";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../../../common/constants/app.constant";
import { IPlan } from "../../../../models/plan";

export enum EPaymentSteps {
  CustomerInfor,
  PaymentMethod,
  PaymentResult,
}

interface PaymentStepsProps {
  handleChange: (step: EPaymentSteps) => void;
  currentStep: EPaymentSteps | null;
}

export default function PaymentSteps(props: PaymentStepsProps) {
  const { handleChange, currentStep } = props;
  const navigate = useNavigate();

  const currentPlan = useSelector<IStateApp, IPlan | null>(
    appState => appState.planInfor.planInfor
  );

  const [status, setStatus] = useState<string | null>(null);

  const auditSignal = useSelector<
    IStateApp,
    { orderId: string; status: string | null }
  >(appState => appState.signalR.paymentAudit);

  const stepsListData: IValue[] = [
    { title: "Thông tin khách hàng", value: EPaymentSteps.CustomerInfor },
    { title: "Phương thức thanh toán", value: EPaymentSteps.PaymentMethod },
    {
      title:
        status === "00"
          ? "Thanh toán thành công"
          : status === "loading"
            ? "Đang xử lý"
            : "Thanh toán thất bại",
      value: EPaymentSteps.PaymentResult,
    },
  ];
  const handleChangeStep = () => {
    let step: EPaymentSteps;
    if (currentStep !== null) {
      switch (currentStep) {
        case EPaymentSteps.PaymentResult:
          step = EPaymentSteps.PaymentMethod;
          break;
        default:
          step = EPaymentSteps.CustomerInfor;
          break;
      }
      handleChange(step);
    }
  };
  const renderActive = (value: EPaymentSteps) => {
    let active: boolean = false;
    if (currentStep !== null) {
      switch (currentStep) {
        case EPaymentSteps.PaymentMethod:
          if (value === EPaymentSteps.CustomerInfor) {
            active = true;
          }
          break;
        case EPaymentSteps.CustomerInfor:
          active = false;
          break;
        default:
          active = true;
          break;
      }
    }
    return active;
  };

  useEffect(() => {
    if (!!currentPlan && !!currentPlan.orderId && !!auditSignal.status) {
      if (auditSignal.orderId === currentPlan.orderId) {
        setStatus(auditSignal.status);
      }
    } else if (!!currentPlan) {
      setStatus("loading");
    }
  }, [currentPlan, auditSignal]);

  return (
    <PaymentStepsWrapper>
      <div className="steps-wrapper flex-start-align-center">
        <Button
          size="xsmall"
          variant="outlined"
          className="steps-back"
          onClick={() => {
            if (currentStep === EPaymentSteps.CustomerInfor) {
              navigate(ROUTE_PATH.PRICING);
            } else {
              handleChangeStep();
            }
          }}
        >
          {currentStep === EPaymentSteps.CustomerInfor ? "Chọn gói" : "Trở lại"}
        </Button>
        <ul className="steps-list flex-between-align-center">
          {stepsListData.map((step, idx) => (
            <>
              <li
                key={step.value}
                className={`${currentStep === step.value ? "current" : ""} flex-start-align-center`}
              >
                {renderActive(step.value) ? (
                  <>
                    {step.value === EPaymentSteps.PaymentResult &&
                    status === "loading" ? (
                      <div>
                        <div className="proccess-loading">
                          <div className="loader"></div>
                        </div>
                      </div>
                    ) : (
                      <figure
                        className={`step-done flex-center ${status !== "00" && step.value === EPaymentSteps.PaymentResult && currentStep === EPaymentSteps.PaymentResult ? "error" : ""}`}
                      >
                        <ImageCustom
                          src={
                            status !== "00" &&
                            step.value === EPaymentSteps.PaymentResult &&
                            currentStep === EPaymentSteps.PaymentResult
                              ? IconX
                              : IconCheck
                          }
                          alt={"check-icon"}
                          width={"14px"}
                          height={"10px"}
                        />
                      </figure>
                    )}
                  </>
                ) : (
                  <p className="step-number flex-center">{idx + 1}</p>
                )}
                <p className="step-text">{step.title}</p>
              </li>
              {idx < stepsListData.length - 1 && (
                <div className="step-dashed"></div>
              )}
            </>
          ))}
        </ul>
      </div>
    </PaymentStepsWrapper>
  );
}
