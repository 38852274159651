import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const ReportHeaderWrapper = styled("div")<{
  height?: string;
}>(({ height }) => ({
  minHeight: !!height ? height : "100%",
  position: "relative",
  backgroundColor: variableStyles.Info50,
  border: `1px solid ${variableStyles.NaturalColor300}`,
  borderRadius: "4px",
  boxShadow: "0px 0px 17.4px -3px #0000001F ",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "0",
    left: "0",
    height: "100%",
    width: "35%",
    backgroundColor: variableStyles.Info100,
  },
  "&::after": {
    content: '""',
    position: "absolute",
    top: "0",
    right: "0",
    height: "100%",
    width: "6px",
    backgroundColor: variableStyles.Info300,
  },
  "& .polygon-overlay": {
    position: "absolute",
    top: "5%",
    left: "10%",
    height: "8px",
    backgroundColor: variableStyles.Info600,
    width: "80%",
    clipPath:
      "polygon(0 calc(100% - 1px), calc(100% - 88px) calc(100% - 1px), calc(100% - 88px) 0, 100% 0, 100% 100%, 0 100%)",
  },
  "& .madeby-text": {
    position: "absolute",
    top: "10%",
    left: "10%",
    color: variableStyles.NaturalColor950,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "24px",
    lineHeight: "32px",
  },
  "& .header-infor": {
    position: "absolute",
    top: "10%",
    right: "20%",
    borderLeft: `1px solid ${variableStyles.Info800}`,
    paddingLeft: "16px",
    "& .infor-wrapper": {
      gap: "8px",
    },
    "& .infor-brandname": {
      color: variableStyles.Info900,
      fontWeight: variableStyles.fwBold,
      fontSize: "14px",
      lineHeight: "24px",
    },
    "& .infor-domain": {
      "& span": { fontWeight: variableStyles.fwBold },
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwRegular,
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
  "& .header-intro": {
    position: "absolute",
    bottom: "10%",
    left: "10%",
    "& .header-intro-title": {
      color: variableStyles.Info950,
      fontWeight: variableStyles.fwBold,
      fontSize: "80px",
      lineHeight: "80px",
      fontFamily: variableStyles.Manrope,
      maxWidth: "65%",
      marginBottom: "36px",
    },
    "& .intro-description": {
      color: variableStyles.NaturalColor900,
      fontWeight: variableStyles.fwRegular,
      fontSize: "14px",
      lineHeight: "24px",
      maxWidth: "60%",
    },
  },
}));
