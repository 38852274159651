import React, { useEffect, useState } from "react";
import {
  ImageCustom,
  LoadingIndicator,
  ReportFooter,
  ReportHeader,
} from "../../../../../components";
import OverviewNavbar from "../../OverviewSidebar";
import { OverviewDomainAuditWrapper } from "./styles";
import PerformSection from "../../OverviewSection/PerformSection";
import ImageMain from "../../../../../assets/images/domai-audit/overview-section/img_overview_main_image.png";
import IconOptimize from "../../../../../assets/images/domai-audit/overview-section/icon_overview_optimize.svg";
import IconUX from "../../../../../assets/images/domai-audit/overview-section/icon_overview_UX.svg";
import IconSEO from "../../../../../assets/images/domai-audit/overview-section/icon_overview_SEO.svg";
import SecuritySection from "../../OverviewSection/UXSection";
import SEOSection from "../../OverviewSection/SEOSection";
import LogoTemp from "../../../../../assets/images/temp/temp_avatar.png";
import { useInView } from "react-intersection-observer";
import CompanyProfileSection from "../../OverviewSection/CompanyProfileSection";
import { useSelector } from "react-redux";
import { IStateApp } from "../../../../../models/redux";
import { IGetReportsRes } from "../../../../../models/report";
import AuditReportService from "../../../../../services/api/audit.report.service";
import { IAuditReportData } from "../../../../../models/audit-report-data";

export default function OverviewTab() {
  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );

  const [section1Ref, section1InView] = useInView();
  const [section2Ref, section2InView] = useInView();
  const [section3Ref, section3InView] = useInView();
  const [section4Ref, section4InView] = useInView();

  const [resData, setResData] = useState<IAuditReportData | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    result();
  }, []);

  useEffect(() => {
    if (!!dataReport.auditId) {
      getDataofPage();
    }
  }, [dataReport]);

  const handleScrollto = (value: string) => {
    const findId = () => {
      switch (value) {
        case "0":
          return "perform-section";
        case "1":
          return "company-profile";
        case "2":
          return "security-section";
        default:
          return "seo-section";
      }
    };

    const element = document.getElementById(findId());
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const result = () => {
    // const res = RegionService.getGGMapsPlacePlatform();
  };

  const getDataofPage = async () => {
    setIsLoading(true);
    const res = await AuditReportService.getDataReportAudit(dataReport.auditId);
    if (!res.isError) {
      setResData(res.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const indexData: {
    type: "normal" | "warning" | "danger";
    percent: number;
    title: string;
    icon?: string;
    logo?: string;
    infor: string;
  }[] = [
    {
      type: "normal",
      percent: 20,
      title: "Trải nghiệm người dùng",
      icon: IconSEO,
      infor:
        "Trải nghiệm người dùng đề cập đến quá trình tương tác của người dùng trên website từ việc tìm kiếm thông tin, mua sắm và các hành động khác. Một website được thiết kế với giao diện trực quan, dễ sử dụng, tốc độ nhanh chóng sẽ mang đến những trải nghiệm tốt cho người dùng từ đó giúp gia tăng tỉ lệ chuyển đổi, nâng cao uy tín thương hiệu và cải thiện thứ hạng website trên công cụ tìm kiếm.",
    },
    {
      type: "warning",
      percent: 10,
      title: "SEO Website",
      icon: IconUX,
      infor:
        "SEO là quá trình tối ưu giúp website dễ được tìm thấy trên các công cụ tìm kiếm. Đánh giá SEO giúp xác định các điểm mạnh, yếu và cơ hội cải thiện website từ đó nâng cao thứ hạng, tăng lượng truy cập vào website.",
    },
    {
      type: "normal",
      percent: 20,
      title: "Tối ưu chuyển đổi",
      icon: IconOptimize,
      infor:
        "Tối ưu chuyển đổi là một hệ thống các yếu tố nhằm tăng tỉ lệ người dùng thực hiện hành động cụ thể trên website như điền form, mua hàng hoặc các hoạt động chuyển đổi khác.",
    },
    {
      type: "normal",
      percent: 20,
      title: "Hồ sơ doanh nghiệp",
      logo: LogoTemp,
      infor:
        "Google Business Profile là một dịch vụ miễn phí của Google cho phép doanh nghiệp cập nhật các thông tin cơ bản, hình ảnh, thông tin về sản phẩm và dịch vụ,...Sở hữu một hồ sơ doanh nghiệp trên Google được tối ưu tốt giúp doanh nghiệp tăng độ hiển thị và tăng sự tin cậy đối với người dùng.",
    },
  ];

  return (
    <>
      <OverviewDomainAuditWrapper id="divToPrint">
        {isLoading ? (
          <div className="overview-loading flex-center">
            <LoadingIndicator />
            <p>Đang tải dữ liệu báo cáo của bạn!</p>
          </div>
        ) : (
          <>
            <OverviewNavbar
              onScrollto={v => {
                handleScrollto(v);
              }}
              activeValue={
                section1InView
                  ? "0"
                  : section2InView
                    ? "1"
                    : section3InView
                      ? "2"
                      : section4InView
                        ? "3"
                        : ""
              }
            />
            <div className="overview-content scrollbar">
              <ReportHeader
                title="Báo Cáo Đánh Giá Website"
                description="Khám phá tình trạng thực tế của website và hồ sơ doanh nghiệp trên Google. Nhận diện điểm cần cải thiện và hành động ngay để tối ưu hóa hiệu quả kinh doanh trực tuyến cho doanh nghiệp."
                brandName={dataReport?.projectName}
                domain={dataReport?.target}
                height={"calc(100vh - 196px)"}
              />
              <div className="overview-introduction-wrapper common-layout">
                <div className="overview-introduction">
                  <div className="introduction-content">
                    <h1 className="content-title">Lời Mở Đầu</h1>
                    <p className="content-description">
                      Báo cáo kiểm tra website này được thiết kế để phân tích
                      chi tiết hiệu suất, các yếu tố giao diện và thực trạng tối
                      ưu SEO website của bạn. Mục tiêu của chúng tôi là cung cấp
                      một báo cáo toàn diện nhất giúp xác định các cơ hội cải
                      thiện, giải pháp khắc phục, nâng cao hiệu quả website,
                      giúp thu hút nhiều khách hàng và gia tăng doanh số.
                    </p>
                  </div>
                  <figure className="introduction-image">
                    <ImageCustom
                      src={ImageMain}
                      alt="Icon-Main"
                      width={"354px"}
                      height={"419px"}
                    />
                  </figure>
                </div>
                {/* <ul className="index-list">
              {indexData.map(data => (
                <li key={data.title}>
                  <OverviewIndexBox
                    type={data.type}
                    percent={data.percent}
                    title={data.title}
                    icon={data.icon}
                    logo={data.logo}
                    infor={data.infor}
                  />
                </li>
              ))}
            </ul> */}
              </div>
              <PerformSection ref={section1Ref} props={resData} />
              <CompanyProfileSection ref={section2Ref} props={resData} />
              <SecuritySection ref={section3Ref} props={resData} />
              <SEOSection ref={section4Ref} props={resData} />
              <ReportFooter
                conclusion={
                  <ul
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <li>
                      Thực hiện cải thiện từ những phân tích trong báo cáo này
                      sẽ giúp doanh nghiệp của bạn không chỉ cải thiện hiệu quả
                      trực tuyến mà còn tạo nền tảng vững chắc cho sự phát triển
                      dài hạn. Thành công bắt đầu từ bước đầu tiên – hãy thực
                      hiện ngay!
                    </li>
                    <li>
                      Nếu cần hỗ trợ, đừng ngần ngại liên hệ với chúng tôi.
                      Chúng tôi sẵn sàng đồng hành cùng bạn trên hành trình tối
                      ưu hóa và phát triển!"
                    </li>
                  </ul>
                }
                height={"calc(100vh - 196px)"}
                title="Sẵn sàng hành động!"
              />
            </div>
          </>
        )}
      </OverviewDomainAuditWrapper>
    </>
  );
}
