import { Button, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import {
  DateRangePickerPopover,
  FilterCheckBoxCustom,
  ImageCustom,
  LoadingIndicator,
  NoDataBox,
  NoSearchDataBox,
  ReportStatusTags,
  ReportTypeTags,
  TableDataGrey,
} from "../../../components";
import { IBasePagingRes, IValue } from "../../../models/common/models.type";
import { DEFAULT_BASE_PAGING_RES } from "../../../common/constants/value.constant";
import { DATETIME_FORMAT, DATE_REQ_FORMAT } from "../../../config/constants";
import moment from "moment";
import { ReportsWrapper } from "./styles";
import InputSearch from "../../../components/controls/InputSearch";
import IconAdd from "../../../assets/images/common/icon_plus_line_white.svg";
import { toggleConfirmModal } from "../../../components/ConfirmModal/ConfirmModal";
import { toggleCreateReportModal } from "../../../components/Modal/CreateReportModal";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ProjectService from "../../../services/api/project.service";
import { IProject } from "../../../models/project";
import { ROUTE_PATH } from "../../../common/constants/app.constant";
import { localStorageHanlde } from "../../../services/services";
import IconArrow from "../../../assets/images/common/icon_arrow_down_line_black.svg";
import IconFilter from "../../../assets/images/common/icon_filter_line_grey.svg";
import TabletFilterModal from "../../../components/FilterModal";
import queryString from "query-string";
import { EReportStatus, EReportType } from "../../../models/common/models.enum";
import { IGetReportsReq } from "../../../models/report";
import ReportService from "../../../services/api/report.service";
import { toggleMessage } from "../../../components/Toast/Toast";
import { ITableHeader } from "../../../components/Table/TableDataGrey";
import { useDispatch } from "react-redux";
import { setReportState } from "../../../redux/project/project.duck";
import { REPORT_ID_KEY } from "../../../services/local/local-storage";
import { useTranslation } from "react-i18next";

export interface IGetReportsParams {
  status?: string[] | null;
  startDate?: string | null;
  endDate?: string | null;
  reportType?: string[] | null;
  pageIndex: number;
  pageSize: number;
  keySearch?: string | null;
}

const reportTypeData: IValue[] = [
  { title: "Domain audit", value: EReportType.DomainAudit.toString() },
  { title: "Domain audit SEO", value: EReportType.DomainAuditSEO.toString() },
  { title: "Domain audit UX", value: EReportType.DomainAuditUX.toString() },
  {
    title: "Domain competitive",
    value: EReportType.DomainCompetitive.toString(),
  },
  { title: "Page audit", value: EReportType.PageAudit.toString() },
  { title: "Page competitive", value: EReportType.PageCompetitive.toString() },
];

const reportStatusData: IValue[] = [
  { title: "Done", value: EReportStatus.Done.toString() },
  { title: "Running", value: EReportStatus.Running.toString() },
  { title: "Cancel", value: EReportStatus.Cancel.toString() },
  { title: "Error", value: EReportStatus.Error.toString() },
];

const DEFAULT_QUERY = {
  pageIndex: 1,
  pageSize: 10,
};

const ProjectReports = () => {
  const timerOut = useRef<number>();
  const { id: projectId } = useParams();
  const matches1100 = useMediaQuery("(max-width:1100px)");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const dataFilter: IGetReportsParams = {
    startDate: searchParams.get("startDate"),
    endDate: searchParams.get("endDate"),
    pageIndex: Number(searchParams.get("pageIndex")) || 1,
    pageSize: Number(searchParams.get("pageSize")) || 10,
    keySearch: searchParams.get("keySearch"),
    reportType: searchParams.getAll("reportType"),
    status: searchParams.getAll("status"),
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [projectInfor, setProjectInfor] = useState<IProject>();
  const [typeCheckedFilter, setTypeCheckedFilter] = useState<string[]>([]);
  const [statusCheckedFilter, setStatusCheckedFilter] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [keySearchState, setKeySearchState] = useState<string | null>(null);
  const [dataReportsRes, setDataReportsRes] = useState<IBasePagingRes<any>>({
    ...DEFAULT_BASE_PAGING_RES,
  });

  useEffect(() => {
    if (!!projectId) {
      onGetProjectDetail(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    onGetListReport();
  }, [location]);

  useEffect(() => {
    return () => {
      localStorageHanlde.clearBreadcrumbs();
    };
  }, []);

  const onGetListReport = async () => {
    setIsLoading(true);
    const newQuery: IGetReportsParams = { ...dataFilter };
    const newParams: IGetReportsReq = {
      pageIndex: newQuery.pageIndex,
      pageSize: newQuery.pageSize,
      projectId: projectId || "",
      keySearch: newQuery.keySearch,
    };
    newParams.reportType =
      !!newQuery.reportType && newQuery.reportType.length > 0
        ? dataFilter?.reportType?.join(",")
        : null;
    newParams.status =
      !!newQuery.status && newQuery.status.length > 0
        ? dataFilter?.status?.join(",")
        : null;
    newParams.startDate = !!newQuery.startDate ? newQuery.startDate : null;
    newParams.endDate = !!newQuery.endDate ? newQuery.endDate : null;

    const res = await ReportService.getReportList(newParams);
    if (!res.isError) {
      setIsLoading(false);
      const newArray = res.data.items.map(item => ({
        ...item,
        id: item.projectId,
      }));
      setDataReportsRes({ ...res.data, items: newArray });
    } else {
      setIsLoading(false);
    }
  };

  const onGetProjectDetail = async (id: string) => {
    const res = await ProjectService.getProjectDetail(id);
    if (!res.isError) {
      setProjectInfor(res.data);
      const data = [
        { title: "Project", link: ROUTE_PATH.INDEX },
        {
          title: res.data?.projectName,
          link: `${ROUTE_PATH.INDEX}/${res.data?.id}`,
        },
      ];
      localStorageHanlde.storeBreadcrumbs(data);
    } else {
      navigate(ROUTE_PATH.NOTFOUND);
    }
  };

  const onChangeSearch = (e: any) => {
    const value = e?.target?.value;
    if (timerOut.current) {
      clearTimeout(timerOut.current);
    }
    timerOut.current = window.setTimeout(() => {
      handleChangeParamUrl("keySearch", value);
    }, 1000);
  };

  const asyncFuncDelete: (id: string) => Promise<void> = async (id: string) => {
    const res = await ReportService.deleteReport({ id: id });
    if (!!res && !res.isError) {
      toggleMessage({
        type: "success",
        title: `${t("ModalText.DeleteSuccessTitle")}`,
        message: `${t("ModalText.DeleteReportSuccessDescription")}`,
      });
      onGetListReport();
      if (projectId) {
        onGetProjectDetail(projectId);
      }
    } else {
      toggleMessage({
        type: "error",
        title: `${t("ModalText.DeleteFailTitle")}`,
        message: `${t("ModalText.DeleteReportFailDescription")}`,
      });
    }
  };

  const headersTable: ITableHeader<any>[] = [
    {
      field: "domain",
      title: `${t("Common.Domain")}`,
      width: "350px",
      className: "table-cell-link",
      isSticky: true,
      handleItem: (item: any) => {
        return (
          <Button
            className={`fit-one-line ${item.status !== EReportStatus.Done ? "disabled" : ""}`}
            onClick={() => {
              if (item.status === EReportStatus.Done) {
                dispatch(setReportState(item));
                localStorage.setItem(REPORT_ID_KEY, item.auditId);
                const path = generatePath(
                  item.reportType === EReportType.DomainAudit
                    ? ROUTE_PATH.DOMAIN_AUDIT_DETAIL
                    : item.reportType === EReportType.DomainCompetitive
                      ? ROUTE_PATH.DOMAIN_COMPETITIVE_DETAIL
                      : item.reportType === EReportType.PageAudit
                        ? ROUTE_PATH.PAGE_AUDIT_DETAIL
                        : item.reportType === EReportType.PageCompetitive
                          ? ROUTE_PATH.PAGE_COMPETITIVE_DETAIL
                          : item.reportType === EReportType.DomainAuditUX
                            ? ROUTE_PATH.DOMAIN_AUDIT_UX_DETAIL
                            : ROUTE_PATH.DOMAIN_AUDIT_SEO_DETAIL,
                  {
                    id: item.auditId,
                  }
                );
                navigate({
                  pathname: `${path}`,
                });
              }
            }}
          >
            {item?.target}
          </Button>
        );
      },
    },
    {
      field: "type",
      title: `${t("Common.ReportType")}`,
      width: "200px",
      handleItem: (item: any) => {
        return <ReportTypeTags type={item.reportType} />;
      },
    },
    {
      field: "createdAt",
      title: `${t("Common.CreatedAt")}`,
      width: "200px",
      handleItem: (item: any) => {
        return (
          <p className="fit-one-line">
            {moment(item.createdAt).format(DATETIME_FORMAT)}
          </p>
        );
      },
    },
    {
      field: "status",
      title: `${t("Common.Status")}`,
      width: "200px",
      handleItem: (item: any) => {
        return <ReportStatusTags status={item.status} />;
      },
    },
  ];

  const handleChangeParamUrl = (key: keyof IGetReportsParams, value: any) => {
    if (JSON.stringify(value) !== JSON.stringify(dataFilter[key])) {
      const queryParams: any = {
        ...DEFAULT_QUERY,
        ...dataFilter,
        pageIndex: key === "pageIndex" ? value : 1,
        [key]: value,
      };
      if (!value || value.length <= 0) {
        delete queryParams[key];
      }
      const removeEmptyQuery = Object.fromEntries(
        Object.entries(queryParams).filter(([_, v]) => v != null)
      );
      navigateParams(removeEmptyQuery);
      setAnchorEl(null);
    }
  };

  const handleChangeFilterParamUrl = (
    typeChecked: string[],
    statusChecked: string[]
  ) => {
    const filterParams = queryString.stringify({
      reportType: [...typeChecked],
      status: [...statusChecked],
      ...DEFAULT_QUERY,
    });

    const path = generatePath(ROUTE_PATH.MY_PROJECT_DETAIL, { id: projectId });
    navigate({
      pathname: `${path}`,
      search: `${filterParams}`,
    });
    setAnchorEl(null);
  };

  const handleChangeDateRangeParamUrl = (startDate?: Date, endDate?: Date) => {
    const filterParams = {
      ...DEFAULT_QUERY,
      ...dataFilter,
      startDate: !!startDate ? moment(startDate).format(DATE_REQ_FORMAT) : null,
      endDate: !!endDate ? moment(endDate).format(DATE_REQ_FORMAT) : null,
    };
    const removeEmptyQuery = Object.fromEntries(
      Object.entries(filterParams).filter(([_, v]) => v != null)
    );
    navigateParams(removeEmptyQuery);
  };

  const navigateParams = (value: { [k: string]: unknown }) => {
    const newParams = queryString.stringify(value);

    const path = generatePath(ROUTE_PATH.MY_PROJECT_DETAIL, { id: projectId });
    navigate({
      pathname: `${path}`,
      search: `${newParams}`,
    });
  };

  const handleButtonClick = () => {
    document.dispatchEvent(new CustomEvent("reset-filter"));
  };

  const handleCreateSuccess = () => {
    navigateParams({ pageIndex: 1, pageSize: 10 });
    onGetProjectDetail(projectId || "");
  };

  return (
    <>
      <TabletFilterModal
        onClose={() => setAnchorEl(null)}
        onSubmit={() => {
          handleChangeFilterParamUrl(typeCheckedFilter, statusCheckedFilter);
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onReset={handleButtonClick}
      >
        <FilterCheckBoxCustom
          options={reportTypeData}
          onChange={v => {
            setTypeCheckedFilter(v);
          }}
          placeholder="Chọn loại"
          valueProps={dataFilter.reportType || []}
          name={""}
          filterText={`${t("Common.ReportType")}:`}
          width="220px"
          isHaveAll
        />
        <FilterCheckBoxCustom
          options={reportStatusData}
          onChange={v => setStatusCheckedFilter(v)}
          placeholder={`${t("Common.ChooseStatus")}:`}
          valueProps={dataFilter.status || []}
          name={""}
          filterText={`${t("Common.Status")}:`}
          width="220px"
          isHaveAll
        />
      </TabletFilterModal>

      <ReportsWrapper>
        <Helmet>
          <title>{projectInfor?.projectName}</title>
        </Helmet>
        <div className="my-report-header">
          <div className="my-report-title-wrapper">
            <div className="my-report-title">
              <p className="header-name fit-one-line">
                {projectInfor?.projectName}
              </p>
              <p className="header-count">
                {projectInfor?.totalReports} {t("Common.Report")}
              </p>
            </div>
          </div>
          <div className="header-action">
            <InputSearch
              placeholder={`${t("Common.Search")}`}
              onChange={(e: any) => {
                setKeySearchState(e.target.value);
                onChangeSearch(e);
              }}
              value={keySearchState || dataFilter.keySearch}
              size={"medium"}
              width={"198px"}
              typeStyle="outlined"
            />
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={_ =>
                toggleCreateReportModal({
                  open: true,
                  onCreateSuccess: () => handleCreateSuccess(),
                  projectInfor: projectInfor,
                })
              }
              className="add-button"
            >
              <ImageCustom
                src={IconAdd}
                alt="Icon-Add"
                width={"18px"}
                height={"18px"}
              />
              <p>{t("Common.CreateReport")}</p>
            </Button>
          </div>
        </div>
        <div className="my-report-wrapper">
          <div
            className={`report-filter-box  ${!!matches1100 ? "tablet-mode" : ""}`}
          >
            {!!matches1100 ? (
              <div
                className={`tablet-filter-box`}
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                <ImageCustom
                  src={IconFilter}
                  alt="Icon-Filter"
                  width={"18px"}
                  height={"18px"}
                />
                <p className="tablet-filter-text">{t("Common.Filter")}</p>
                <ImageCustom
                  src={IconArrow}
                  alt="Icon-Arrow"
                  width={"20px"}
                  height={"20px"}
                />
              </div>
            ) : (
              <div className="filter-box-select">
                <p className="select-filter-text">{t("Common.DisplayBy")}</p>
                <FilterCheckBoxCustom
                  options={reportTypeData}
                  onChange={v => handleChangeParamUrl("reportType", v)}
                  placeholder={`${t("Common.ChooseType")}`}
                  valueProps={dataFilter.reportType || []}
                  name={""}
                  filterText={`${t("Common.ReportType")}: `}
                  isHaveAll
                />
                <FilterCheckBoxCustom
                  options={reportStatusData}
                  onChange={v => handleChangeParamUrl("status", v)}
                  placeholder={`${t("Common.ChooseStatus")}`}
                  valueProps={dataFilter.status || []}
                  name={""}
                  filterText={`${t("Common.Status")}: `}
                  isHaveAll
                />
              </div>
            )}

            <DateRangePickerPopover
              onDataChange={(v: {
                startDate: Date | undefined;
                endDate: Date | undefined;
              }) => {
                handleChangeDateRangeParamUrl(v.startDate, v.endDate);
              }}
              maxDate={Date.now()}
              value={{
                startDate: dataFilter.startDate
                  ? new Date(dataFilter.startDate)
                  : undefined,
                endDate: dataFilter.endDate
                  ? new Date(dataFilter.endDate)
                  : undefined,
              }}
            />
          </div>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              {!!dataReportsRes?.items && dataReportsRes.items?.length > 0 ? (
                <TableDataGrey
                  data={dataReportsRes}
                  headersTable={headersTable}
                  handleOpenDetail={(id: string) => {
                    const findReport = dataReportsRes.items.find(
                      item => item.auditId === id
                    );
                    if (!!findReport) {
                      const path = generatePath(
                        findReport.reportType === EReportType.DomainAudit
                          ? ROUTE_PATH.DOMAIN_AUDIT_DETAIL
                          : findReport.reportType ===
                              EReportType.DomainCompetitive
                            ? ROUTE_PATH.DOMAIN_COMPETITIVE_DETAIL
                            : findReport.reportType === EReportType.PageAudit
                              ? ROUTE_PATH.PAGE_AUDIT_DETAIL
                              : ROUTE_PATH.PAGE_COMPETITIVE_DETAIL,
                        {
                          id: id,
                        }
                      );
                      navigate({
                        pathname: `${path}`,
                      });
                    }
                  }}
                  handleDeleteData={(id: string) => {
                    toggleConfirmModal({
                      open: true,
                      title: `${t("Common.DeleteReportTitle")}`,
                      content: `${t("Common.DeleteReportDescription")}`,
                      onSubmit: () => asyncFuncDelete(id.toString()),
                      submitText: `${t("Common.Delete")}`,
                      type: "warning-red",
                    });
                  }}
                  maxHeight={"calc(100vh - 300px)"}
                  handleChangeSearchParams={(v: any) =>
                    handleChangeParamUrl("pageIndex", v)
                  }
                  keyField="auditId"
                  textOpenDetail={t("Common.Open")}
                />
              ) : !!dataFilter.keySearch && dataFilter.keySearch.length > 0 ? (
                <NoSearchDataBox
                  inputSearch={dataFilter.keySearch}
                  onClearSearch={() => {
                    setKeySearchState("");
                    handleChangeParamUrl("keySearch", "");
                  }}
                  openCreateModal={() =>
                    toggleCreateReportModal({
                      open: true,
                      onCreateSuccess: () => {
                        handleCreateSuccess();
                      },
                      projectInfor: projectInfor,
                    })
                  }
                  openText={t("Common.CreateProject")}
                />
              ) : (
                <NoDataBox
                  onClick={() => {
                    toggleCreateReportModal({
                      open: true,
                      onCreateSuccess: () => {
                        handleCreateSuccess();
                      },
                      projectInfor: projectInfor,
                    });
                  }}
                />
              )}
            </>
          )}
        </div>
      </ReportsWrapper>
    </>
  );
};

export default ProjectReports;
