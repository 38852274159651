import "react-perfect-scrollbar/dist/css/styles.css";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import reduxStore from "../src/redux/store";
import "./App.css";
import Main from "./pages/Main";
import "moment/locale/vi";
import Toast from "./components/Toast/Toast";
import ConfirmModal from "./components/ConfirmModal/ConfirmModal";
import { useEffect, useState } from "react";
import { CountryLanguage, changeLanguage } from "./i18n/i18n";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { EReportStatus } from "./models/common/models.enum";
import { IBaseResponseModel } from "./models/common/models.type";

const App = () => {
  const [connection, setConnection] = useState<HubConnection>();
  const [auditSignal, setAuditSignal] = useState<
    IBaseResponseModel<{
      auditId: string;
      status: EReportStatus;
    }>
  >();
  const [paymentAuditSignal, setPaymentAuditSignal] = useState<
    IBaseResponseModel<{
      orderId: string;
      status: string;
    }>
  >();

  const joinReportStatus = async () => {
    try {
      const connection = new HubConnectionBuilder()
        .withUrl("http://45.117.170.185:9100/miko-notify")
        .withAutomaticReconnect()
        .build();
      await connection.start();
      connection.on("UpdateAudit", (message: any) => {
        if (!message.isError) {
          setAuditSignal(message);
        }
      });
      connection.on("PaymentAudit", (message: any) => {
        if (!message.isError) {
          console.log(message);
          setPaymentAuditSignal(message);
        }
      });
      setConnection(connection);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const locale = localStorage.getItem("language");

    if (!locale) {
      localStorage.setItem("language", CountryLanguage.EN);
      changeLanguage(CountryLanguage.EN);
    } else {
      changeLanguage(locale as CountryLanguage);
    }
    joinReportStatus();
  }, []);

  useEffect(() => {
    return () => {
      if (!!connection) {
        connection.stop();
      }
    };
  }, []);

  return (
    <Provider store={reduxStore.store}>
      <PersistGate loading={null} persistor={reduxStore.persistor}>
        <Toast />
        <Main
          auditsignal={{
            auditId: auditSignal?.data.auditId as string,
            status: auditSignal?.data.status as EReportStatus,
          }}
          paymentauditsignal={{
            orderId: paymentAuditSignal?.data.orderId as string,
            status: paymentAuditSignal?.data.status as string,
          }}
        />
        <ConfirmModal />
      </PersistGate>
    </Provider>
  );
};

export default App;
