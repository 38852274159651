import React, { useEffect, useState } from "react";
import { ImageCustom, LoadingIndicator } from "../../../../components";
import { ECurrencyCode } from "../../../../models/common/models.enum";
import { PaymentResultWrapper } from "../../styles";
import IconSuccess from "../../../../assets/images/common/img_success_fill_blue.png";
import IconFail from "../../../../assets/images/common/img_fail_fill_yellow.png";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { IStateApp } from "../../../../models/redux";
import { IPlan } from "../../../../models/plan";
import CommonHandle from "../../../../common/handles/common.handles";
import moment from "moment";
import { DATE_FORMAT } from "../../../../config/constants";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../../../common/constants/app.constant";
import queryString from "query-string";

interface PaymentResultProps {
  className: string;
  onChangeStep: () => void;
}

export default function PaymentResult(props: PaymentResultProps) {
  const { className, onChangeStep } = props;

  const navigate = useNavigate();

  const currentPlan = useSelector<IStateApp, IPlan | null>(
    appState => appState.planInfor.planInfor
  );
  const auditSignal = useSelector<
    IStateApp,
    { orderId: string; status: string | null }
  >(appState => appState.signalR.paymentAudit);

  const [status, setStatus] = useState<string | null>(null);

  const listFeature: {
    title: string;
    description: string;
    hasBorder: boolean;
    isTotal?: boolean;
  }[] = [
    { title: "Số lượng", description: "1 Báo cáo", hasBorder: true },
    {
      title: "Giá gói",
      description: `${CommonHandle.formatCurrency(
        currentPlan?.price || 0,
        ECurrencyCode.VND
      )}`,
      hasBorder: false,
    },
    {
      title: "Phí VAT",
      description: `${CommonHandle.formatCurrency(0, ECurrencyCode.VND)}`,
      hasBorder: false,
    },
    {
      title: "Tổng cộng",
      description: `${CommonHandle.formatCurrency(
        (currentPlan?.price || 0) + 0,
        ECurrencyCode.VND
      )}`,
      hasBorder: true,
      isTotal: true,
    },
    {
      title: "Ngày giao dịch",
      description: `${moment(new Date()).format(DATE_FORMAT)}`,
      hasBorder: false,
    },
    {
      title: "Phương thức",
      description: "Thanh toán quốc tế",
      hasBorder: false,
    },
  ];
  useEffect(() => {
    if (!!currentPlan && !!currentPlan.orderId && !!auditSignal.status) {
      if (auditSignal.orderId === currentPlan.orderId) {
        setStatus(auditSignal.status);
      }
    } else if (!!currentPlan) {
      setStatus("loading");
    }
  }, [currentPlan, auditSignal]);

  return (
    <PaymentResultWrapper className={`${className} flex-center`}>
      {status === "loading" ? (
        <div className="result-loading flex-center">
          <LoadingIndicator />
          <p>Giao dịch đang chờ xử lý!</p>
        </div>
      ) : (
        <>
          <figure className="result-image">
            <ImageCustom
              src={status === "00" ? IconSuccess : IconFail}
              alt={"status-icon"}
              width={"65px"}
              height={"65px"}
            />
          </figure>
          <p className="result-title">
            {status === "00"
              ? "Thanh toán thành công"
              : "Giao dịch không thành công"}
          </p>
          <p className="result-description">
            {status === "00"
              ? "Bạn đã nhận được gói đăng kí"
              : "Payment failed. Please check your details or try again"}
          </p>
          {status === "00" && (
            <div className="plan-summary">
              <p className="summary-title">{currentPlan?.title}</p>
              <p className="summary-description">{currentPlan?.description}</p>
              <ul className="list-plan-feature">
                {listFeature.map(feature => (
                  <li
                    key={feature.title}
                    className={`flex-between-align-center ${feature.hasBorder ? "has-border" : ""} ${feature.isTotal ? "total" : ""}`}
                  >
                    <p className="feature-title">{feature.title}</p>
                    <p className="feature-description">{feature.description}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="modal-action">
            <Button
              size="xsmall"
              variant="outlined"
              className="button-cancel"
              onClick={() => {}}
            >
              Trở về trang chủ
            </Button>
            <Button
              size="xsmall"
              variant="contained"
              className="button-submit"
              onClick={() => {
                if (status === "00") {
                  const path = generatePath(ROUTE_PATH.PROFILE);
                  const newParams = queryString.stringify({ tab: "history" });
                  navigate({
                    pathname: `${path}`,
                    search: `${newParams}`,
                  });
                } else {
                  onChangeStep();
                }
              }}
            >
              {status === "00" ? "Lịch sử thanh toán" : "Thử lại"}
            </Button>
          </div>
        </>
      )}
    </PaymentResultWrapper>
  );
}
