import React from "react";
import variableStyles from "../../../../theme/variable-styles";
import IconWebAudit from "../../../../assets/images/common/icon_domainaudit_fill_blue.svg";
import IconWebCompetitive from "../../../../assets/images/common/icon_domaincompetitive_fill_pink.svg";
import IconPageAudit from "../../../../assets/images/common/icon_pageaudit_fill_blue.svg";
import IconPageCompetitive from "../../../../assets/images/common/icon_pagecompetitive_fill_green.svg";
import IconSEO from "../../../../assets/images/common/icon_seo_fill_yellow.svg";
import IconUX from "../../../../assets/images/common/icon_ux_fill_purple.svg";
import CheckToolNavCard from "../CheckToolNavCard";
import { CheckToolServiceIntroWrapper } from "./styles";
import { ROUTE_PATH } from "../../../../common/constants/app.constant";

export default function CheckToolServiceIntro() {
  const tempData = [
    {
      title: "Domain Audit",
      description:
        "Khám phá thực trạng hoạt động, tìm giải pháp tăng hiệu quả kinh doanh trực tuyến.",
      background: variableStyles.blueSecondary200,
      icon: IconWebAudit,
      path: ROUTE_PATH.DOMAIN_AUDIT,
    },
    {
      title: "Domain SEO Audit",
      description:
        "Đánh giá SEO toàn diện, cải thiện thứ hạng và gia tăng lượng truy cập website.",
      background: "#F4F0E4",
      icon: IconSEO,
      path: ROUTE_PATH.DOMAIN_AUDIT_SEO,
    },
    {
      title: "Domain UX Audit",
      description:
        "Giữ chân khách hàng, gia tăng doanh thu với báo cáo trải nghiệm người dùng chi tiết.",
      background: "#D8DFFF",
      icon: IconUX,
      path: ROUTE_PATH.DOMAIN_AUDIT_UX,
    },
    {
      title: "Domain Competitive",
      description:
        "Phân tích đối thủ trực tuyến để xây dựng chiến lược cạnh tranh hiệu quả.",
      background: "#FFE2ED",
      icon: IconWebCompetitive,
      path: ROUTE_PATH.DOMAIN_COMPETITIVE,
    },
    {
      title: "Page SEO Audit",
      description:
        "Đánh giá SEO trên trang, cải thiện thứ hạng từ khóa mục tiêu cho trang đích cụ thể.",
      background: variableStyles.Info100,
      icon: IconPageAudit,
      path: ROUTE_PATH.PAGE_AUDIT,
    },
    {
      title: "Page SEO Competitive",
      description:
        "Phân tích SEO trên trang đối thủ, tìm kiếm cơ hội vượt trội trong kết quả tìm kiếm.",
      background: "#AFF5DB",
      icon: IconPageCompetitive,
      path: ROUTE_PATH.PAGE_COMPETITIVE,
    },
  ];
  return (
    <CheckToolServiceIntroWrapper>
      <div className="service-intro">
        <p className="service-title">Khám phá báo cáo phân tích chuyên sâu</p>
        <p className="service-description">
          Đánh giá toàn diện website, tối ưu hiệu suất và bứt phá doanh thu.
        </p>
      </div>
      <ul className="card-list">
        {tempData.map(card => (
          <li key={card.title}>
            <CheckToolNavCard data={card} />
          </li>
        ))}
      </ul>
    </CheckToolServiceIntroWrapper>
  );
}
