import { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import { Helmet } from "react-helmet";
import TextEditorQuill from "../../../../../components/TextEditorQuill";
import Title from "../../../../../components/Title";
import { PostWrapper } from "./styles";
import ReactQuill from "react-quill";
import InputSearch from "../../../../../components/controls/InputSearch";
import {
  FormAutocomplete,
  ImageCustom,
  LoadingIndicator,
} from "../../../../../components";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import IconLocation from "../../../../../assets/images/common/icon_location_fill_grey.svg";
import IconLanguage from "../../../../../assets/images/common/icon_earth_fill_grey.svg";
import SuggestBox, { Suggest } from "../../SuggestBox";
import {
  IGetOutlinePostRes,
  ITop5HeadingReq,
  IUpdatePostReq,
} from "../../../../../models/outline";
import OutlineService from "../../../../../services/api/outline.service";
import { IValue } from "../../../../../models/common/models.type";
import { useParams } from "react-router-dom";
import RegionService from "../../../../../services/api/region.service";
import { localStorageHanlde } from "../../../../../services/services";
import { IBreadcrumbs } from "../../../../../models/redux/breadcrumbs";
import { BREADCRUMBS_KEY } from "../../../../../services/local/local-storage";
import IconSpeedoMeter from "../../../../../assets/images/common/speedometer_fill_green.svg";
import { toggleMessage } from "../../../../../components/Toast/Toast";

const toolbarOptions = [
  [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "link",
    "image",
    "video",
    "formula",
    { header: 1 },
    { header: 2 },
    { list: "ordered" },
    { list: "bullet" },
    { list: "check" },
    { script: "sub" },
    { script: "super" },
    { indent: "-1" },
    { indent: "+1" },
    { direction: "rtl" },
    {
      size: [
        "9px",
        "10px",
        "11px",
        "12px",
        "14px",
        "16px",
        "18px",
        "20px",
        "22px",
        "24px",
        "26px",
        "28px",
      ],
    },
    { header: [1, 2, 3, 4, 5, 6, false] },
    { color: [] },
    { background: [] },
    { font: [] },
    { align: [] },
    "clean",
  ],
];

export default function Post() {
  const { id } = useParams();
  const quillRef = useRef<ReactQuill>(null);

  const [content, setContent] = useState("");
  const [dataRes, setDataRes] = useState<Suggest[]>([]);
  const [dataResSingle, setDataResSingle] = useState<IGetOutlinePostRes | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listCountries, setListCountries] = useState<IValue[]>([]);
  const [listLanguage, setListLanguage] = useState<IValue[]>([]);

  useEffect(() => {
    if (!!id && id.length > 0) {
      getSinglePost();
      getDataSelect();
    }
  }, [id]);

  useEffect(() => {
    return () => {
      localStorageHanlde.clearBreadcrumbs();
    };
  }, []);

  const getSinglePost = async () => {
    const res = await OutlineService.getSinglePost(id || "");
    if (res.success) {
      setDataResSingle(res.data);
      const dataLocal: IBreadcrumbs[] = JSON.parse(
        localStorage.getItem(BREADCRUMBS_KEY) || "[]"
      );
      if (!dataLocal.find(breadcrumbs => breadcrumbs.title === res.data.name)) {
        const data = [...dataLocal, { title: res.data.name }];
        localStorageHanlde.storeBreadcrumbs(data);
      }
    }
  };

  const getDataSelect = async () => {
    const resCountries = await RegionService.getAllCountries();
    if (resCountries.success) {
      const dataCountryCode: IValue[] = resCountries.data.items.map(
        countryCode => ({
          value: countryCode.code,
          title: countryCode.displayName,
        })
      );
      setListCountries(dataCountryCode);
    }
    const resLanguage = await RegionService.getAllLanguage();
    if (resLanguage.success) {
      const dataLanguageCode: IValue[] = resLanguage.data.items.map(
        languageCode => ({
          value: languageCode.code,
          title: languageCode.displayName,
        })
      );
      setListLanguage(dataLanguageCode);
    }
  };

  const handleAddContent = (html: string) => {
    if (quillRef.current) {
      let editor = quillRef.current.getEditor();
      let range = editor.getSelection();

      if (!range) {
        range = { index: 0, length: 0 };
      }

      const [line, offset] = editor.getLine(range.index);
      const lineText = line.domNode.textContent;

      const newIndex = range.index + lineText.length - offset;
      editor.clipboard.dangerouslyPasteHTML(newIndex + 1, html);

      editor.setSelection(range.index + html.length, 0);
    }
  };

  const handleAddAllContent = (htmlArray: string[]) => {
    if (quillRef.current) {
      let editor = quillRef.current.getEditor();
      let range = editor.getSelection();
      if (!range) {
        range = { index: editor.getLength(), length: 0 };
      }

      let insertIndex = editor.getLength();

      htmlArray.forEach(html => {
        editor.clipboard.dangerouslyPasteHTML(insertIndex, html);
        insertIndex += editor.getText(insertIndex).length;
      });

      editor.setSelection(insertIndex, 0);
    }
  };

  const validationSchema = Yup.object().shape({
    keyword: Yup.string().required(`${"Please enter keyword"}`),
    countryCode: Yup.string().required(`${"Please choose the country"}`),
  }) as any;

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      keyword: "",
      countryCode: "",
      language: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (value: ITop5HeadingReq) => {
    setIsLoading(true);
    if (!value.language) {
      delete value.language;
    }
    const res = await OutlineService.getTop5Heading(value);
    if (res.success) {
      setDataRes(res.data.suggestsTop5);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const onUpdatePost = async () => {
    const keyword = getValues("keyword");
    const language = getValues("language");
    const location = getValues("countryCode");
    if (!!dataResSingle?.name && !!id) {
      const data: IUpdatePostReq = {
        name: dataResSingle?.name || "",
        id: id,
        keyword: keyword,
        language: language || "vi",
        location: location,
        outlineHtml: content,
      };
      const res = await OutlineService.updatePost(data);
      if (res.success) {
        toggleMessage({
          type: "success",
          message: "Tạo thành công bài viết",
          title: "Tạo thành công",
        });
      } else {
        toggleMessage({
          type: "error",
          message: "Tạo không thành công bài viết",
          title: "Tạo không thành công",
        });
      }
    }
  };

  useEffect(() => {
    if (!!dataResSingle) {
      setContent(dataResSingle.outlineHtml);
      setValue("language", dataResSingle.language);
      setValue("countryCode", dataResSingle.location);
      setValue("keyword", dataResSingle.keyword);
      if (!!dataResSingle.language && !!dataResSingle.location) {
        onSubmit({
          language: dataResSingle.language,
          countryCode: dataResSingle.location,
          keyword: dataResSingle.keyword,
        });
      }
    }
  }, [dataResSingle]);

  return (
    <PostWrapper>
      <Helmet>
        <title>Outline</title>
      </Helmet>

      <div className="post-box-left">
        <div className="left-form-wrapper">
          <Title
            highlightTitle="Outline"
            children={
              <p>
                Lorem ipsum dolor sit amet,consectetur adipiscing elit, sed do
                xzczx eiusmod tem.
              </p>
            }
            marginBottom={24}
          />
          <form className="left-form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name={"keyword"}
              control={control}
              render={({ field }) => (
                <InputSearch
                  placeholder={"Tìm kiếm"}
                  onChange={field.onChange}
                  value={field.value}
                  size={"medium"}
                  width={"100%"}
                  typeStyle="outlined"
                  error={!!errors["keyword"]}
                  helperText={errors["keyword"]?.message}
                />
              )}
            />

            <div className="select-wrapper">
              <FormAutocomplete
                name="countryCode"
                control={control}
                errors={errors}
                maxHeight="350px"
                options={listCountries}
                placeholder="Quốc gia"
                startIcon={IconLocation}
                isFreeSolo
              />
              <FormAutocomplete
                name="language"
                control={control}
                errors={errors}
                maxHeight="350px"
                options={listLanguage}
                placeholder="Ngôn ngữ"
                startIcon={IconLanguage}
                isFreeSolo
              />
            </div>
            <Button
              size="small"
              variant="contained"
              className={"reset-button"}
              onClick={handleSubmit(onSubmit)}
            >
              Tìm kiếm
            </Button>
          </form>
        </div>
        {isLoading ? (
          <LoadingIndicator className="suggest-loading" />
        ) : (
          <ul className="suggest-list scrollbar-small">
            {dataRes.map((data, idx) => (
              <li key={idx}>
                <SuggestBox
                  data={data}
                  onAddAction={v => {
                    handleAddContent(v);
                  }}
                  onAddAllAction={v => {
                    handleAddAllContent(v);
                  }}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="post-box-right">
        <div className="right-action">
          <div className="peformance-box">
            <ImageCustom
              src={IconSpeedoMeter}
              alt="Icon-SpeedoMeter"
              width={"32px"}
              height={"32px"}
            />
            <p>12/100</p>
          </div>
          <Button
            size="small"
            variant="contained"
            className={`save-button`}
            onClick={onUpdatePost}
          >
            Lưu
          </Button>
        </div>
        <TextEditorQuill
          value={content}
          onChange={setContent}
          placeholder="Nhập nội dung tại đây..."
          toolbar={true}
          toolbarOptions={toolbarOptions}
          quillRef={quillRef}
          className="text-editor"
        />
      </div>
    </PostWrapper>
  );
}
