import EN from "./locales/en";
import VI from "./locales/vn";
import i18next from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

export interface ILang {
  Lang: {
    Code: string;
    TextEN: string;
    TextVN: string;
  };
  Common: {};
  HeaderMenu: {};
  Login: {
    ErrorEmail: string;
    LoginDescription: string;
  };
  ReportHeader: {};
  FooterHeader: {};
  ModalText: {};
  NoData: {};
}

export enum CountryLanguage {
  "EN" = "EN",
  "VI" = "VI",
}

const locale = localStorage.getItem("language");

i18next.use(initReactI18next).init({
  compatibilityJSON: "v3",
  fallbackLng: [locale ? locale.toUpperCase() : `${CountryLanguage.VI}`],
  debug: false,
  resources: {
    EN: {
      translation: EN,
    },
    VI: {
      translation: VI,
    },
  },
});

const useLanguage = () => {
  const { t } = useTranslation();
  return t;
};

const changeLanguage = (value: CountryLanguage) => {
  Lang.changeLanguage(value);
  localStorage.setItem("language", value);
};

const useI18n = () => {
  const { i18n } = useTranslation();
  return i18n;
};
export { useLanguage, useI18n, changeLanguage };

const Lang = i18next;

export default Lang;
