import { styled } from "@mui/material/styles";
import { Modal } from "../..";
import variableStyles from "../../../theme/variable-styles";

export const ReportFormWrapper = styled("div")(() => ({
  backgroundColor: variableStyles.NaturalColor0,
  padding: "16px 12px",
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  gap: "12px",
}));
