import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { ControlForm } from "../../../../components";
import InputForm from "../../../../components/controls/InputForm";
import { IStateApp } from "../../../../models/redux";
import { IUser } from "../../../../models/user";
import { ProfileInforBoxWrapper } from "../../styles";

interface ProfileInfoBoxProps {
  className?: string;
}

export default function ProfileInforBox(props: ProfileInfoBoxProps) {
  const { className } = props;
  const regex =
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(\/*)$/;
  const currentUser = useSelector<IStateApp, IUser>(
    appState => appState.user.data
  );

  useEffect(() => {
    if (!!currentUser.userId) {
      setValue("fullName", currentUser.fullName);
      setValue("email", currentUser.email);
      setValue("phoneNumbers", currentUser.phoneNumbers);
    }
  }, [currentUser]);

  const validationSchema = Yup.object().shape({
    projectName: Yup.string().required(`${"Vui lòng nhập tên dự án"}`),
    domain: Yup.string()
      .required(`${"Vui lòng nhập URL"}`)
      .matches(regex, "Vui lòng nhập đúng URL"),
  }) as any;

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      projectName: "",
      domain: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async () => {};
  return (
    <ProfileInforBoxWrapper className={className}>
      <div className="infor-form">
        <h2 className="infor-form-title">Thông tin cá nhân</h2>
        <div className="infor-form-wrapper">
          <ControlForm
            title="Tên"
            infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
          >
            <InputForm
              placeholder="Tên"
              required
              name="fullName"
              errors={errors}
              control={control}
              size="small"
            />
          </ControlForm>
          <ControlForm
            title="Email"
            infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
          >
            <InputForm
              placeholder="Email"
              required
              name="email"
              errors={errors}
              control={control}
              size="small"
            />
          </ControlForm>
          <ControlForm
            title="Số điện thoại"
            infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
          >
            <InputForm
              placeholder="Số điện thoại"
              required
              name="phoneNumbers"
              errors={errors}
              control={control}
              size="small"
            />
          </ControlForm>
        </div>
      </div>
      <div className="infor-form">
        <h2 className="infor-form-title">Thông tin doanh nghiệp</h2>
        <div className="infor-business-form-wrapper">
          <ControlForm
            title="Tên công ty"
            infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
          >
            <InputForm
              placeholder="Tên công ty"
              required
              name="projectName"
              errors={errors}
              control={control}
              size="small"
            />
          </ControlForm>
          <ControlForm
            title="Số điện thoại"
            infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
          >
            <InputForm
              placeholder="Số điện thoại"
              required
              name="projectName"
              errors={errors}
              control={control}
              size="small"
            />
          </ControlForm>
          <ControlForm
            title="Mã số thế"
            infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
          >
            <InputForm
              placeholder="Email"
              required
              name="projectName"
              errors={errors}
              control={control}
              size="small"
            />
          </ControlForm>
          <ControlForm
            title="Địa chỉ"
            infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            classname="address-input"
          >
            <InputForm
              placeholder="Email"
              required
              name="projectName"
              errors={errors}
              control={control}
              size="small"
            />
          </ControlForm>
        </div>
      </div>
      <Button
        size="xsmall"
        variant="contained"
        className="button-submit"
        onClick={handleSubmit(onSubmit)}
        disabled={!isDirty}
      >
        Lưu thay đổi
      </Button>
    </ProfileInforBoxWrapper>
  );
}
