import MyProjectIcon from "../../assets/images/sidebar/icon_myproject_line_grey.svg";
import MyProjectIconActive from "../../assets/images/sidebar/icon_myproject_active.svg";
import WebAuditIcon from "../../assets/images/sidebar/icon_domainaudit_line_grey.svg";
import WebAuditIconActive from "../../assets/images/sidebar/icon_domainaudit_active.svg";
import WebCompetitiveIcon from "../../assets/images/sidebar/icon_domaincompetitive_line_grey.svg";
import WebCompetitiveIconActive from "../../assets/images/sidebar/icon_domaincompetitive_active.svg";
import PageAuditIcon from "../../assets/images/sidebar/icon_pageaudit_line_grey.svg";
import PageAuditIconActive from "../../assets/images/sidebar/icon_pageaudit_active.svg";
import PageCompetitiveIcon from "../../assets/images/sidebar/icon_pagecompetitive_line_grey.svg";
import PageCompetitiveIconActive from "../../assets/images/sidebar/icon_pagecompetitive_active.svg";
import OptimizeHeaderIcon from "../../assets/images/sidebar/icon_optimizeheader_line_grey.svg";
import OptimizeHeaderIconActive from "../../assets/images/sidebar/icon_optimizeheader_active.svg";
import KeywordDensityIcon from "../../assets/images/sidebar/icon_keyworddensity_line_grey.svg";
import KeywordDensityIconActive from "../../assets/images/sidebar/icon_keyworddensity_active.svg";
import PotentialKeywordIcon from "../../assets/images/sidebar/icon_potentialkeyword_line_grey .svg";
import PotentialKeywordIconActive from "../../assets/images/sidebar/icon_potentialkeyword_active .svg";
import KeywordGroupIcon from "../../assets/images/sidebar/icon_keywordgroup_line_grey.svg";
import KeywordGroupIconActive from "../../assets/images/sidebar/icon_keywordgroup_active.svg";
import CompressPhotoIcon from "../../assets/images/sidebar/icon_compressphoto_line_grey.svg";
import CompressPhotoIconActive from "../../assets/images/sidebar/icon_compressphoto_active.svg";
import OutlineIcon from "../../assets/images/sidebar/icon_outline_line_grey.svg";
import OutlineIconActive from "../../assets/images/sidebar/icon_outline_active.svg";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import i18n from "i18next";

export const menuData = [
  { text: "HeaderMenu.Aboutus", link: "/about-us" },
  { text: "HeaderMenu.Service", link: "/service" },
  { text: "HeaderMenu.Pricing", link: ROUTE_PATH.PRICING },
  { text: "HeaderMenu.Project", link: "/project" },
  { text: "HeaderMenu.FreeTools", link: "/free-tools" },
];

export const sidebarData = [
  {
    title: `${i18n.t("Common.MyProject")}`,
    children: [
      {
        text: "My Project",
        link: "/",
        icon: { default: MyProjectIcon, active: MyProjectIconActive },
      },
    ],
  },
  {
    title: `${i18n.t("Common.Overview")}`,
    children: [
      {
        text: "Web audit",
        link: "/about-us",
        icon: { default: WebAuditIcon, active: WebAuditIconActive },
      },
      {
        text: "Web competitive",
        link: "/about-us",
        icon: { default: WebCompetitiveIcon, active: WebCompetitiveIconActive },
      },
      {
        text: "Page audit",
        link: "/about-us",
        icon: { default: PageAuditIcon, active: PageAuditIconActive },
      },
      {
        text: "Page Competitive",
        link: "/about-us",
        icon: {
          default: PageCompetitiveIcon,
          active: PageCompetitiveIconActive,
        },
      },
    ],
  },
  {
    title: `${i18n.t("HeaderMenu.FreeTools")}`,
    children: [
      {
        text: "Optimize Header",
        link: "/about-us",
        icon: { default: OptimizeHeaderIcon, active: OptimizeHeaderIconActive },
      },
      {
        text: "Keyword Density",
        link: "/about-us",
        icon: { default: KeywordDensityIcon, active: KeywordDensityIconActive },
      },
      {
        text: "Potential Keyword",
        link: "/about-us",
        icon: {
          default: PotentialKeywordIcon,
          active: PotentialKeywordIconActive,
        },
      },
      {
        text: "Keyword Group",
        link: "/about-us",
        icon: { default: KeywordGroupIcon, active: KeywordGroupIconActive },
      },
      {
        text: "Compress photo",
        link: "/about-us",
        icon: { default: CompressPhotoIcon, active: CompressPhotoIconActive },
      },
      {
        text: "Outline",
        link: "/about-us",
        icon: { default: OutlineIcon, active: OutlineIconActive },
      },
    ],
  },
];
