import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import CommonHandle from "../../../../common/handles/common.handles";
import { ECurrencyCode } from "../../../../models/common/models.enum";
import { IPlan } from "../../../../models/plan";
import { IStateApp } from "../../../../models/redux";
import { PaymentSummaryWrapper } from "../../styles";
import { EPaymentSteps } from "../PaymentSteps";

interface PaymentSummaryProps {
  currentTab: EPaymentSteps;
  handleChange: (step: EPaymentSteps) => void;
}

export default function PaymentSummary(props: PaymentSummaryProps) {
  const { currentTab, handleChange } = props;

  const currentPlan = useSelector<IStateApp, IPlan | null>(
    appState => appState.planInfor.planInfor
  );

  const handleChangeStep = () => {
    let step: EPaymentSteps;
    switch (currentTab) {
      case EPaymentSteps.CustomerInfor:
        step = EPaymentSteps.PaymentMethod;
        break;
      default:
        step = EPaymentSteps.PaymentResult;
        break;
    }
    handleChange(step);
  };
  return (
    <PaymentSummaryWrapper>
      <div className="summary-content flex-between-align-center">
        <div className="total-content">
          <p className="content-title">{currentPlan?.title}</p>
          <p className="content-detail">
            Tổng cộng:{" "}
            <span>
              {CommonHandle.formatCurrency(
                (currentPlan?.price || 0) + 0,
                ECurrencyCode.VND
              )}
            </span>
          </p>
        </div>
        <Button
          size="xsmall"
          variant="contained"
          className="button-submit"
          onClick={handleChangeStep}
        >
          Tiếp tục
        </Button>
      </div>
    </PaymentSummaryWrapper>
  );
}
