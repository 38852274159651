import { ILang } from "../i18n";

const VI: ILang = {
  Lang: {
    Code: "VN",
    TextEN: "Tiếng Anh",
    TextVN: "Tiếng Việt",
  },
  Common: {
    Update: "Cập nhật",
    Logout: "Đăng xuất",
    Login: "Đăng nhập",
    Profile: "Hồ sơ",
    Setting: "Cài đặt",
    Support: "Hổ trợ",
    Overview: "Tổng quan",
    Project: "Dự án",
    MyProject: "Dự án của tôi",
    CreateProject: "Tạo dự án",
    EnterLink: "Nhập đường dẫn",
    Type: "Loại",
    ChooseType: "Chọn loại",
    Search: "Tìm kiếm",
    CreatedAt: "Ngày tạo",
    Status: "Trạng thái",
    ChooseStatus: "Chọn trạng thái",
    Domain: "Tên miền",
    ReportType: "Loại báo cáo",
    Report: "Báo cáo",
    CreateReport: "Tạo báo cáo",
    DownloadReport: "Tải báo cáo",
    Share: "Chia sẻ",
    Filter: "Bộ lọc",
    DisplayBy: "Hiển thị theo",
    Delete: "Xoá",
    Open: "Mở",
    ChooseTime: "Chọn thời gian",
    DeleteFilter: "Xoá bộ lọc",
    Apply: "Áp dụng",
    ProjectName: "Tên dự án",
    NumbersReport: "Số lượng báo cáo",
    Success: "Thành công",
    Fail: "Thất bại",
  },
  HeaderMenu: {
    Aboutus: "Về chúng tôi",
    Service: "Dịch vụ",
    Pricing: "Bảng giá",
    Project: "Dự án",
    FreeTools: "Công cụ miễn phí",
  },
  Login: {
    ErrorEmail: "Vui lòng nhập email!",
    LoginDescription: "Nhập email và mật khẩu của bạn để đăng nhập.",
  },
  ReportHeader: {},
  FooterHeader: {},
  ModalText: {
    LogoutModalTitle: "Đăng xuất",
    LogoutModalDescription: "Bạn có muốn đăng xuất khỏi thiết bị này?",
    Cancel: "Huỷ",
    Confirm: "Xác nhận",
    LoginSessionTitle: "Phiên đăng nhập",
    LoginSessionDescription:
      "Phiên đăng nhập của bạn đã hết hạn, vui lòng đăng nhập lại để tiếp tục!",
    DeleteSuccessTitle: "Xoá thành công",
    DeleteReportSuccessDescription: "Xoá báo cáo thành công!",
    DeleteProjectSuccessDescription: "Xoá dự án thành công!",
    DeleteFailTitle: "Xoá không thành công",
    DeleteReportFailDescription: "Xoá báo cáo không thành công!",
    DeleteProjectFailDescription: "Xoá dự án không thành công!",
    DeleteReportTitle: "Xoá báo cáo",
    DeleteReportDescription: "Bạn có chắc chắc muốn xoá báo cáo này không?",
    DeleteProjectTitle: "Xoá dự án",
    DeleteProjectDescription: "Bạn có chắc chắc muốn xoá dự án này không?",
    GetProjectsFail: "Lấy danh sách dự án không thành công",
  },
  NoData: {
    NoProjectCreatedTitle: "Chưa có báo cáo nào được tạo",
    NoProjectCreatedDescription:
      "Tạo báo cáo để sử dụng các báo cáo của chúng tôi",
  },
};

export default VI;
