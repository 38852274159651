import React from "react";
import { NoDataBoxWrapper } from "./styles";
import IconAdd from "../../assets/images/common/icon_plus_line_white.svg";
import { Button } from "@mui/material";
import { ImageCustom } from "..";
import ImageNoData from "../../assets/images/common/img_project_empty.png";
import { useTranslation } from "react-i18next";

interface INoDataBoxProps {
  onClick?: () => void;
  title?: string;
  description?: string;
  buttonText?: string;
  hasImage?: boolean;
}

export default function NoDataBox(props: INoDataBoxProps) {
  const { t } = useTranslation();
  const {
    title = `${t("NoData.NoProjectCreatedTitle")}`,
    description = `${t("NoData.NoProjectCreatedDescription")}`,
    onClick,
    buttonText = `${t("Common.CreateReport")}`,
    hasImage = false,
  } = props;

  return (
    <NoDataBoxWrapper className="flex-column-center">
      {!!hasImage && (
        <ImageCustom
          src={ImageNoData}
          alt="Icon-NoData"
          width={"120px"}
          height={"120px"}
        />
      )}
      <p className="nodata-title">{title}</p>
      <p className="nodata-description">{description}</p>
      {!!onClick && (
        <Button
          color="primary"
          size="xsmall"
          variant="contained"
          onClick={onClick}
          className="add-create"
        >
          <ImageCustom
            src={IconAdd}
            alt="Icon-Add"
            width={"18px"}
            height={"18px"}
          />
          <p>{buttonText}</p>
        </Button>
      )}
    </NoDataBoxWrapper>
  );
}
