import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import Breadcrumbs from "../Breadcrumbs";
import variableStyles from "../../theme/variable-styles";
import {
  BREADCRUMBS_KEY,
  REPORT_ID_KEY,
  USER_TOKEN_KEY,
} from "../../services/local/local-storage";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { IBreadcrumbs } from "../../models/redux/breadcrumbs";
import CreateProjectModal from "../Modal/CreateProjectModal";
import { useDispatch, useSelector } from "react-redux";
import CreateReportModal from "../Modal/CreateReportModal";
import ReportService from "../../services/api/report.service";
import { setReportState } from "../../redux/project/project.duck";
import { clearUser, getUser } from "../../redux/user/user.duck";
import { jwtDecode } from "jwt-decode";
import { toggleConfirmModal } from "../ConfirmModal/ConfirmModal";
import { IStateApp } from "../../models/redux";
import { IUser } from "../../models/user";
import { useTranslation } from "react-i18next";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  flex: "1 1 auto",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  overflow: "hidden",
});

export const PageWrapper = styled("div")(() => ({
  flex: "1 1 auto",
  maxHeight: "calc(100vh - 94px)",
  overflow: "auto",
  backgroundColor: variableStyles.NaturalColor0,
  "&.fullHeight": { maxHeight: "calc(100vh - 52px)" },
  "& .MuiTableHead-root": {
    backgroundColor: "rgb(243, 244, 246)",
  },
}));

const DashboardLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector<IStateApp, IUser>(
    appState => appState.user.data
  );

  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbs[]>([]);

  window.addEventListener("storageBreadcrumbs", () => {
    getBreadcrumbs();
  });

  const getBreadcrumbs = () => {
    const data: IBreadcrumbs[] = JSON.parse(
      localStorage.getItem(BREADCRUMBS_KEY) || "[]"
    );

    if (!!data && data.length > 0) {
      setBreadcrumbs(data);
    } else {
      setBreadcrumbs([]);
    }
  };

  const getReport = async (id: string) => {
    const res = await ReportService.getReport(id);
    if (!res.isError) {
      dispatch(setReportState(res.data));
    }
  };

  useEffect(() => {
    const report_id = localStorage.getItem(REPORT_ID_KEY);
    if (!!report_id) {
      getReport(report_id);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(USER_TOKEN_KEY);
    if (!token) {
      navigate(ROUTE_PATH.LOGIN);
    } else {
      try {
        const userDecode: {
          userid: string;
          email: string;
          fullname: string;
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string;
          exp: number;
          iss: string;
          aud: string;
        } = jwtDecode(token);
        const nowTime = new Date(Date.now()).getTime() / 1000;
        if (Number(nowTime.toFixed(0)) < userDecode.exp) {
          if (!currentUser.userId) {
            dispatch(getUser(userDecode.email, navigate));
          }
          getBreadcrumbs();
        } else {
          toggleConfirmModal({
            open: true,
            title: `${t("ModalText.LoginSessionTitle")}`,
            content: `${t("ModalText.LoginSessionTitle")}`,
            onSubmit: () => asyncFuncNavigate(),
            submitText: `${t("Common.Login")}`,
            type: "infor",
            isNotCancel: true,
          });
        }
      } catch (err) {
        localStorage.removeItem(USER_TOKEN_KEY);
        dispatch(clearUser());
        navigate(ROUTE_PATH.LOGIN);
      }
    }
  }, [navigate]);

  const asyncFuncNavigate: () => Promise<void> = async () => {
    localStorage.removeItem(USER_TOKEN_KEY);
    navigate(ROUTE_PATH.LOGIN);
  };

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar />
      <DashboardLayoutContainer>
        <DashboardSidebar />
        <DashboardLayoutWrapper>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <CreateProjectModal />
          <CreateReportModal />
          <PageWrapper
            className={`scrollbar ${!breadcrumbs || (!!breadcrumbs && breadcrumbs.length <= 1) ? "fullHeight" : ""}`}
          >
            <Outlet />
          </PageWrapper>
        </DashboardLayoutWrapper>
      </DashboardLayoutContainer>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
