import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { ROUTE_PATH } from "../../../../../../../../../common/constants/app.constant";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../../../../../common/constants/value.constant";
import { Modal, ProgressBar } from "../../../../../../../../../components";
import {
  ITableNavDetailNoPaginationHeader,
  TableNavDetailNoPagination,
} from "../../../../../../../../../components/Table/TableNavDetailNoPagination";
import { IGetDomainOrganicAccessRes } from "../../../../../../../../../models/audit-report";
import { ETypeLinkCheck } from "../../../../../../../../../models/common/models.enum";
import { IBasePagingRes } from "../../../../../../../../../models/common/models.type";
import { IStateApp } from "../../../../../../../../../models/redux";
import { IGetReportsRes } from "../../../../../../../../../models/report";

export default function ContentTableBox(props: { data: any }) {
  const { data } = props;
  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );

  const [resDataAccessHighPaging, setResDataAccessHighPaging] =
    useState<IBasePagingRes<IGetDomainOrganicAccessRes>>();
  const [resDataAccessLowPaging, setResDataAccessLowPaging] =
    useState<IBasePagingRes<IGetDomainOrganicAccessRes>>();
  const [resDataAccessHigh, setResDataAccessHigh] =
    useState<IGetDomainOrganicAccessRes[]>();
  const [resDataAccessLow, setResDataAccessLow] =
    useState<IGetDomainOrganicAccessRes[]>();
  const [openModal, setOpenModal] = useState<"high" | "low" | null>(null);

  useEffect(() => {
    if (!!data) {
      getDataofPage();
    }
  }, [data]);

  const getDataofPage = () => {
    if (!!data.domainOrganicPages && !!data.domainOrganicPages.itemsDesc) {
      setResDataAccessHigh(data.domainOrganicPages.itemsDesc);
    }
    if (!!data.domainOrganicPages && !!data.domainOrganicPages.itemsAsc) {
      setResDataAccessLow(data.domainOrganicPages.itemsAsc);
    }
  };

  const headersTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "url",
      title: `Domain`,
      width: "250px",
      className: "table-cell-link",
      isSticky: true,
      isLink: true,
    },
    {
      field: "numberOfKeywords",
      title: `Organic Keywords`,
      width: "120px",
    },
    {
      field: "traffic",
      title: `Organic Traffic`,
      width: "120px",
    },
    {
      field: "trafficPercent",
      title: `% Traffic `,
      width: "160px",
      className: "table-cell-progress",
      handleItem: (item: any) => {
        return (
          <div>
            <p className="progress-percent">{item.trafficPercent}%</p>
            <ProgressBar
              percent={item.trafficPercent}
              className="progress-bar"
              type={"normal"}
              height={"4px"}
            />
          </div>
        );
      },
    },
  ];

  const headersLowTrafficTable: ITableNavDetailNoPaginationHeader<any>[] = [
    {
      field: "url",
      title: `Domain`,
      width: "250px",
      className: "table-cell-link",
      isSticky: true,
      isLink: true,
    },
    {
      field: "numberOfKeywords",
      title: `Organic Keywords`,
      width: "120px",
    },
    {
      field: "traffic",
      title: `Organic Traffic`,
      width: "120px",
    },
    {
      field: "trafficPercent",
      title: `% Traffic `,
      width: "160px",
      className: "table-cell-progress",
      handleItem: (item: any) => {
        return (
          <div>
            <p className="progress-percent">{item.trafficPercent}%</p>
            <ProgressBar
              percent={item.trafficPercent}
              className="progress-bar"
              type={"warning"}
              height={"4px"}
            />
          </div>
        );
      },
    },
  ];

  const handleChangePage = (
    data: IGetDomainOrganicAccessRes[],
    page: number,
    type: "high" | "low"
  ) => {
    const newData = {
      page: page,
      pageSize: 10,
      total: data.length,
      items: [...data]?.slice(page * 10 - 10, page * 10),
      isFull: true,
    };
    if (type === "high") {
      setResDataAccessHighPaging(newData);
      if (openModal !== "high") {
        setOpenModal("high");
      }
    } else {
      setResDataAccessLowPaging(newData);
      if (openModal !== "low") {
        setOpenModal("low");
      }
    }
  };

  return (
    <>
      <Modal
        title={
          openModal === "high"
            ? "Lượng truy cập hàng đầu"
            : "Lượng truy cập thấp"
        }
        open={!!openModal}
        width={"744px"}
        onClose={() => setOpenModal(null)}
        textSubmit="Xem tất cả"
        onSubmit={() => {
          const path = generatePath(ROUTE_PATH.ORGANIC_KEYWORD_CHECK);
          let newParams = {};
          newParams = {
            tab: "toppage",
            target: `https://${dataReport.target}`,
            type: ETypeLinkCheck.RootDomain,
          };
          const paramSearch = queryString.stringify(newParams);

          const newWindow = window.open(
            `${path}?${paramSearch?.toLocaleLowerCase()}`,
            "_blank",
            "noopener,noreferrer"
          );
          if (newWindow) newWindow.opener = null;
        }}
        maxheight="calc(100vh - 88px)"
      >
        {openModal === "high" ? (
          <>
            <TableNavDetailNoPagination
              headersTable={headersTable}
              data={resDataAccessHighPaging}
              handleViewMore={() => {}}
              isFullTable
              handlePageChange={page => {
                if (!!resDataAccessHigh)
                  handleChangePage(resDataAccessHigh, page, "high");
              }}
              maxHeight={"calc(100vh - 280px)"}
            />
          </>
        ) : (
          <>
            <TableNavDetailNoPagination
              headersTable={headersLowTrafficTable}
              data={resDataAccessLowPaging}
              handleViewMore={() => {}}
              isFullTable
              handlePageChange={page => {
                if (!!resDataAccessLow) {
                  handleChangePage(resDataAccessLow, page, "low");
                }
              }}
              maxHeight={"calc(100vh - 280px)"}
            />
          </>
        )}
      </Modal>
      <div className="issue-table-wrapper">
        <TableNavDetailNoPagination
          headersTable={headersTable}
          data={{
            ...DEFAULT_BASE_PAGING_RES,
            items: resDataAccessHigh?.slice(0, 10) || [],
          }}
          titleText="Lượng truy cập hàng đầu"
          handleViewMore={() => {
            if (!!resDataAccessHigh) {
              handleChangePage(resDataAccessHigh, 1, "high");
            }
          }}
        />
        <TableNavDetailNoPagination
          headersTable={headersLowTrafficTable}
          data={{
            ...DEFAULT_BASE_PAGING_RES,
            items: resDataAccessLow?.slice(0, 10) || [],
          }}
          titleText="Lượng truy cập thấp"
          handleViewMore={() => {
            if (!!resDataAccessLow) {
              handleChangePage(resDataAccessLow, 1, "low");
            }
          }}
        />
      </div>
    </>
  );
}
