import { EPaymentSteps } from "../../../pages/Payment/components/PaymentSteps";
import { EPlanPackage } from "../../common/models.enum";
import { IPlan } from "../../plan";

export interface IReduxPlanInforState {
  planInfor: IPlan | null;
}

export enum EPlanInforType {
  SET_PLANINFOR_STATE = "SET_PLANINFOR_STATE",
  CLEAR_PLANINFOR = "CLEAR_PLANINFOR",
  SET_PAYMENT_STEP_STATE = "SET_PAYMENT_STEP_STATE",
  CLEAR_PAYMENT_STEP = "CLEAR_PAYMENT_STEP",
}
export interface IActionSetPlanInforState {
  type: EPlanInforType.SET_PLANINFOR_STATE;
  payload: IPlan;
}

export interface IActionClearPlanInfor {
  type: EPlanInforType.CLEAR_PLANINFOR;
  payload: {};
}

export type IActionPlanInfor = IActionSetPlanInforState | IActionClearPlanInfor;
