import queryString from "query-string";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { Avatar, PlanBox } from "../../components";
import { IValue } from "../../models/common/models.type";
import { IStateApp } from "../../models/redux";
import { IUser } from "../../models/user";
import ProfileHistoryBox from "./components/ProfileHistoryBox";
import ProfileInforBox from "./components/ProfileInforBox";
import ProfilePaymentBox from "./components/ProfilePaymentBox";
import { ProfileWrapper } from "./styles";

enum Tabs {
  INFOR = "infor",
  PAYMENT = "payment",
  HISTORY = "history",
}

export default function Profile() {
  const currentUser = useSelector<IStateApp, IUser>(
    appState => appState.user.data
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dataTab: { tab: string } = {
    tab: searchParams.get("tab") || Tabs.INFOR,
  };

  const tabsData: IValue[] = [
    { title: "Thông tin tài khoản", value: Tabs.INFOR },
    { title: "Thông tin thanh toán", value: Tabs.PAYMENT },
    { title: "Lịch sử thanh toán", value: Tabs.HISTORY },
  ];
  return (
    <ProfileWrapper>
      <div className="profile-header flex-between-align-center">
        <div className="header-infor flex-start-align-center">
          <figure className="infor-avatar">
            <Avatar src={""} alt="Avatar-Image" width={80} height={80} />
          </figure>
          <div className="infor-content">
            <p className="content-name">{currentUser.fullName}</p>
            <p className="content-email">{currentUser.email}</p>
          </div>
        </div>
        <PlanBox className="header-plan-box" />
      </div>
      <div className="profile-tabs">
        <ul className="tabs-list flex-start-align-center">
          {tabsData.map(item => (
            <li
              key={item.title}
              className={`${dataTab.tab === item.value ? "active" : ""}`}
            >
              <button
                onClick={() => {
                  if (dataTab.tab !== item.value) {
                    const newParams = queryString.stringify({
                      tab: item.value,
                    });
                    const path = generatePath(ROUTE_PATH.PROFILE);
                    navigate({
                      pathname: `${path}`,
                      search: `${newParams}`,
                    });
                  }
                }}
              >
                {item.title}
              </button>
            </li>
          ))}
        </ul>
        <div className="profile-box-wrapper scrollbar-small">
          <ProfileInforBox
            className={`${dataTab.tab !== Tabs.INFOR ? "hidden-box" : "visible-box"} tab-content`}
          />
          <ProfilePaymentBox
            className={`${dataTab.tab !== Tabs.PAYMENT ? "hidden-box" : "visible-box"} tab-content`}
          />
          <ProfileHistoryBox
            className={`${dataTab.tab !== Tabs.HISTORY ? "hidden-box" : "visible-box"} tab-content`}
          />
        </div>
      </div>
    </ProfileWrapper>
  );
}
