import { Popover } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

interface SidebarWrapperProps {
  notexpanded: boolean;
}

export const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

export const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingLeft: "300px",
  transition: "all 0.5s ease-in-out",
  paddingBottom: theme.spacing(10),
  backgroundColor: theme.palette.background.default,
}));

export const SidebarWrapper = styled("div")<SidebarWrapperProps>(
  ({ theme, notexpanded }) => ({
    flex: "0 0 auto",
    position: "relative",
    borderRight: `1px solid ${variableStyles.NaturalColor200}`,
    "@keyframes hidden": {
      "0%": {
        opacity: "1",
      },
      "50%": {
        opacity: "1",
      },
      "100%": {
        display: "none",
        opacity: "0",
      },
    },
    "& .sidebar-box": {
      width: notexpanded ? "64px" : "236px",
      backgroundColor: variableStyles.NaturalColor100,
      transition: "all 0.25s linear",
      height: "calc(100vh - 52px)",
      overflow: "hidden",
    },
    "& .expanded-button": {
      position: "absolute",
      top: "80px",
      right: "-15px",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      border: `0.5px solid ${variableStyles.NaturalColor200}`,
      zIndex: "1",
      backgroundColor: variableStyles.NaturalColor0,
      "&:hover": { backgroundColor: variableStyles.NaturalColor0 },
      boxShadow:
        "0px 39px 67.8px 0px #1956580A, 0px 21px 37px -15px #1956580F,0px 1px 1px 0px #00000014",
      "& img": { transition: "all 0.25s linear" },
      "&.notExpanded": {
        "& img": { transform: "rotate(180deg)" },
      },
    },
    "& .user-infor-wrapper": {
      padding: "12px 10px 4px",
      display: "flex",
      justifyContent: "center",
      "& .user-infor": {
        width: "100%",
        justifyContent: "space-between",
        padding: "8px 4px",
        borderRadius: "10px",
        "& .infor-box": {
          display: "flex",
          gap: "14px",
          alignItems: "center",
          flex: "1 1 auto",
          "& .user-name": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "4px",
            flex: "1 1 auto",
            maxWidth: "136px",
            "& .name-user": {
              color: variableStyles.NaturalColor950,
              fontWeight: variableStyles.fwSemiBold,
              fontSize: "14px",
              lineHeight: "14px",
              width: "max-content",
              maxWidth: "100%",
              textAlign: "left",
            },
            "& .email-user": {
              color: variableStyles.NaturalColor900,
              fontWeight: variableStyles.fwRegular,
              fontSize: "12px",
              lineHeight: "12px",
              width: "max-content",
              maxWidth: "100%",
              textAlign: "left",
            },
          },
        },
        "&.notExpanded": {
          "& .project-name, & img.arrow-right": {
            animation: "hidden .2s linear forwards",
          },
        },
        "& .arrow-right": {
          transform: "rotate(180deg)",
        },
      },
      "& .create-project-button": {
        width: "100%",
        margin: "8px 0",
        minWidth: "0",
        boxShadow: "none",
        "& p": { minWidth: "max-content" },
        "&.notExpanded": {
          width: "36px",
          "& p": { animation: "hidden .2s linear forwards" },
        },
      },
    },

    "& .sidebar-menu": {
      height: "calc(100vh - 284px)",
      overflow: "auto",
      "&.notExpanded": {
        transition: "all 0s linear 0.2s",
        height: "calc(100vh - 222px)",
      },
      "& .sidebar-item": {
        marginBottom: "24px",
        position: "relative",
        "&.has-text": {
          "&::after": {
            content: '""',
            position: "absolute",
            width: "24px",
            height: "1px",
            top: "0",
            left: "calc(50% - 12px)",
            backgroundColor: variableStyles.NaturalColor300,
            transition: "all 0.2s linear",
            transform: "scaleX(0)",
            opacity: 0,
          },
        },
        "&.notExpanded": {
          "&::after": {
            transform: "scaleX(1) !important",
            opacity: 1,
          },
        },
        "& .sidebar-title": {
          color: variableStyles.NaturalColor950,
          fontWeight: variableStyles.fwBold,
          fontSize: "12px",
          lineHeight: "12px",
          padding: "0 16px",
          marginBottom: "12px",
          transition: "all 0.2s linear",
          transform: "scaleX(1)",
          opacity: 1,
          height: "12px",
          "&.notExpanded": {
            transform: "scaleX(0)",
            opacity: 0,
            height: "12px",
          },
        },
      },
      "& .sidebar-children": {
        display: "flex",
        flexDirection: "column",
        gap: "2px",
        "& .children-item": {
          padding: "0 12px",
          position: "relative",
          "&::before": {
            content: '""',
            width: "0",
            height: "24px",
            position: "absolute",
            top: "calc(50% - 12px)",
            left: "0",
            backgroundColor: variableStyles.GreenPrimaryColor400,
            borderRadius: "0 12px 12px 0",
            transition: "all 0.2s linear",
          },
          "& button": {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "8px",
            padding: "9px",
            borderRadius: "8px",
            transition: "all 0.2s linear",
            border: `0.5px solid transparent`,
            position: "relative",
            width: "100%",
            backgroundColor: "transparent",
            "& p": {
              color: variableStyles.NaturalColor800,
              fontWeight: variableStyles.fwRegular,
              fontSize: "13px",
              lineHeight: "13px",
              transition: "all 0.2s linear",
              position: "relative",
              minWidth: "max-content",
              "&.notExpanded": { animation: "hidden .2s linear forwards" },
            },
            "&:hover": {
              backgroundColor: variableStyles.NaturalColor200,
            },
            "&:focus": {
              outline: "none",
            },
            "& .icon-box": {
              width: "20px",
              height: "20px",
              position: "relative",
              overflow: "hidden",
              flex: "0 0 auto",
              "& img": {
                position: "absolute",
                top: "0",
                left: "0",
                transition: "all 0.2s linear",
                "&.box-default": {
                  zIndex: 1,
                  transform: "scaleX(1)",
                  "&.active": { zIndex: 0, transform: "scaleX(0)" },
                },
                "&.box-active": {
                  zIndex: 0,
                  transform: "scaleX(0)",
                  "&.active": { zIndex: 1, transform: "scaleX(1)" },
                },
              },
            },
          },
          "&.active": {
            "&::before": {
              width: "4px",
            },
            "& button": {
              boxShadow: "0px 2px 3.5px 0px #0000000A",
              backgroundColor: variableStyles.NaturalColor0,
              border: `0.5px solid ${variableStyles.NaturalColor100}`,
              "& p": {
                color: variableStyles.NaturalColor950,
                fontWeight: variableStyles.fwSemiBold,
              },
            },
          },
        },
      },
    },
    "& .plan-box-wrapper": {
      padding: "14px",
      transition: "all 0.2s linear",
      position: "relative",
      minHeight: "78px",
      "& .plan-box": {
        transform: "scaleX(1)",
        minWidth: "max-content",
      },
      "&.notExpanded .plan-box": {
        animation: "hidden .2s linear forwards",
      },
      "& .not-expanded-button": {
        transition: "all 0.2s linear",
        position: "absolute",
        bottom: "27px",
        left: "14px",
        minWidth: "unset",
        transform: "scaleX(0)",
        opacity: 0,
        backgroundColor: variableStyles.NaturalColor0,
        border: `1px solid ${variableStyles.blueSecondary300}`,
      },
      "&.notExpanded .not-expanded-button": {
        animation: "showButtonPlanBox .2s linear forwards",
      },
      "@keyframes showButtonPlanBox": {
        "0%": {
          transform: "scaleX(0)",
          opacity: 0,
        },
        "50%": {
          transform: "scaleX(1)",
          opacity: 1,
        },
        "100%": {
          transform: "scaleX(1)",
          opacity: 1,
        },
      },
    },
  })
);

export const NavbarWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  backgroundColor: variableStyles.GreenPrimaryColor950,
  padding: "6px 12px",
  "& .header-navigate": {
    display: "flex",
    gap: "36px",
    alignItems: "center",
    "& .header-menu": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "32px",
      "& .menu-item": {
        paddingBottom: "3px",
        "& a": {
          color: variableStyles.NaturalColor300,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "12px",
          lineHeight: "15px",
          transition: "all 0.2s ease-out",
          "&:hover": {
            color: variableStyles.NaturalColor50,
          },
        },
        "&.active": {
          "& a": { color: variableStyles.colorWhite },
        },
      },
    },
  },
  "& .header-action": {
    display: "flex",
    alignItems: "center",
    "& .action-search": {
      marginRight: "12px",
      flex: "0 0 auto",
      width: "200px",
    },
    "& .action-group": {
      display: "flex",
      alignItems: "center",
      marginRight: "8px",
      gap: "8px",
      flex: "0 0 auto",
      "& button": {
        padding: "6px",
        opacity: ".5",
        transition: "all 0.2s ease-out",
        "&:hover": {
          opacity: "1",
        },
        "& .have-noti-circle": {
          position: "absolute",
          width: "12px",
          height: "12px",
          top: "5px",
          right: "6px",
          backgroundColor: variableStyles.Success500,
          border: "2px solid #09251F",
          borderRadius: "50%",
          zIndex: "1",
        },
      },
    },
    "& .action-profile": {
      flex: "0 0 auto",
      padding: "4px",
    },
  },
  "& .language-select": {
    backgroundColor: variableStyles.colorWhite,
    borderRadius: "12px",
    marginRight: "12px",
    "& .MuiSelect-select": {
      padding: "5px 32px 5px 12px",
    },
    "& .MuiMenuItem-root": {
      gap: "6px",
    },
  },
}));

export const PlanPopoverWrapper = styled(Popover)(() => ({
  "& .MuiPaper-root": {
    borderRadius: "12px",
    marginLeft: "14px",
  },
}));

export const ActionPopoverWrapper = styled(Popover)(() => ({
  "& .MuiPaper-root": {
    borderRadius: "12px",
    marginTop: "19px",
    padding: "12px 8px",
    border: `1px solid ${variableStyles.NaturalColor100}`,
    boxShadow:
      "0px 39px 67.8px 0px #1956580A, 0px 21px 37px -15px #1956580F,0px 1px 1px 0px #00000014",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    width: "238px",
    "& .MuiDivider-root": {
      borderColor: variableStyles.NaturalColor200,
    },
    "& .profile-infor": {
      display: "flex",
      gap: "12px",
      "& .detail-name": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "14px",
        lineHeight: "14px",
        marginBottom: "4px",
      },
      "& .detail-email": {
        color: variableStyles.NaturalColor900,
        fontWeight: variableStyles.fwRegular,
        fontSize: "12px",
        lineHeight: "12px",
      },
    },
    "& .profile-actions": {
      "& li": {
        "& a": {
          display: "flex",
          alignItems: "center",
          gap: "8px",
          color: variableStyles.NaturalColor900,
          fontWeight: variableStyles.fwRegular,
          fontSize: "14px",
          lineHeight: "14px",
          padding: "10px 6px",
          borderRadius: "6px",
          transition: "all .2s linear",
          "&:hover": {
            backgroundColor: variableStyles.NaturalColor100,
          },
        },
      },
    },
    "& .profile-plan": {
      display: "flex",
      justifyContent: "space-between",
      padding: "2px 6px",
      alignItems: "center",
      "& .infor-title": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "14px",
        lineHeight: "14px",
        marginBottom: "6px",
      },
      "& .infor-description": {
        color: variableStyles.NaturalColor600,
        fontWeight: variableStyles.fwMedium,
        fontSize: "12px",
        lineHeight: "12px",
      },
      "& button": {
        padding: "9px 18px",
      },
    },
    "& .profile-logout": {
      gap: "8px",
      color: variableStyles.Error500,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "14px",
      lineHeight: "14px",
      padding: "6px",
      width: "100%",
      justifyContent: "flex-start",
      borderRadius: "6px",
      "&:hover": {
        color: `${variableStyles.Error500} !important`,
        backgroundColor: variableStyles.Error50,
      },
    },
  },
}));

export const ProjectPopoverWrapper = styled(Popover)(() => ({
  "& .MuiPaper-root": {
    borderRadius: "12px",
    marginLeft: "28px",
    border: `1px solid ${variableStyles.NaturalColor100}`,
    padding: "12px 8px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    width: "238px",
    "& .project-list": {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      "& li": {
        "& a": {
          borderRadius: "12px",
          padding: "6px",
          display: "flex",
          alignItems: "center",
          transition: "all 0.2s linear",
          "&:hover": {
            backgroundColor: variableStyles.NaturalColor100,
          },
          "& .infor-box": {
            display: "flex",
            gap: "12px",
            alignItems: "center",
            width: "222px",
            "& .project-avatar": {
              flex: "0 0 auto",
            },
            "& .project-name": {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "4px",
              flex: "1 1 auto",
              "& .name-project": {
                color: variableStyles.NaturalColor950,
                fontWeight: variableStyles.fwSemiBold,
                fontSize: "14px",
                lineHeight: "14px",
              },
              "& .name-domain": {
                color: variableStyles.NaturalColor900,
                fontWeight: variableStyles.fwRegular,
                fontSize: "12px",
                lineHeight: "12px",
              },
            },
          },
        },
      },
    },
    "& .MuiDivider-root": {
      borderColor: variableStyles.NaturalColor200,
    },
    "& .create-button": {
      gap: "8px",
    },
  },
}));
