import React, { useEffect, useState } from "react";
import { ImageCustom, PaymentMethodBox } from "../../../../components";
import { PaymentMethodWrapper } from "../../styles";
import LogoVisa from "../../../../assets/images/temp/logo_visa.png";
import LogoMasterCard from "../../../../assets/images/temp/logo_mastercard.png";
import LogoMomo from "../../../../assets/images/temp/logo_momo.png";
import LogoVietQR from "../../../../assets/images/temp/logo_vietQR.png";
import IconCard from "../../../../assets/images/common/icon_card_fill_blue.svg";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PaymentService from "../../../../services/api/payment.service";
import { IOtherPaymentMethod } from "../../../../models/payment";

interface PaymentMethodProps {
  className: string;
  onChangeMethod: (value: number) => void;
  invoiceInfor:
    | {
        customerName: string;
        companyName: string;
        phoneNumbers: string;
        taxNumbers: string;
        address: string;
      }
    | undefined;
}

export default function PaymentMethod(props: PaymentMethodProps) {
  const { className, onChangeMethod, invoiceInfor } = props;

  const [selectedCard, setSelectedCard] = useState<number>(0);
  const [resDataPayment, setResDataPayment] = useState<IOtherPaymentMethod[]>();

  const validationSchema = Yup.object().shape({
    projectName: Yup.string().required(`${"Vui lòng nhập tên dự án"}`),
  }) as any;

  useEffect(() => {
    getPaymentMethods();
  }, []);
  useEffect(() => {
    onChangeMethod(selectedCard);
  }, [selectedCard]);

  const getPaymentMethods = async () => {
    const res = await PaymentService.getPaymentMethods();
    if (!res.isError && !!res.data) {
      setResDataPayment(res.data);
      setSelectedCard(res.data[0].id);
    }
  };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      projectName: "",
      domain: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const dataCard = [
    {
      cardNumber: "0126  9848  9899  9899",
      cardName: "VO QUOC AI LEN",
      cardCVV: "***",
      logo: (
        <ImageCustom
          src={LogoVisa}
          alt={"start-icon"}
          width={"60px"}
          height={"42px"}
        />
      ),
    },
    {
      cardNumber: "0126  9848  9899  9891",
      cardName: "VO QUOC AI LEN",
      cardCVV: "***",
      logo: (
        <ImageCustom
          src={LogoMasterCard}
          alt={"start-icon"}
          width={"60px"}
          height={"42px"}
        />
      ),
    },
    {
      textReplacement: "Thẻ Visa/Mastercard khác",
      logo: (
        <ImageCustom
          src={IconCard}
          alt={"start-icon"}
          width={"60px"}
          height={"42px"}
          className="image-add-card"
        />
      ),
    },
  ];

  const dataOtherCard = [
    {
      textReplacement: "Cổng thanh toán Momo",
      logo: (
        <ImageCustom
          src={LogoMomo}
          alt={"start-icon"}
          width={"68px"}
          height={"68px"}
        />
      ),
    },
    {
      textReplacement: "VietQR tất cả ngân hàng",
      logo: (
        <ImageCustom
          src={LogoVietQR}
          alt={"start-icon"}
          width={"68px"}
          height={"68px"}
        />
      ),
    },
  ];

  return (
    <PaymentMethodWrapper className={`${className}`}>
      <div className="method-list">
        {/* <h2 className="list-title">Thẻ của bạn</h2>
        <ul className="card-list">
          {dataCard.map(card => (
            <li key={card.cardNumber}>
              <PaymentMethodBox hasCheckbox dataCard={resDataPayment} />
            </li>
          ))}
        </ul> */}
        <h2 className="list-title">Loại thanh toán</h2>
        {!!resDataPayment && (
          <ul className="card-list">
            {resDataPayment.map(card => (
              <li key={card.id}>
                <PaymentMethodBox
                  hasCheckbox
                  dataCard={card}
                  isSelected={selectedCard === card.id}
                  onChange={value => {
                    setSelectedCard(value);
                    onChangeMethod(value);
                  }}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
      {/* <div className="method-infor-wrapper flex-center">
        <div className="method-infor">
          <p className="method-infor-title">Thông tin tài khoản</p>
          <form className="method-form">
            <ControlForm
              title="Số thẻ"
              infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            >
              <InputForm
                placeholder="Số thẻ"
                required
                name="fullName"
                errors={errors}
                control={control}
                size="small"
              />
            </ControlForm>
            <ControlForm
              title="Họ và tên"
              infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            >
              <InputForm
                placeholder="Họ và tên"
                required
                name="email"
                errors={errors}
                control={control}
                size="small"
              />
            </ControlForm>
            <ControlForm
              title="CVV"
              infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            >
              <InputPassword
                placeholder="CVV"
                required
                name="phoneNumbers"
                errors={errors}
                control={control}
                size="small"
              />
            </ControlForm>
          </form>
        </div>
      </div> */}
    </PaymentMethodWrapper>
  );
}
