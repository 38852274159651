import { all, put, takeEvery } from "redux-saga/effects";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { toggleMessage } from "../../components/Toast/Toast";
import { ILoginResponse } from "../../models/auth";
import { IBaseResponseModel } from "../../models/common/models.type";
import {
  EAuthType,
  IActionAdminLoginWithPassword,
  IActionLoginWithPassword,
  IActionLogout,
} from "../../models/redux/auth";
import AuthService from "../../services/api/auth.service";
import localStorageHanlde from "../../services/local/local-storage";
import { clearUser, setUserState } from "../user/user.duck";
import { Roles } from "../../models/common/models.enum";

function* loginWithPassword({ payload }: IActionLoginWithPassword) {
  const { loginRequest, navigate } = payload;
  try {
    const resLogin: IBaseResponseModel<ILoginResponse> =
      yield AuthService.loginWithPassword(loginRequest);
    if (resLogin.success && !resLogin.message) {
      const loginInfo = resLogin.data;

      yield localStorageHanlde.storeToken(loginInfo.token.token);
      yield localStorageHanlde.storeRole(loginInfo.user.role);
      yield put(setUserState(loginInfo.user));

      navigate(ROUTE_PATH.INDEX, { replace: true });
    } else if (!!resLogin.message && resLogin.message.length > 0) {
      toggleMessage({
        type: "error",
        message: resLogin.message,
        title: "Error Occurred",
      });
    } else if (!resLogin.success && !resLogin.message) {
      toggleMessage({
        type: "error",
        message: "Your email or password is incorrect!",
        title: "Error Occurred",
      });
    }
  } catch (err: any) {
    toggleMessage({
      type: "error",
      message: err?.message || "Your email or password is incorrect!",
      title: "Error Occurred",
    });
  }
}

function* adminLoginWithPassword({ payload }: IActionAdminLoginWithPassword) {
  const { loginRequest, navigate } = payload;
  try {
    const resLogin: IBaseResponseModel<ILoginResponse> =
      yield AuthService.adminLoginWithPassword(loginRequest);
    if (resLogin.success) {
      const loginInfo = resLogin.data;
      yield localStorageHanlde.storeToken(loginInfo.token.token);
      yield localStorageHanlde.storeRole(
        loginInfo.user.role === Roles.Admin
          ? Roles.SuperAdmin
          : loginInfo.user.role
      );
      yield put(setUserState(loginInfo.user));
      navigate(ROUTE_PATH.INDEX, { replace: true });
    } else {
      toggleMessage({
        type: "error",
        message: "Your email or password is incorrect!",
        title: "Error Occurred",
      });
    }
  } catch (err) {}
}

function* logout({ payload }: IActionLogout) {
  const { navigate } = payload;
  try {
    yield localStorageHanlde.clear();

    yield put(clearUser());

    navigate && navigate(ROUTE_PATH.LOGIN, { replace: true });
  } catch (err) {}
}

export default function* rootSaga() {
  yield all([
    takeEvery(EAuthType.LOGIN_WITH_PASSWORD, loginWithPassword),
    takeEvery(EAuthType.ADMIN_LOGIN_WITH_PASSWORD, adminLoginWithPassword),
    takeEvery(EAuthType.LOGOUT, logout),
  ]);
}
