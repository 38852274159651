import { forwardRef, useEffect, useState } from "react";
import OverviewCommonTitle from "../../OverviewCommonTitle";
import BackLinkContents from "./components/BackLinkContents";
import ContentContents from "./components/ContentContents";
import TechnicalContents from "./components/TechnicalContents";
import { SEOSectionWrapper } from "./styles";

const SEOSection = forwardRef<any, any>(function (props, ref) {
  const [data, setData] = useState<any>();
  useEffect(() => {
    if (!!props.props) {
      setData(props.props);
    }
  }, [props.props]);

  return (
    <SEOSectionWrapper className="common-layout" id={"seo-section"} ref={ref}>
      <OverviewCommonTitle
        title="SEO Website"
        description="SEO là quá trình giúp website dễ dàng được tìm thấy bởi người dùng khi họ tìm kiếm thông tin trên công cụ tìm kiếm. Việc đánh giá SEO website sẽ chỉ ra những điểm mạnh, điểm yếu và cơ hội cải thiện để website có thể đạt được thứ hạng cao hơn trên kết quả tìm kiếm, từ đó thu hút nhiều lượt truy cập hơn so với đối thủ."
        className="SEO-title"
        scores={78}
      />
      <TechnicalContents data={data} />
      <ContentContents data={data} />
      <BackLinkContents data={data} />
    </SEOSectionWrapper>
  );
});
export default SEOSection;
