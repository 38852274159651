import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const PackageBoxWrapper = styled("button")(() => ({
  boxShadow: "0px -1px 14.7px 1px #0000001A",
  backgroundColor: variableStyles.colorWhite,
  borderRadius: "16px",
  position: "relative",
  overflow: "hidden",
  transition: "all .2s linear",
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  alignItems: "unset",
  height: "100%",
  width: "100%",
  "& .item-top": {
    minHeight: "170px",
    flex: "0 0 auto",
    width: "100%",
    position: "relative",
    "& .top-icon-wrapper": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      position: "absolute",
      top: "20px",
      right: "20px",
      "& .top-icon": {
        position: "relative",
        "&::before, &::after": {
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: variableStyles.NaturalColor0,
          borderRadius: "100%",
        },
        "&::before": {
          width: "180px",
          height: "180px",
          opacity: ".3",
        },
        "&::after": {
          width: "180px",
          height: "180px",
          opacity: ".2",
          animation: "fadeAnimation .4s forwards linear",
        },
        "& img": {
          position: "relative",
          zIndex: "1",
          transform: "rotate(0deg)",
          transition: "all .4s linear",
        },
      },
    },
    "& .top-title": {
      color: variableStyles.Info900,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "24px",
      lineHeight: "29.05px",
      marginBottom: "4px",
      textTransform: "uppercase",
      padding: "20px",
      position: "absolute",
      bottom: "0",
      left: "0",
      zIndex: "1",
    },
  },
  "& .item-bottom": {
    padding: "24px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    flex: "1 1 auto",
    "& .bottom-description": {
      color: variableStyles.Info950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "20.05px",
      marginBottom: "16px",
    },
    "& .bottom-features": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      gap: "12px",
      "& span": {
        color: variableStyles.NaturalColor500,
        fontWeight: variableStyles.fwMedium,
        textAlign: "left",
        fontSize: "16px",
        lineHeight: "19.36px",
      },
    },
    "& .bottom-price": {
      display: "flex",
      gap: "12px",
      alignItems: "center",
      marginTop: "32px",
      "& .new-price": {
        color: variableStyles.NaturalColor1000,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "24px",
        lineHeight: "29.05px",
      },
      "& .old-price": {
        color: variableStyles.NaturalColor1000,
        fontWeight: variableStyles.fwMedium,
        fontSize: "16px",
        lineHeight: "19.36px",
        opacity: ".5",
        textDecoration: "line-through",
      },
    },
  },
  "& .button-submit": {
    marginTop: "32px",
  },
  "&:hover": {
    "& .top-icon-wrapper": {
      "& .top-icon": {
        "&::after": {
          animation: "growAnimation .4s forwards linear",
        },
        "& img": {
          transform: "rotate(15deg)",
        },
      },
    },
  },
}));
