import React, { useEffect, useState } from "react";
import { ImageCustom, LoadingIndicator, PackageBox } from "../../components";
import { PricingWrapper } from "./styles";
import { useDispatch } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import variableStyles from "../../theme/variable-styles";
import IconWebAudit from "../../assets/images/common/icon_domainaudit_fill_blue.svg";
import IconWebCompetitive from "../../assets/images/common/icon_domaincompetitive_fill_pink.svg";
import IconPageAudit from "../../assets/images/common/icon_pageaudit_fill_blue.svg";
import IconPageCompetitive from "../../assets/images/common/icon_pagecompetitive_fill_green.svg";
import IconSEO from "../../assets/images/common/icon_seo_fill_yellow.svg";
import IconUX from "../../assets/images/common/icon_ux_fill_purple.svg";
import PricingTitle from "./components/PricingTitle";
import PlanService from "../../services/api/plan.service";
import { IPlan } from "../../models/plan";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import ImageNoData from "../../assets/images/common/img_project_empty.png";

const listColors = [
  variableStyles.blueSecondary200,
  "#F4F0E4",
  "#D8DFFF",
  "#FFE2ED",
  variableStyles.Info100,
  "#AFF5DB",
];

const listIcons = [
  IconWebAudit,
  IconSEO,
  IconUX,
  IconWebCompetitive,
  IconPageAudit,
  IconPageCompetitive,
];

export default function Pricing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [resData, setResData] = useState<IPlan[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getDataofPage();
  }, []);

  const getDataofPage = async () => {
    setIsLoading(true);
    const res = await PlanService.getPlanList();
    if (!res.isError && res.data.length > 0) {
      const data = res.data.map((data, idx) => ({
        ...data,
        backgroundColor: listColors[idx],
        icon: listIcons[idx],
      }));
      setResData(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <PricingWrapper>
      <h1 className="pricing-title">
        Choose Plan <br />
        That’s Right For You
      </h1>
      <p className="pricing-description">
        Choose plan that works best for you, feel free to contact us
      </p>
      <PricingTitle
        text="Web audit Report"
        description={`${resData.length || 0} services`}
      />
      <ul
        className={`pricing-plan-list ${!resData || (!!resData && resData.length <= 0) ? "nodata" : ""}`}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {!resData || (!!resData && resData.length <= 0) ? (
              <div className={"box-empty flex-column-center"}>
                <ImageCustom
                  src={ImageNoData}
                  alt="Icon-NoData"
                  width={"120px"}
                  height={"120px"}
                />
                <p className={"text-empty"}>Không tìm thấy dữ liệu</p>
              </div>
            ) : (
              <>
                {resData.map(price => (
                  <li className="plan-item" key={price.id}>
                    <PackageBox
                      data={price}
                      onNavigate={() => {
                        const path = generatePath(ROUTE_PATH.PRICING_DETAIL, {
                          id: price.id,
                        });
                        navigate({
                          pathname: `${path}`,
                        });
                      }}
                    />
                  </li>
                ))}
              </>
            )}
          </>
        )}
      </ul>
    </PricingWrapper>
  );
}
