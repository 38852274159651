import React from "react";
import { ProfilePaymentBoxWrapper } from "../../styles";
import { ImageCustom, PaymentMethodBox } from "../../../../components";
import LogoVisa from "../../../../assets/images/temp/logo_visa.png";
import LogoMasterCard from "../../../../assets/images/temp/logo_mastercard.png";
import IconAdd from "../../../../assets/images/common/icon_plus_line_black.svg";

interface ProfilePaymentBoxProps {
  className?: string;
}

export default function ProfilePaymentBox(props: ProfilePaymentBoxProps) {
  const { className } = props;

  const dataCard = [
    {
      cardNumber: "0126  9848  9899  9899",
      cardName: "VO QUOC AI LEN",
      cardCVV: "312",
      logo: (
        <ImageCustom
          src={LogoVisa}
          alt={"start-icon"}
          width={"60px"}
          height={"42px"}
        />
      ),
      isDefault: true,
    },
    {
      cardNumber: "0126  9848  9899  9891",
      cardName: "VO QUOC AI LEN",
      cardCVV: "123",
      logo: (
        <ImageCustom
          src={LogoMasterCard}
          alt={"start-icon"}
          width={"60px"}
          height={"42px"}
        />
      ),
      isDefault: false,
    },
  ];

  return (
    <ProfilePaymentBoxWrapper className={className}>
      <div className="payment-top-wrapper">
        <h2 className="payment-title">Tổng quan</h2>
        <div className="payment-overview">
          <p className="overview-text">Credit của bạn</p>
          <div className="overview-credit-wrapper flex-start-align-bottom">
            <p className="credit-number">60</p>
            <p className="credit-text">Credit</p>
          </div>
        </div>
      </div>
      <div className="payment-bottom-wrapper">
        <h2 className="payment-title">Phương thức thanh toán</h2>
        <ul className="payment-methods">
          {dataCard.map(payment => (
            <li key={payment.cardCVV}>
              <PaymentMethodBox
                isDefault={payment.isDefault}
                dataCard={undefined}
              />
            </li>
          ))}
        </ul>
        <button className="payment-add-card">
          <ImageCustom
            isAvatar
            src={IconAdd}
            alt={"add-icon"}
            width={"18px"}
            height={"18px"}
          />
          <p>Thêm thẻ mới</p>
        </button>
      </div>
    </ProfilePaymentBoxWrapper>
  );
}
