// ----------------------------------------------------------------------

import variableStyles from "../variable-styles";

export default function Input(theme: any) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "& svg": { color: theme.palette.text.disabled },
          },
          "&.MuiInputBase-sizeLarge": {
            input: {
              padding: "16.5px 12px",
            },
            "& .MuiSelect-select": {
              padding: "16.5px 12px",
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:before": {
            borderBottomColor: theme.palette.grey[500],
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[100],
          "&:hover": {
            backgroundColor: theme.palette.background[100],
          },
          "&.Mui-focused": {
            backgroundColor: theme.palette.action.focus,
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          "&:before": {
            borderBottomColor: theme.palette.grey[500],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: variableStyles.NaturalColor300,
          },
          "&.Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline":
              {
                borderColor: variableStyles.NaturalColor100,
              },
          },
          "& fieldset": {
            borderRadius: "12px",
            padding: "0",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        // styleOverrides cho root của sizeMedium vì TextField không có class size-specific
        root: {
          "& .MuiInputLabel-sizeMedium.MuiInputLabel-outlined:not(.Mui-focused)":
            {
              // Điều chỉnh vị trí nhãn khi thu nhỏ
              transform: "translate(14px, 12px) scale(1)",
            },
          "& .MuiInputBase-input": {
            padding: "16px 12px",
            height: "unset",
            color: variableStyles.NaturalColor800,
            fontWeight: variableStyles.fwMedium,
            fontSize: "15px",
            lineHeight: "18.15px",
            backgroundColor: variableStyles.colorWhite,
            "&:-webkit-autofill, &:-webkit-autofill:hover,&:-webkit-autofill:focus,&:-webkit-autofill:active":
              {
                WebkitBackgroundClip: "text",
                transition: "background-color 5000s ease-in-out 0s",
                boxShadow: "inset 0 0 20px 20px transparent",
              },
          },
          "& .MuiInputBase-sizeSmall": {
            "& .MuiInputBase-input": {
              padding: "11px 8px",
              color: variableStyles.NaturalColor950,
              fontWeight: variableStyles.fwMedium,
              fontSize: "13px",
              lineHeight: "18.2px",
              borderRadius: "12px",
            },

            "& input::placeholder": {
              color: variableStyles.NaturalColor800,
              fontWeight: variableStyles.fwMedium,
              fontSize: "13px",
              lineHeight: "18.2px",
              opacity: "1",
            },
          },
          "& input::placeholder": {
            color: variableStyles.NaturalColor800,
            fontWeight: variableStyles.fwMedium,
            fontSize: "13px",
            lineHeight: "18.2px",
            opacity: "1",
          },
          "&:hover": {
            "& fieldset": {
              border: `1px solid ${variableStyles.NaturalColor600} !important`,
            },
          },
          "& .Mui-focused": {
            "& fieldset": {
              boxShadow: "0px 0px 0px 4px #23B4FF33",
              border: "1px solid #0C97FF !important",
            },
          },
          "& .Mui-disabled": {
            "& fieldset": {
              border: `1px solid ${variableStyles.NaturalColor100} !important`,
            },
          },
          "& .Mui-error": {
            "& fieldset": {
              boxShadow: `0px 0px 0px 4px #90580433`,
              border: `1px solid ${variableStyles.Warning800} !important`,
            },
          },
          "& .MuiFormHelperText-root": {
            "&.Mui-error": {
              color: variableStyles.Warning800,
              fontSize: "13px",
              lineHeight: "18.2px",
              display: "flex",
              alignItems: "center",
              gap: "4px",
              margin: "8px 0 0",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.MuiInputLabel-root.MuiInputLabel-filled.Mui-focused": {
            // Điều chỉnh vị trí nhãn khi thu nhỏ
            transform: "translate(12px, -9px) scale(0.75)",
          },
        },
        sizeLarge: {
          top: "-8px",
        },
      },
    },
  };
}
