import variableStyles from "../../theme/variable-styles";
import { styled } from "@mui/material/styles";

export const PaymentMethodBoxWrapper = styled("button")(() => ({
  gap: "12px",
  width: "100%",
  backgroundColor: "transparent",
  "&.hasCheckbox": {
    borderRadius: "12px",
    "& .box-card": { maxWidth: "100%" },
  },
  "&.active": {
    border: `2px solid ${variableStyles.blueSecondary700}`,
    "& .box-card": {
      padding: "4px",
      backgroundColor: variableStyles.Info50,
    },
  },
  "& .box-card": {
    borderRadius: "12px",
    backgroundColor: variableStyles.NaturalColor100,
    padding: "6px",
    gap: "12px",
    maxWidth: "467px",
    flex: "1 1 auto",
    position: "relative",
    "& .card-image": {
      "& img": {
        boxShadow: "0px 0px 20px 0px #00000012",
        borderRadius: "8px",
      },
    },
    "& .card-infor": {
      "& .infor-number": {
        color: variableStyles.Info800,
        fontWeight: variableStyles.fwMedium,
        fontSize: "16px",
        lineHeight: "22.4px",
        marginBottom: "4px",
      },
      "& .infor-name, & .infor-cvv": {
        color: variableStyles.NaturalColor900,
        fontWeight: variableStyles.fwMedium,
        fontSize: "14px",
        lineHeight: "19.6px",
      },
    },
    "& .infor-checkbox": {
      position: "absolute",
      top: "50%",
      right: "12px",
      transform: "translateY(-50%)",
    },
  },
  "& .box-action": {
    gap: "12px",
    flex: "0 0 auto",
    "& .action-default-payment": {
      color: variableStyles.GreenPrimaryColor600,
      fontWeight: variableStyles.fwRegular,
      fontSize: "14px",
      lineHeight: "20px",
      backgroundColor: variableStyles.GreenPrimaryColor50,
      borderRadius: "100px",
      padding: "4px 8px",
    },
    "& button": {
      backgroundColor: "transparent",
      color: variableStyles.blueSecondary700,
      fontWeight: variableStyles.fwRegular,
      fontSize: "14px",
      lineHeight: "16.94px",
      fontStyle: variableStyles.fsItalic,
      textDecoration: "underline",
      transition: "all .2s linear",
      "&:hover": { textDecoration: "none" },
    },
  },
}));
