import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";

export const AreaChartCustomWrapper = styled("div")<{
  wrapperhight?: string;
  chartheight?: string;
}>(({ theme, wrapperhight, chartheight }) => ({
  width: "100%",
  height: !!wrapperhight ? wrapperhight : "200px",
  "& .legend-list": {
    display: "flex",
    alignItems: "center",
    gapColumn: "8px",
    flexWrap: "wrap",
    "& li": {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      minWidth: "100px",
      "& div": {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
      },
      "& p": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "12px",
        lineHeight: "20px",
      },
    },
  },
  "& .custom-tooltip": {
    backgroundColor: variableStyles.NaturalColor0,
    border: `1px solid ${variableStyles.NaturalColor200}`,
    borderRadius: "5px",
    padding: "6px",
    textAlign: "center",
    width: "128px",
    boxShadow: "0px 4px 4.9px 0px #00000012",
    "& .YAxis-text": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "12px",
      lineHeight: "20px",
    },
    "& .XAxis-text": {
      color: variableStyles.NaturalColor800,
      fontWeight: variableStyles.fwRegular,
      fontSize: "12px",
      lineHeight: "15px",
    },
  },
  "& .legend-filter-wrapper": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "0 20px 0 10px",
    marginBottom: "10px",
  },
  "& .chart-wrapper": {
    height: !!chartheight ? chartheight : "200px",
  },
}));
