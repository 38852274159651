import React, { useEffect, useState } from "react";
import { ImageCustom, PackageBox } from "../../../../components";
import { PricingDetailWrapper } from "./styles";
import IconCheck from "../../../../assets/images/common/icon_check_line_white.svg";
import IconFireWork from "../../../../assets/images/common/icon_firework_line_grey.svg";
import { EPlanPackage } from "../../../../models/common/models.enum";
import IconPageCompetitive from "../../../../assets/images/common/icon_pagecompetitive_fill_green.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IconWebAudit from "../../../../assets/images/common/icon_domainaudit_fill_blue.svg";
import IconWebCompetitive from "../../../../assets/images/common/icon_domaincompetitive_fill_pink.svg";
import IconPageAudit from "../../../../assets/images/common/icon_pageaudit_fill_blue.svg";
import IconSEO from "../../../../assets/images/common/icon_seo_fill_yellow.svg";
import IconUX from "../../../../assets/images/common/icon_ux_fill_purple.svg";
import variableStyles from "../../../../theme/variable-styles";
import IconArrow from "../../../../assets/images/common/icon_arrow_left_line_black.svg";
import PricingTitle from "../PricingTitle";
import PlanService from "../../../../services/api/plan.service";
import { IPlan } from "../../../../models/plan";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ROUTE_PATH } from "../../../../common/constants/app.constant";
import { useDispatch, useSelector } from "react-redux";
import { setPlanInforState } from "../../../../redux/planInfor/planInfor.duck";
import { IStateApp } from "../../../../models/redux";

const listColors = [
  variableStyles.blueSecondary200,
  "#F4F0E4",
  "#D8DFFF",
  "#FFE2ED",
  variableStyles.Info100,
  "#AFF5DB",
];

const listIcons = [
  IconWebAudit,
  IconSEO,
  IconUX,
  IconWebCompetitive,
  IconPageAudit,
  IconPageCompetitive,
];

export default function PricingDetail() {
  const sliderPackage = React.useRef<any>(null);
  const divRef = React.useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id: planId } = useParams();
  const [resListData, setResListData] = useState<IPlan[]>([]);
  const [resData, setResData] = useState<IPlan>();
  const currentPlan = useSelector<IStateApp, IPlan | null>(
    appState => appState.planInfor.planInfor
  );

  useEffect(() => {
    getDataofPage();
  }, []);

  useEffect(() => {
    scrollToRef(divRef);
  }, [location.pathname]);

  useEffect(() => {
    if (!!planId) {
      getPLanDetail();
    }
  }, [planId]);

  const getDataofPage = async () => {
    const res = await PlanService.getPlanList();
    if (!res.isError && res.data.length > 0) {
      const data = res.data.map((data, idx) => ({
        ...data,
        backgroundColor: listColors[idx],
        icon: listIcons[idx],
      }));
      setResListData(data);
    }
  };
  const getPLanDetail = async () => {
    if (!!planId) {
      const res = await PlanService.getPlan(planId);
      if (!res.isError && !!res.data) {
        setResData(res.data);
      }
    }
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    // autoplay: true,
    slidesToScroll: 3,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const scrollToRef = (ref: any) => {
    ref.current.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <PricingDetailWrapper ref={divRef} className="scrollbar">
      <div className="pack-infor flex-between-align-top">
        <div className="infor-detail ">
          <div className="detail-tag flex-between-align-center">
            <ImageCustom
              src={IconFireWork}
              alt="Icon-FireWork"
              width={"18px"}
              height={"18px"}
            />
            <p>About the sevice</p>
          </div>
          <h2 className="detail-title">{resData?.title}</h2>
          <p className="detail-description">{resData?.description}</p>{" "}
          {!!resData && !!resData.content && (
            <ul
              className="list-benefit"
              dangerouslySetInnerHTML={{ __html: resData.content }}
            ></ul>
          )}
          <button className="detail-demo-button">Get demo report</button>
        </div>
        {!!resData && (
          <PackageBox
            data={resData}
            className="infor-package"
            isBanner
            onClick={() => {
              dispatch(setPlanInforState(resData));
              navigate(ROUTE_PATH.PAYMENT);
            }}
          />
        )}
      </div>
      <PricingTitle
        text="Liên quan"
        description={`${resListData.length || 0} services`}
      />
      <div className="package-relate">
        <div className="slider">
          <button
            onClick={() => sliderPackage?.current?.slickPrev()}
            className="slider-button button-prev"
          >
            <ImageCustom
              src={IconArrow}
              alt="Icon-Arrow"
              width={"40px"}
              height={"40px"}
            />
          </button>
          <Slider {...settings} className="relate-slider" ref={sliderPackage}>
            {resListData.map(price => (
              <PackageBox
                data={price}
                key={price.title}
                className="slider-package"
                onNavigate={() => {
                  const path = generatePath(ROUTE_PATH.PRICING_DETAIL, {
                    id: price.id,
                  });
                  navigate({
                    pathname: `${path}`,
                  });
                }}
              />
            ))}
          </Slider>
          <button
            onClick={() => sliderPackage?.current?.slickNext()}
            className="slider-button button-next"
          >
            <ImageCustom
              src={IconArrow}
              alt="Icon-Arrow"
              width={"40px"}
              height={"40px"}
            />
          </button>
        </div>
      </div>
    </PricingDetailWrapper>
  );
}
