import { Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { BehaviorSubject } from "rxjs";
import { ROUTE_PATH } from "../../../../common/constants/app.constant";
import { DEFAULT_BASE_PAGING_RES } from "../../../../common/constants/value.constant";
import {
  FastFilterList,
  TableNavDetailNoPagination,
} from "../../../../components";
import { ITableHeader } from "../../../../components/Table/TableDataGrey";
import { IBasePagingRes, IValue } from "../../../../models/common/models.type";
import { IStateApp } from "../../../../models/redux";
import { IGetReportsRes } from "../../../../models/report";
import AuditReportService from "../../../../services/api/audit.report.service";
import { ErrorTableModalWrapper } from "./styles";

interface ErrorTableModalProps {
  open?: boolean;
  onCancel?: () => void;
  filter?: {
    key: string;
    value: string;
    operator: string;
    type: string;
  }[];
  headersTable: ITableHeader<any>[];
  activeTab?: string;
  isHasFilter?: boolean;
  url?: string;
}

const errorTableModalSubject = new BehaviorSubject<ErrorTableModalProps>({
  open: false,
  filter: undefined,
  headersTable: [],
  activeTab: undefined,
  isHasFilter: false,
  url: undefined,
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const toggleErrorTableModal = (value: ErrorTableModalProps) => {
  errorTableModalSubject.next(value);
};

export default function ErrorTableModal() {
  const navigate = useNavigate();

  const [data, setData] = useState<ErrorTableModalProps>({
    open: false,
    filter: undefined,
    headersTable: [],
    isHasFilter: false,
    url: undefined,
  });
  const [tabActive, setTabActive] = useState<string>("0");
  const [resData, setResData] = useState<IBasePagingRes<any>>({
    ...DEFAULT_BASE_PAGING_RES,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );

  useEffect(() => {
    const subscribe = errorTableModalSubject.subscribe(value => {
      let params = { ...value };
      if (value.open === undefined) {
        params.open = true;
      }
      setData(params);
      if (!!value.activeTab) {
        setTabActive(value.activeTab);
      }
    });
    return () => {
      setData({
        open: false,
        filter: undefined,
        headersTable: [],
        isHasFilter: false,
        url: undefined,
      });
      setResData({
        ...DEFAULT_BASE_PAGING_RES,
      });
      subscribe.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!!dataReport.thirdPartyId && !!data.filter) {
      getData();
    }
  }, [dataReport?.thirdPartyId, data?.filter]);

  const getData = async () => {
    if (!!dataReport.thirdPartyId && !!data.filter) {
      setIsLoading(true);
      const params = {
        id: "10240940-8234-0216-0000-9e8a3cff2149",
        filters: data.filter,
        pageFrom: data.url || "",
      };
      const res = await AuditReportService.getErrorsFromLink(params);
      if (!res.isError && !!res.data.items) {
        setResData(prev => ({
          ...prev,
          items: res.data.items,
          total: res.data.items?.length,
        }));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  const onCancel = () => {
    errorTableModalSubject.next({
      open: false,
      filter: undefined,
      headersTable: [],
      activeTab: undefined,
      isHasFilter: false,
      url: undefined,
    });
    data.onCancel && data.onCancel();
    setData({ ...data, open: false });
    setData({
      open: false,
      filter: undefined,
      headersTable: [],
      isHasFilter: false,
      url: undefined,
    });
    setResData({
      ...DEFAULT_BASE_PAGING_RES,
    });
  };

  const tabData: IValue[] = [
    { title: "Inlink", value: "0" },
    { title: "Outlink", value: "1" },
    { title: "Image", value: "2" },
  ];

  return (
    <ErrorTableModalWrapper
      open={!!data.open}
      onClose={() => onCancel()}
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="modal-header flex-between-align-center">
        <FastFilterList
          selectedValue={tabActive}
          onChange={(v: string | null) => {}}
          data={tabData}
          disabled
        />
      </div>
      <TableNavDetailNoPagination
        data={resData}
        headersTable={data.headersTable}
        handlePageChange={(page: number) => {}}
        loading={isLoading}
        // handleViewMore={
        //   resData.total > 10
        //     ? () => {
        //         const path = generatePath(
        //           ROUTE_PATH.DOMAIN_AUDIT_ERROR_DETAIL,
        //           {
        //             id: "asdasasdasasd",
        //           }
        //         );
        //         const newParams = queryString.stringify({
        //           tab: "1",
        //         });
        //         navigate({
        //           pathname: `${path}`,
        //           search: `${newParams}`,
        //         });
        //       }
        //     : undefined
        // }
      />
    </ErrorTableModalWrapper>
  );
}
