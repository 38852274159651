import { Button } from "@mui/material";
import React from "react";
import { ImageCustom } from "..";
import CommonHandle from "../../common/handles/common.handles";
import { ECurrencyCode } from "../../models/common/models.enum";
import { IPlan } from "../../models/plan";
import { PackageBoxWrapper } from "./styles";

interface PackageBoxProps {
  data: IPlan;
  className?: string;
  isBanner?: boolean;
  onClick?: () => void;
  onNavigate?: () => void;
}

export default function PackageBox(props: PackageBoxProps) {
  const { data, className, isBanner = false, onClick, onNavigate } = props;
  return (
    <PackageBoxWrapper
      className={`${className} ${isBanner ? "is-banner" : ""}`}
      onClick={onNavigate}
    >
      <div
        className="item-top"
        style={{ backgroundColor: data.backgroundColor }}
      >
        <div className="top-icon-wrapper">
          <figure className="top-icon">
            <ImageCustom
              src={data.icon}
              alt="top-icon"
              width={"60px"}
              height={"60px"}
            />
          </figure>
        </div>
        <p className="top-title">{data.title}</p>
      </div>
      <div className="item-bottom">
        <div className="bottom-infor">
          <p className="bottom-description">{data.description}</p>
          {!!data.content && !isBanner && (
            // <ul className="bottom-features">
            //   {data.benefitList.map(benefit => (
            //     <li key={benefit}>{benefit}</li>
            //   ))}
            // </ul>
            <ul
              dangerouslySetInnerHTML={{ __html: data.content }}
              className="bottom-features"
            ></ul>
          )}
        </div>
        <div className="bottom-price">
          <p className="new-price">
            {CommonHandle.formatCurrency(data.price, ECurrencyCode.VND)}
          </p>
          {!!data.oldPrice && (
            <p className="old-price">
              {CommonHandle.formatCurrency(data.oldPrice, ECurrencyCode.VND)}
            </p>
          )}
        </div>
        {isBanner && (
          <Button
            size="xsmall"
            variant="contained"
            className="button-submit"
            onClick={onClick}
          >
            Subcribe now
          </Button>
        )}
      </div>
    </PackageBoxWrapper>
  );
}
