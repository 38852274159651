import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import { IBaseResponseModel } from "../../models/common/models.type";
import { IPlan } from "../../models/plan";

const PLAN_API = ApiEndpoints.PLAN_API;
const baseURL = envConfig.API_KEY_PROJECT_ENDPOINT;

const getPlanList = (): Promise<IBaseResponseModel<IPlan[]>> => {
  return api.get(baseURL, PLAN_API.GET_PLAN_LIST.url, {});
};

const getPlan = (planId: string): Promise<IBaseResponseModel<IPlan>> => {
  return api.get(baseURL, `${PLAN_API.GET_PLAN_LIST.url}/${planId}`);
};

const PlanService = { getPlanList, getPlan };

export default PlanService;
