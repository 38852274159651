import React from "react";
import queryString from "query-string";
import { ROUTE_PATH } from "../../../../../../../common/constants/app.constant";
import { CollapseHandleError } from "../../../../../../../components";
import { EAuditErrorStatus } from "../../../../../../../models/common/models.enum";
import OverviewCommonSubTitle from "../../../../OverviewCommonSubTitle";
import { IIssueCommon } from "../TechnicalTab/TechnicalIssueTab/tempData";
import ContentTableBox from "./components/ContentTableBox";
import { ContentContentsWrapper } from "./styles";

interface ContentContentsProps {
  data: any;
}

interface IContentIssue extends IIssueCommon {
  issueCount?: number;
}

export default function ContentContents(props: ContentContentsProps) {
  const { data } = props;

  const dataContent: IContentIssue[] = [
    {
      title: "Trang trùng lặp nội dung",
      key: "duplicate_content",
      type: "page",
      infor:
        "Trang trùng lặp nội dung là khi một website chứa nhiều trang có nội dung giống hệt nhau hoặc quá tương tự nhau, gây ra những ảnh hưởng tiêu cực đối với thứ hạng của website.",
      recommendAction:
        "Sử dụng các công cụ như thẻ canonical, chuyển hướng 301, thẻ noindex, và tối ưu hóa nội dung của mình để tránh trùng lặp. ",
      filter: [
        {
          key: "duplicate_content",
          value: "true",
          operator: "=",
          type: "field",
        },
      ],
      headerData: [
        {
          field: "url",
          title: `URL`,
          className: "table-cell-link",
          handleItem: (item: any) => {
            return (
              <a
                className="fit-one-line"
                target="_blank"
                rel="noopener noreferrer"
                href={item.url}
              >
                {item.url}
              </a>
            );
          },
        },
      ],
      issueCount:
        !!data && !!data.overviewErrors
          ? data.overviewErrors.duplicate_content
          : 0,
      navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
      navigateFilter: queryString.stringify({
        tab: 2,
        mFilter: "content",
        sFilter: "duplicatecontent",
      }),
    },
    // {
    //   title: "Trang có lỗi chính tả",
    //   key: "meta.spell",
    //   infor:
    //     "Trang có lỗi chính tả là khi trang web chứa các lỗi về từ, ngữ pháp, dấu câu,.... Lỗi chính tả khiến website trông cẩu thả, thiếu chỉnh chu, làm giảm niềm tin của khách hàng vào sự chuyên nghiệp và uy tín của doanh nghiệp.",
    //   recommendAction:
    //     "Sử dụng các công cụ kiểm tra chính tả trực tuyến hoặc phần mềm soạn thảo văn bản có tích hợp chức năng kiểm tra chính tả để ra soát lỗi trên trang web trước khi publish.",
    //   type: "page",
    //   filter: [
    //     {
    //       key: "meta.spell",
    //       value: "null",
    //       operator: "<>",
    //       type: "field",
    //     },
    //   ],
    //   headerData: [
    //     {
    //       field: "url",
    //       title: `URL`,
    //       className: "table-cell-link",
    //       handleItem: (item: any) => {
    //         return (
    //           <a
    //             className="fit-one-line"
    //             target="_blank"
    //             rel="noopener noreferrer"
    //             href={item.url}
    //           >
    //             {item.url}
    //           </a>
    //         );
    //       },
    //     },
    //   ],
    //   issueCount: !!data ? data.spell_check : 0,
    //   navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
    //   navigateFilter: queryString.stringify({
    //     tab: 2,
    //     mFilter: "content",
    //     sFilter: "spellcontent",
    //   }),
    // },
    {
      title: "Trang có thẻ tiêu đề không liên quan đến nội dung",
      key: "irrelevant_title",
      type: "page",
      infor:
        "Một thẻ tiêu đề không liên quan (không nêu đúng nội dung chính của trang web) sẽ gây nhầm lẫn, làm giảm sự tin cậy với người dùng khi họ không tìm được nội dung mong muốn đồng thời làm giảm thứ hạng trên trang kết quả tìm kiếm.",
      recommendAction:
        "Đảm bảo rằng thẻ tiêu đề chứa các từ khóa chính mà người dùng có thể tìm kiếm liên quan đến nội dung chính của trang.",
      filter: [
        {
          key: "checks.irrelevant_title",
          value: "true",
          operator: "=",
          type: "field",
        },
      ],
      headerData: [
        {
          field: "url",
          title: `URL`,
          className: "table-cell-link",
          handleItem: (item: any) => {
            return (
              <a
                className="fit-one-line"
                target="_blank"
                rel="noopener noreferrer"
                href={item.url}
              >
                {item.url}
              </a>
            );
          },
        },
        {
          field: "title",
          title: `Tiêu đề`,
          className: " align-end",
          handleItem: (item: any) => {
            return <p className="fit-one-line">{item.meta.title}</p>;
          },
        },
      ],
      issueCount:
        !!data && !!data.overviewErrors
          ? data.overviewErrors.heading_not_irrelevant_title
          : 0,
      navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
      navigateFilter: queryString.stringify({
        tab: 2,
        mFilter: "content",
        sFilter: "notrelativetitle",
      }),
    },
    {
      title: "Trang có thẻ meta description không liên quan đến nội dung",
      key: "irrelevant_description",
      type: "page",
      infor:
        "Thẻ meta description không liên quan (không mô tả hay tóm tắt đúng nội dung tổng quan của trang web) sẽ gây nhầm lẫn hoặc khó hiểu cho người dùng, điều này có thể làm tăng tỉ lệ thoát hoặc nghiêm trọng hơn là giảm niềm tin nơi người dùng. ",
      recommendAction:
        "Viết lại thẻ meta description để phản ánh đúng nội dung trang web. Thẻ này nên sử dụng các từ khóa liên quan và dài khoảng 150-160 ký tự.",
      filter: [
        {
          key: "checks.irrelevant_description",
          value: "true",
          operator: "=",
          type: "field",
        },
      ],
      headerData: [
        {
          field: "url",
          title: `URL`,
          className: "table-cell-link",
          handleItem: (item: any) => {
            return (
              <a
                className="fit-one-line"
                target="_blank"
                rel="noopener noreferrer"
                href={item.url}
              >
                {item.url}
              </a>
            );
          },
        },
        {
          field: "description",
          title: `Meta Description`,
          className: "align-end",
          handleItem: (item: any) => {
            return <p className="fit-one-line">{item.meta.description}</p>;
          },
        },
      ],
      issueCount:
        !!data && !!data.overviewErrors
          ? data.overviewErrors.heading_not_irrelevant_description
          : 0,
    },
    {
      title: "Trang có tỉ lệ nội dung thấp",
      key: "low_content_rate",
      type: "page",
      infor:
        "Trang có tỉ lệ nội dung thấp là khi tỉ lệ văn bản trên trang/mã HTML quá thấp ( dưới 10%), điều này gây ảnh hưởng đến thứ hạng SEO vì trang có thể được đánh giá là không cung cấp đủ thông tin hữu ích.",
      recommendAction:
        "Bổ sung thêm nội dung có giá trị và tối ưu các mã HTLM không cần thiết. Đảm bảo nội dung dễ đọc và chứa từ khóa liên quan để cải thiện thứ hạng SEO.",
      filter: [
        {
          key: "checks.low_content_rate",
          value: "true",
          operator: "=",
          type: "field",
        },
      ],
      headerData: [
        {
          field: "url",
          title: `URL`,
          className: "table-cell-link",
          handleItem: (item: any) => {
            return (
              <a
                className="fit-one-line"
                target="_blank"
                rel="noopener noreferrer"
                href={item.url}
              >
                {item.url}
              </a>
            );
          },
        },
        {
          field: "plain_text_rate",
          title: `Ratio (Text/HTML)`,
          className: " align-end",
          handleItem: (item: any) => {
            return (
              <p className="fit-one-line">
                {(item.meta.content.plain_text_rate * 100).toFixed(2)}%
              </p>
            );
          },
        },
      ],
      issueCount:
        !!data && !!data.overviewErrors
          ? data.overviewErrors.low_content_rate
          : 0,
      navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
      navigateFilter: queryString.stringify({
        tab: 2,
        mFilter: "content",
        sFilter: "lowcontent",
      }),
    },
    {
      title: "Trang có tỉ lệ nội dung cao",
      key: "high_content_rate",
      type: "page",
      infor:
        "Trang có tỉ lệ nội dung cao là khi tỉ lệ văn bản trên trang/mã HTML quá cao (trên 90%), điều này gây khó khăn cho cả người đọc và công cụ tìm kiếm trong việc hiểu nội dung chính trên trang.",
      recommendAction:
        "Giảm cho nội dung trên trang ngắn gọn và liên quan. Tỉ lệ nội dung trên trang/ mã HLML nên từ 10%  - <90%",
      filter: [
        {
          key: "checks.high_content_rate",
          value: "true",
          operator: "=",
          type: "field",
        },
      ],
      headerData: [
        {
          field: "url",
          title: `URL`,
          className: "table-cell-link",
          handleItem: (item: any) => {
            return (
              <a
                className="fit-one-line"
                target="_blank"
                rel="noopener noreferrer"
                href={item.url}
              >
                {item.url}
              </a>
            );
          },
        },
        {
          field: "plain_text_rate",
          title: `Ratio (Text/HTML)`,
          className: " align-end",
          handleItem: (item: any) => {
            return (
              <p className="fit-one-line">
                {item.meta.content.plain_text_rate.toFixed(2)}%
              </p>
            );
          },
        },
      ],
      issueCount:
        !!data && !!data.overviewErrors
          ? data.overviewErrors.high_content_rate
          : 0,
      navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
      navigateFilter: queryString.stringify({
        tab: 2,
        mFilter: "content",
        sFilter: "highcontent",
      }),
    },
    {
      title: "Trang có tỉ lệ đọc thấp",
      key: "low_readability_rate",
      type: "page",
      infor:
        "Trang có tỉ lệ đọc thấp là khi nội dung trên trang khó đọc hoặc khó hiểu, gây khó khăn trong việc tiếp nhận thông tin. Điều này ảnh hưởng xấu đến SEO do thời gian trên trang thấp và tỉ lệ thoát cao.",
      recommendAction:
        "Sử dụng ngôn ngữ đơn giản, chia nhỏ nội dung và thêm tiêu đề phụ. Sử dụng định dạng dễ đọc, như danh sách và khoảng cách hợp lý, để nâng cao trải nghiệm người dùng.",
      filter: [
        {
          key: "checks.low_readability_rate",
          value: "true",
          operator: "=",
          type: "field",
        },
      ],
      headerData: [
        {
          field: "url",
          title: `URL`,
          className: "table-cell-link",
          handleItem: (item: any) => {
            return (
              <a
                className="fit-one-line"
                target="_blank"
                rel="noopener noreferrer"
                href={item.url}
              >
                {item.url}
              </a>
            );
          },
        },
        {
          field: "flesch_kincaid_readability_index",
          title: `Ratio (Text/HTML)`,
          className: " align-end",
          handleItem: (item: any) => {
            return (
              <p className="fit-one-line">
                {item.meta.content.flesch_kincaid_readability_index.toFixed(2)}%
              </p>
            );
          },
        },
      ],
      issueCount:
        !!data && !!data.overviewErrors
          ? data.overviewErrors.low_readability_rate
          : 0,
      navigatePath: ROUTE_PATH.DOMAIN_AUDIT_DETAIL,
      navigateFilter: queryString.stringify({
        tab: 2,
        mFilter: "content",
        sFilter: "lowread",
      }),
    },
  ];

  return (
    <ContentContentsWrapper>
      <OverviewCommonSubTitle title="Content" className="content-subtitle" />
      <ul className="content-list">
        {dataContent.map(content => (
          <li key={content.title}>
            <CollapseHandleError
              headersTable={
                !!content.issueCount && content.issueCount > 0
                  ? content.headerData
                  : undefined
              }
              typeError={
                !content.issueCount || content.issueCount > 0
                  ? EAuditErrorStatus.Warning
                  : EAuditErrorStatus.Success
              }
              title={content.title}
              key={content.key}
              filter={
                !!content.issueCount && content.issueCount > 0
                  ? content.filter
                  : undefined
              }
              type={content.type}
              isNavigateToLink={content.isNavigateToLink}
              infor={content.infor}
              recommendAction={content.recommendAction}
              navigatePath={content.navigatePath}
              navigateFilter={content.navigateFilter}
              isCountError={content.issueCount}
              hiddenStatusICon={true}
            />
          </li>
        ))}
      </ul>
      <ContentTableBox data={data} />
    </ContentContentsWrapper>
  );
}
