import React, { useEffect, useState } from "react";
import { CheckBoxCustom, ControlForm } from "../../../../components";
import InputForm from "../../../../components/controls/InputForm";
import { PaymentInforWrapper } from "../../styles";
import { Control, FieldErrors, useForm } from "react-hook-form";

import { Collapse } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { IStateApp } from "../../../../models/redux";
import { setPlanInforState } from "../../../../redux/planInfor/planInfor.duck";
import { ECurrencyCode } from "../../../../models/common/models.enum";
import { IPlan } from "../../../../models/plan";
import CommonHandle from "../../../../common/handles/common.handles";

interface PaymentInforProps {
  className: string;
  onChangeStep: (value: boolean) => void;
  errors: FieldErrors<{
    fullName?: string | undefined;
    email?: string | undefined;
    phoneNumbersUser?: string | undefined;
    customerName?: string | undefined;
    companyName?: string | undefined;
    phoneNumbers?: string | undefined;
    taxNumbers?: string | undefined;
    address?: string | undefined;
    paymentId?: number | undefined;
    planPackageId?: number | undefined;
  }>;
  control: Control<
    {
      fullName?: string | undefined;
      email?: string | undefined;
      phoneNumbersUser?: string | undefined;
      customerName?: string | undefined;
      companyName?: string | undefined;
      phoneNumbers?: string | undefined;
      taxNumbers?: string | undefined;
      address?: string | undefined;
      paymentId?: number | undefined;
      planPackageId?: number | undefined;
    },
    any
  >;
  isInvoice?: boolean;
  onChangeInvoice: (value: boolean) => void;
}

export default function PaymentInfor(props: PaymentInforProps) {
  const {
    className,
    errors,
    control,
    isInvoice = false,
    onChangeInvoice,
  } = props;

  const currentPlan = useSelector<IStateApp, IPlan | null>(
    appState => appState.planInfor.planInfor
  );

  return (
    <PaymentInforWrapper className={`${className} flex-between-align-top`}>
      <div className="infor-user">
        <h2 className="infor-title">Thông tin cá nhân</h2>
        <form className="infor-form user-form">
          <ControlForm
            title="Tên"
            infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
          >
            <InputForm
              placeholder="Tên"
              required
              name="fullName"
              errors={errors}
              control={control}
              size="small"
              disabled
            />
          </ControlForm>
          <div className="form-flex">
            <ControlForm
              title="Email"
              infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            >
              <InputForm
                placeholder="Email"
                required
                name="email"
                errors={errors}
                control={control}
                size="small"
                disabled
              />
            </ControlForm>
            <ControlForm
              title="Số điện thoại"
              infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            >
              <InputForm
                placeholder="Số điện thoại"
                required
                name="phoneNumbersUser"
                errors={errors}
                control={control}
                disabled
                size="small"
              />
            </ControlForm>
          </div>
        </form>
        <div className="user-checkbox">
          <CheckBoxCustom
            onChange={() => {
              if (!isInvoice) {
                onChangeInvoice(true);
              } else {
                onChangeInvoice(false);
              }
            }}
            label={"Xuất hóa đơn cho doanh nghiệp"}
            value={"Invoice"}
            checked={isInvoice}
            width="20px"
            height="20px"
          />
        </div>
        <Collapse in={isInvoice}>
          <h2 className="infor-title">Thông tin doanh nghiệp</h2>
          <form className="infor-form infor-business-form">
            <ControlForm
              title="Họ và tên"
              infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            >
              <InputForm
                placeholder="Họ và tên"
                required
                name="customerName"
                errors={errors}
                control={control}
                size="small"
              />
            </ControlForm>
            <ControlForm
              title="Tên doanh nghiệp"
              infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            >
              <InputForm
                placeholder="Tên doanh nghiệp"
                required
                name="companyName"
                errors={errors}
                control={control}
                size="small"
              />
            </ControlForm>
            <div className="form-flex">
              <ControlForm
                title="Số điện thoại"
                infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
              >
                <InputForm
                  placeholder="Số điện thoại"
                  required
                  name="phoneNumbers"
                  errors={errors}
                  control={control}
                  size="small"
                />
              </ControlForm>
              <ControlForm
                title="MST"
                infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
              >
                <InputForm
                  placeholder="Mã số thuế"
                  required
                  name="taxNumbers"
                  errors={errors}
                  control={control}
                  size="small"
                />
              </ControlForm>
            </div>
            <ControlForm
              title="Địa chỉ"
              infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            >
              <InputForm
                placeholder="Địa chỉ"
                required
                name="address"
                errors={errors}
                control={control}
                size="small"
              />
            </ControlForm>
          </form>
        </Collapse>
      </div>

      <div className="infor-plan">
        <div className="plan-content">
          <h2 className="plan-title">{currentPlan?.title}</h2>
          <p className="plan-description">{currentPlan?.description}</p>
          <div className="plan-detail plan-detail-expired flex-between-align-center">
            <p className="detail-title">Số lượng</p>
            <p className="detail-content">1 Báo Cáo</p>
          </div>
          <div className="plan-detail flex-between-align-center">
            <p className="detail-title">Package</p>
            <p className="detail-content">
              {CommonHandle.formatCurrency(
                currentPlan?.price,
                ECurrencyCode.VND
              )}
            </p>
          </div>
          <div className="plan-detail flex-between-align-center">
            <p className="detail-title">VAT fee</p>
            <p className="detail-content">
              {CommonHandle.formatCurrency(0, ECurrencyCode.VND)}
            </p>
          </div>
        </div>
        <div className="plan-price flex-end-align-center">
          <p className="price-text">
            {CommonHandle.formatCurrency(
              (currentPlan?.price || 0) + 0,
              ECurrencyCode.VND
            )}
          </p>
          {/* <p className="price-time">/ year</p> */}
        </div>
      </div>
    </PaymentInforWrapper>
  );
}
