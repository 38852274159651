import { IPlan } from "../../models/plan";
import {
  EPlanInforType,
  IActionClearPlanInfor,
  IActionPlanInfor,
  IActionSetPlanInforState,
  IReduxPlanInforState,
} from "../../models/redux/planInfor";
import { EPaymentSteps } from "../../pages/Payment/components/PaymentSteps";

export const setPlanInforState = (data: IPlan): IActionSetPlanInforState => ({
  type: EPlanInforType.SET_PLANINFOR_STATE,
  payload: data,
});

export const clearPlanInfor = (): IActionClearPlanInfor => ({
  type: EPlanInforType.CLEAR_PLANINFOR,
  payload: {},
});

const defaultState: IReduxPlanInforState = {
  planInfor: null,
};

export default function planInforReducer(
  state: IReduxPlanInforState = defaultState,
  action: IActionPlanInfor
): IReduxPlanInforState {
  switch (action.type) {
    case EPlanInforType.SET_PLANINFOR_STATE:
      return { ...state, planInfor: action.payload };
    case EPlanInforType.CLEAR_PLANINFOR:
      return { ...state, planInfor: defaultState.planInfor };

    default:
      return state;
  }
}
