import { AuditTaskStatus } from "../../models/audit";
import { EReportType } from "../../models/common/models.enum";
import { IValue } from "../../models/common/models.type";
import { IBasePagingRes } from "../models/common/model.type";
import IconDomainAudit from "../../assets/images/sidebar/icon_domainaudit_active.svg";
import IconDomainCompetitive from "../../assets/images/sidebar/icon_domaincompetitive_active.svg";
import IconPageAudit from "../../assets/images/sidebar/icon_pageaudit_active.svg";
import IconPageComptitive from "../../assets/images/sidebar/icon_pagecompetitive_active.svg";
import IconDomainAuditUX from "../../assets/images/common/icon_ux_fill_green.svg";
import IconDomainAuditSEO from "../../assets/images/common/icon_seo_fill_green.svg";
import variableStyles from "../../theme/variable-styles";

import Icon1 from "../../assets/images/checkbox/icon_checkbox_1.svg";
import Icon2 from "../../assets/images/checkbox/icon_checkbox_2.svg";
import Icon3 from "../../assets/images/checkbox/icon_checkbox_3.svg";
import Icon4 from "../../assets/images/checkbox/icon_checkbox_4.svg";
import Icon5 from "../../assets/images/checkbox/icon_checkbox_5.svg";

import IconActive1 from "../../assets/images/checkbox/icon_checkbox_active_1.svg";
import IconActive2 from "../../assets/images/checkbox/icon_checkbox_active_2.svg";
import IconActive3 from "../../assets/images/checkbox/icon_checkbox_active_3.svg";
import IconActive4 from "../../assets/images/checkbox/icon_checkbox_active_4.svg";
import IconActive5 from "../../assets/images/checkbox/icon_checkbox_active_5.svg";

const STATUS_OPTIONS: IValue[] = [
  { title: "Common.Active", value: true },
  { title: "Common.Deactive", value: false },
];

const AUDIT_STATUS_OPTIONS: IValue[] = [
  { title: "Waiting", value: AuditTaskStatus.Waiting },
  { title: "Running", value: AuditTaskStatus.Running },
  { title: "Done", value: AuditTaskStatus.Done },
  { title: "Cancelled", value: AuditTaskStatus.Cancelled },
  { title: "Error", value: AuditTaskStatus.Error },
];

const REPORT_TYPE_OPTIONS: IValue[] = [
  {
    title: "Domain Audit",
    value: EReportType.DomainAudit,
    icon: IconDomainAudit,
  },
  {
    title: "Domain Audit SEO",
    value: EReportType.DomainAuditSEO,
    icon: IconDomainAuditSEO,
  },
  {
    title: "Domain Audit UX",
    value: EReportType.DomainAuditUX,
    icon: IconDomainAuditUX,
  },
  {
    title: "Domain Competive",
    value: EReportType.DomainCompetitive,
    icon: IconDomainCompetitive,
  },
  { title: "Page Audit", value: EReportType.PageAudit, icon: IconPageAudit },
  {
    title: "Page Competitive",
    value: EReportType.PageCompetitive,
    icon: IconPageComptitive,
  },
];

export const DEFAULT_BASE_PAGING_RES: IBasePagingRes<any> = {
  items: [],
  page: 1,
  pageSize: 0,
  total: 0,
  isFull: true,
};

const VALUE_LIST = {
  STATUS_OPTIONS,
  AUDIT_STATUS_OPTIONS,
  REPORT_TYPE_OPTIONS,
};

export default VALUE_LIST;

export const AreaChartColors = [
  variableStyles.Info400,
  variableStyles.blueSecondary400,
  variableStyles.blueSecondary600,
  variableStyles.Info700,
  variableStyles.Warning600,
];

export const PieChartColors = [
  variableStyles.Warning800,
  variableStyles.blueSecondary600,
  variableStyles.Success600,
  variableStyles.Warning600,
  variableStyles.Info600,
  variableStyles.NaturalColor600,
];

export const CheckBoxIconList = [
  { iconCheck: Icon1, iconCheckActive: IconActive1 },
  { iconCheck: Icon2, iconCheckActive: IconActive2 },
  { iconCheck: Icon3, iconCheckActive: IconActive3 },
  { iconCheck: Icon4, iconCheckActive: IconActive4 },
  { iconCheck: Icon5, iconCheckActive: IconActive5 },
];
