import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import {
  IBasePagingReq,
  IBasePagingRes,
  IBaseResponseModel,
} from "../../models/common/models.type";
import {
  ICreateOutlinePostRes,
  ICreateOutlineProjectsReq,
  IGetOutlinePostReq,
  IGetOutlinePostRes,
  IGetOutlineProjectsRes,
  IProjectRes,
  ITop5HeadingReq,
  ITop5HeadingRes,
  IUpdatePostReq,
} from "../../models/outline";

const API_OUTLINE = ApiEndpoints.OUTLINE;
const baseURL = envConfig.API_KEY_ENDPOINT;

const getTop5Heading = (
  params: ITop5HeadingReq
): Promise<IBaseResponseModel<ITop5HeadingRes>> => {
  return api.get(baseURL, API_OUTLINE.GET_TOP_5_HEADING.url, {
    params: params,
  });
};

const getListProject = (
  params: IBasePagingReq
): Promise<IBaseResponseModel<IBasePagingRes<IGetOutlineProjectsRes>>> => {
  return api.get(baseURL, API_OUTLINE.GET_LIST_PROJECT.url, {
    params: params,
  });
};

const getSingleProject = (
  id: string
): Promise<IBaseResponseModel<IProjectRes>> => {
  return api.get(baseURL, API_OUTLINE.CREATE_PROJECT.url, {
    params: { id: id },
  });
};

const createProject = (data: {
  name: string;
}): Promise<IBaseResponseModel<ICreateOutlineProjectsReq>> => {
  return api.post(baseURL, API_OUTLINE.CREATE_PROJECT.url, data);
};

const updateProject = (data: {
  name: string;
  id: string;
}): Promise<IBaseResponseModel<ICreateOutlineProjectsReq>> => {
  return api.put(baseURL, API_OUTLINE.CREATE_PROJECT.url, data);
};

const deleteProject = (id: string): Promise<IBaseResponseModel<boolean>> => {
  return api.delete(baseURL, `${API_OUTLINE.CREATE_PROJECT.url}/${id}`);
};

const getListPost = (
  params: IGetOutlinePostReq
): Promise<IBaseResponseModel<IBasePagingRes<IGetOutlinePostRes>>> => {
  return api.get(baseURL, API_OUTLINE.GET_LIST_POST.url, {
    params: params,
  });
};

const getSinglePost = (
  id: string
): Promise<IBaseResponseModel<IGetOutlinePostRes>> => {
  return api.get(baseURL, API_OUTLINE.CREATE_POST.url, {
    params: { id: id },
  });
};

const createPost = (data: {
  name: string;
  projectId: string;
}): Promise<IBaseResponseModel<ICreateOutlinePostRes>> => {
  return api.post(baseURL, API_OUTLINE.CREATE_POST.url, data);
};

const updatePostName = (data: {
  name: string;
  id: string;
}): Promise<IBaseResponseModel<ICreateOutlineProjectsReq>> => {
  return api.put(baseURL, API_OUTLINE.UPDATE_POST_NAME.url, data);
};

const updatePost = (data: IUpdatePostReq): Promise<IBaseResponseModel<any>> => {
  return api.put(baseURL, API_OUTLINE.CREATE_POST.url, data);
};

const deletePost = (id: string): Promise<IBaseResponseModel<boolean>> => {
  return api.delete(baseURL, `${API_OUTLINE.CREATE_POST.url}/${id}`);
};

const OutlineService = {
  getTop5Heading,
  getListProject,
  getSingleProject,
  createProject,
  updateProject,
  deleteProject,
  getListPost,
  createPost,
  updatePostName,
  updatePost,
  deletePost,
  getSinglePost,
};

export default OutlineService;
