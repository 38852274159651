import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ImageCustom } from "..";
import IconPlan from "../../assets/images/common/icon_plan_fill_blue.svg";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { PlanBoxWrapper } from "./styles";

interface PlanBoxProps {
  className?: string;
}

export default function PlanBox(props: PlanBoxProps) {
  const { className = "" } = props;
  const navigate = useNavigate();
  return (
    <PlanBoxWrapper className={`${className}`}>
      <p className="plan-title">Nâng cấp Pro Plan</p>
      <p className="plan-description">Lorem ipsum</p>
      <Button
        size="xsmall"
        variant="contained"
        color="secondary"
        onClick={() => navigate(ROUTE_PATH.PRICING)}
      >
        <ImageCustom
          src={IconPlan}
          alt="Plan-Icon"
          width={"18px"}
          height={"18px"}
        />
        <p>Update the plan</p>
      </Button>
    </PlanBoxWrapper>
  );
}
