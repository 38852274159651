import React from "react";
import { ReportFooterWrapper } from "./styles";
import IconEmail from "../../assets/images/common/icon_email_line_grey.svg";
import IconEarth from "../../assets/images/common/icon_earth_line_grey.svg";
import IconPhone from "../../assets/images/common/icon_phone_line_grey.svg";
import ImageCustom from "../Image";

interface ReportFooterProps {
  className?: string;
  conclusion: React.ReactNode;
  title?: string;
  height?: string;
}

export default function ReportFooter(props: ReportFooterProps) {
  const { className, conclusion, height, title = "Conclusion" } = props;
  return (
    <ReportFooterWrapper height={height} className={`${className}`}>
      <div className="polygon-overlay"></div>
      <div className="footer-intro">
        <p className="intro-title">{title}</p>
        <div className="intro-description">{conclusion}</div>
      </div>

      <div className="footer-infor">
        <p className="infor-brandname">{"WEBAUDIT"}</p>
        <div className="infor-wrapper flex-start-align-center">
          <ImageCustom
            src={IconEarth}
            alt="Icon-Earth"
            width={"16px"}
            height={"16px"}
          />
          <a
            className="infor-website"
            href="https://webaudit.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {"https://webaudit.io/"}
          </a>
        </div>
        <div className="infor-wrapper flex-start-align-center">
          <ImageCustom
            src={IconPhone}
            alt="Icon-Phone"
            width={"16px"}
            height={"16px"}
          />
          <p className="infor-phone">SDT: {"+84 9999999"}</p>
        </div>
        <div className="infor-wrapper flex-start-align-center">
          <ImageCustom
            src={IconEmail}
            alt="Icon-Email"
            width={"16px"}
            height={"16px"}
          />
          <p className="infor-email">Email: {"infor@mikotech.com"}</p>
        </div>
      </div>
      <p className="madeby-text">
        Made by
        <br />
        WEBAUDIT
      </p>
    </ReportFooterWrapper>
  );
}
