import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const PaymentWrapper = styled("div")(() => ({
  width: "100%",
  position: "relative",
  backgroundColor: variableStyles.NaturalColor50,
  "& .payment-content": {
    width: "100%",
    maxWidth: "1070px",
    padding: " 0 20px 74px",
    margin: "0 auto",
    overflow: "auto",
    height: "calc(100vh - 120px)",
  },
  "@keyframes hidden-box": {
    "0%": {
      transform: "translateX(0)",
    },
    "75%": {
      transform: "translateX(calc(-100% - 10px))",
    },
    "100%": {
      display: "none",
      transform: "translateX(calc(-100% - 10px))",
    },
  },

  "@keyframes show-box": {
    "0%": {
      transform: "translateX(calc(-100% - 10px))",
    },
    "100%": { transform: "translateX(0)" },
  },
  "& .visible-box": {
    willChange: "display, transform",
    animation: "show-box .2s linear forwards",
  },
  "& .hidden-box": {
    willChange: "display, transform",
    animation: "hidden-box .2s linear forwards",
  },
}));

export const PaymentStepsWrapper = styled("div")(() => ({
  width: "100%",
  padding: "15px 12px",
  borderBottom: `1px solid ${variableStyles.NaturalColor300}`,
  backgroundColor: variableStyles.colorWhite,
  "& .steps-wrapper": {
    width: "100%",
    maxWidth: "1030px",
    gap: "42px",
    margin: "0 auto",
  },
  "& .steps-back": { flex: "0 0 auto" },
  "& .step-text": {
    color: variableStyles.NaturalColor700,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "16px",
    lineHeight: "19.36px",
  },
  "& .steps-list": {
    width: "100%",
    gap: "16px",
    "& .step-dashed": {
      flex: "1 1 auto",
      height: "1px",
      borderTop: `1px dashed ${variableStyles.NaturalColor300}`,
    },
    "& li": {
      gap: "12px",
      position: "relative",
      flex: "0 0 auto",
      "& .step-done": {
        width: "28px",
        height: "28px",
        borderRadius: "50%",
        backgroundColor: variableStyles.GreenPrimaryColor400,
        "&.error": {
          backgroundColor: variableStyles.Warning600,
        },
      },
      "& .step-number": {
        color: variableStyles.NaturalColor700,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "14px",
        lineHeight: "14px",
        width: "28px",
        height: "28px",
        borderRadius: "50%",
        backgroundColor: variableStyles.NaturalColor200,
      },

      "&.current": {
        "& .step-number": {
          color: variableStyles.NaturalColor1000,
        },
        "& .step-text": {
          color: variableStyles.NaturalColor1000,
        },
      },
    },
  },
  "& .proccess-loading": {
    width: "28px",
    height: "28px",
    "@keyframes rotate": {
      "0%": { transform: "rotate(0deg)" },
      "100%": { transform: "rotate(360deg)" },
    },
    "@keyframes prixClipFix": {
      "0%": { clipPath: "polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)" },
      "50%": {
        clipPath: "polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)",
      },
      "75%, 100%": {
        clipPath: "polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)",
      },
    },
    "& .loader": {
      width: "28px",
      height: "28px",
      borderRadius: "50%",
      position: "absolute",
      animation: "rotate .8s linear infinite",
    },
    "& .loader::before , & .loader::after": {
      content: '""',
      boxSizing: "border-box",
      position: "absolute",
      inset: "0px",
      borderRadius: "50%",
      border: `2px solid ${variableStyles.blueSecondary500}`,
      animation: " prixClipFix 2s linear forwards",
    },
    "& .loader::after": {
      transform: "rotate3d(90, 90, 0, 180deg )",
      borderColor: variableStyles.NaturalColor0,
      opacity: ".5",
    },
  },
}));

export const PaymentSummaryWrapper = styled("div")(() => ({
  padding: "16px 12px",
  borderTop: `1px solid ${variableStyles.NaturalColor300}`,
  position: "absolute",
  bottom: "0",
  left: "0",
  width: "100%",
  backgroundColor: variableStyles.colorWhite,
  "& .summary-content": { width: "100%", maxWidth: "1070px", margin: "0 auto" },
  "& .total-content": {
    paddingLeft: "16px",
    borderLeft: `1px solid ${variableStyles.NaturalColor400}`,
    "& .content-title": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "16.94px",
      marginBottom: "4px",
    },
    "& .content-detail": {
      color: variableStyles.NaturalColor600,
      fontWeight: variableStyles.fwRegular,
      fontSize: "16px",
      lineHeight: "19.36px",
      "& span": {
        fontWeight: variableStyles.fwSemiBold,
        color: variableStyles.NaturalColor1000,
      },
    },
  },
}));

export const PaymentInforWrapper = styled("div")(() => ({
  padding: "24px 0",
  gap: "40px",
  "@media (min-width:1160px)": { flexDirection: "row" },
  "@media (max-width:1159.98px)": { flexDirection: "column-reverse" },
  "& .infor-title": {
    color: variableStyles.NaturalColor1000,
    fontWeight: variableStyles.fwMedium,
    fontSize: "20px",
    lineHeight: "20px",
    marginBottom: "24px",
  },
  "& .form-flex": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "12px",
  },
  "& .infor-form": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .infor-user": {
    flex: "1 1 auto",
    width: "100%",

    "& .user-form": {
      marginBottom: "36px",
    },
    "& .user-checkbox": {
      marginBottom: "36px",
    },
  },
  "& .infor-plan": {
    borderRadius: "16px",
    boxShadow: "0px 0px 18.3px 0px #0000000A",
    border: `1px solid ${variableStyles.NaturalColor300}`,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    "@media (min-width:1160px)": {
      maxWidth: "377px",
      flex: "0 0 auto",
      height: "485px",
      "& .plan-content": { padding: "25px 28px" },
      "& .plan-description": {
        marginBottom: "36px",
      },
    },
    "@media (max-width:1159.98px)": {
      maxWidth: "100%",
      flex: "1 1 auto",
      height: "unset",
      "& .plan-content": { padding: "16px" },
      "& .plan-description": {
        marginBottom: "20px",
      },
    },
    "& .plan-content": {
      flex: "1 1 auto",
      "& .plan-title": {
        color: variableStyles.Info700,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "28px",
        lineHeight: "33.89px",
        marginBottom: "10px",
      },
      "& .plan-description": {
        color: variableStyles.NaturalColor600,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "14px",
        lineHeight: "21px",
      },
      "& .plan-detail": {
        marginBottom: "8px",
        "& p": {
          fontWeight: variableStyles.fwMedium,
          fontSize: "14px",
          lineHeight: "30px",
          "&.detail-title": { color: variableStyles.NaturalColor800 },
          "&.detail-content": { color: variableStyles.NaturalColor1000 },
        },
        "&.plan-detail-expired": {
          paddingBottom: "8px",
          borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
        },
      },
    },
    "& .plan-price": {
      backgroundColor: variableStyles.Info100,
      padding: "16px",
      gap: "1ch",
      "& .price-text": {
        color: variableStyles.Info900,
        fontWeight: variableStyles.fwMedium,
        fontSize: "26px",
        lineHeight: "30px",
      },
      "& .price-time": {
        color: variableStyles.Info900,
        fontWeight: variableStyles.fwMedium,
        fontSize: "14px",
        lineHeight: "30px",
      },
    },
  },
}));
export const PaymentMethodWrapper = styled("div")(() => ({
  gap: "34px",
  display: "flex",
  padding: "24px 0 16px",
  "@media (min-width:1160px)": { flexDirection: "row" },
  "@media (max-width:1159.98px)": { flexDirection: "column" },
  "& .image-add-card": {
    padding: "5px 14px",
    backgroundColor: variableStyles.colorWhite,
  },
  "& .method-list": {
    flex: "1 1 auto",
    "& .list-title": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwMedium,
      fontSize: "20px",
      lineHeight: "20px",
      marginBottom: "24px",
    },
    "& ul.card-list": {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      "&:first-of-type": { marginBottom: "40px" },
    },
  },
  "& .method-infor-wrapper": {
    flex: "1 1 auto",
    borderRadius: "16px",
    border: `1px solid ${variableStyles.NaturalColor300}`,
    boxShadow: "0px 0px 18.3px 0px #0000000A",
    padding: "16px",
    "& .method-infor": {
      width: "284px",
      "& .method-infor-title": {
        color: variableStyles.NaturalColor1000,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "20px",
        lineHeight: "24.2px",
        marginBottom: "36px",
      },
      "& .method-form": {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      },
    },
  },
}));

export const PaymentResultWrapper = styled("div")(() => ({
  flexDirection: "column",
  padding: "24px",

  "& .result-image": {
    marginBottom: "16px",
  },
  "& .result-title": {
    color: variableStyles.NaturalColor1000,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "28px",
    lineHeight: "33.89px",
    marginBottom: "12px",
  },
  "& .result-description": {
    color: variableStyles.NaturalColor800,
    fontWeight: variableStyles.fwMedium,
    fontSize: "18px",
    lineHeight: "21.78px",
    marginBottom: "36px",
  },
  "& .plan-summary": {
    padding: "24px",
    border: `1px solid ${variableStyles.NaturalColor300}`,
    boxShadow: "0px 0px 18.3px 0px #0000000A",
    borderRadius: "16px",
    marginBottom: "36px",
    minWidth: "378px",
    "& .summary-title": {
      color: variableStyles.Info700,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "28px",
      lineHeight: "33.89px",
      marginBottom: "10px",
    },
    "& .summary-description": {
      color: variableStyles.NaturalColor600,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "14px",
      lineHeight: "27px",
      marginBottom: "36px",
    },
    "& .list-plan-feature": {
      display: "flex",
      flexDirection: "column",
      gap: "8px",

      "& li": {
        "&.has-border": {
          paddingBottom: "8px",
          borderBottom: `1px solid ${variableStyles.NaturalColor200}`,
        },
        "&.total": {
          "& .feature-description": {
            color: variableStyles.Info700,
          },
        },
      },
      "& .feature-title": {
        color: variableStyles.NaturalColor800,
        fontWeight: variableStyles.fwMedium,
        fontSize: "14px",
        lineHeight: "30px",
      },
      "& .feature-description": {
        color: variableStyles.NaturalColor1000,
        fontWeight: variableStyles.fwMedium,
        fontSize: "14px",
        lineHeight: "30px",
      },
    },
  },
  "& .modal-action": {
    display: "flex",
    gap: "12px",
    "& button": {
      minWidth: "156px",
    },
  },
  "& .result-loading": {
    height: "calc(100vh - 242px)",
    width: "100%",
    gap: "12px",
    "& p": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "20px",
      lineHeight: "30px",
    },
  },
}));
