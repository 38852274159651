import React from "react";
import { PricingTitleWrapper } from "./styles";

export default function PricingTitle(props: {
  text: string;
  description?: string;
}) {
  const { text, description } = props;
  return (
    <PricingTitleWrapper className="flex-between-align-center">
      <p className="title">{text}</p>
      <p className="description">{description}</p>
    </PricingTitleWrapper>
  );
}
