import ImageCustom from "../Image";
import { ReportHeaderWrapper } from "./styles";
import IconWeb from "../../assets/images/common/icon_earth_line_grey.svg";
import IconCompany from "../../assets/images/common/icon_company_line_grey.svg";
import { useEffect, useRef, useState } from "react";

interface ReportHeaderHeaderProps {
  className?: string;
  title: React.ReactNode;
  description: string;
  brandName?: string;
  domain?: string;
  height?: string;
}

export default function ReportHeader(props: ReportHeaderHeaderProps) {
  const {
    className = "",
    title,
    description,
    brandName,
    domain,
    height,
  } = props;

  const refParent = useRef<any>(null);

  useEffect(() => {
    // to handle page resize
    const getwidth = () => {
      const width = refParent?.current?.offsetWidth;
      if (width < 800) {
        const title = document.querySelector<HTMLElement>(
          ".header-intro-title"
        )!;
        title.style.fontSize = `${(width / 800) * 80}px`;
        title.style.lineHeight = `${(width / 800) * 80 + 2}px`;
      }
    };
    // when the component gets mounted
    getwidth();
    window.addEventListener("resize", getwidth);
    // remove the event listener before the component gets unmounted
    return () => window.removeEventListener("resize", getwidth);
  }, []);

  return (
    <ReportHeaderWrapper
      className={`${className}`}
      height={height}
      ref={refParent}
    >
      <div className="polygon-overlay"></div>
      <p className="madeby-text">
        Made by
        <br />
        WEBAUDIT
      </p>
      <div className="header-infor">
        {!!brandName && (
          <div className="infor-wrapper flex-start-align-center">
            <ImageCustom
              src={IconCompany}
              alt="Icon-Company"
              width={"16px"}
              height={"16px"}
            />
            <p className="infor-brandname">{brandName}</p>
          </div>
        )}
        {!!domain && (
          <div className="infor-wrapper flex-start-align-center">
            <ImageCustom
              src={IconWeb}
              alt="Icon-Web"
              width={"16px"}
              height={"16px"}
            />
            <p className="infor-domain">
              <span>Website: </span>
              {domain}
            </p>
          </div>
        )}
      </div>
      <div className="header-intro">
        <p className="header-intro-title">{title}</p>
        <p className="intro-description">{description}</p>
      </div>
    </ReportHeaderWrapper>
  );
}
