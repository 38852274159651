import { styled } from "@mui/material/styles";
import variableStyles from "../../../../theme/variable-styles";

export const PricingTitleWrapper = styled("div")(() => ({
  marginBottom: "32px",
  width: "100%",
  "& .title": {
    color: variableStyles.NaturalColor1000,
    fontWeight: variableStyles.fwBold,
    fontSize: "24px",
    lineHeight: "36px",
    borderLeft: `4px solid ${variableStyles.GreenPrimaryColor400}`,
    paddingLeft: "20px",
  },
  "& .description": {
    color: variableStyles.NaturalColor950,
    fontWeight: variableStyles.fwSemiBold,
    fontSize: "16px",
    lineHeight: "19.36px",
  },
}));
