import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// MUI
import { Button } from "@mui/material";
// models
import { ILoginRequest, ILoginResponse } from "../../models/auth";
// redux
import InputPassword from "../../components/controls/InputPassword";
import InputForm from "../../components/controls/InputForm";
import { LoginWrapper } from "./styles";
import { ImageCustom } from "../../components";
import LogoIcon from "../../assets/images/login/login_logo_img_line_green.png";
import EmailIcon from "../../assets/images/login/login_icon_email_line_green.svg";
import PasswordIcon from "../../assets/images/login/login_icon_password_line_green.svg";
import Banner from "../../assets/images/login/login_img_banner.png";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { useEffect, useState } from "react";
import { IBaseResponseModel } from "../../models/common/models.type";
import AuthService from "../../services/api/auth.service";
import { localStorageHanlde } from "../../services/services";
import { setUserState } from "../../redux/user/user.duck";
import { toggleMessage } from "../../components/Toast/Toast";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(`${"Vui lòng nhập email"}`),
    password: Yup.string().required(`${"Vui lòng nhập mật khẩu"}`),
  }) as any;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginRequest>({
    defaultValues: {
      username: null,
      password: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (loginInfo: ILoginRequest) => {
    setIsLoading(true);
    try {
      const resLogin: IBaseResponseModel<ILoginResponse> =
        await AuthService.loginWithPassword(loginInfo);
      if (resLogin.success && !resLogin.message) {
        const loginInfo = resLogin.data;
        setIsLoading(false);
        await localStorageHanlde.storeToken(loginInfo.token.token);
        await localStorageHanlde.storeRole(loginInfo.user.role);
        await dispatch(setUserState(loginInfo.user));

        navigate(ROUTE_PATH.INDEX, { replace: true });
      } else if (!!resLogin.message && resLogin.message.length > 0) {
        setIsLoading(false);
        toggleMessage({
          type: "error",
          message: resLogin.message,
          title: "Error Occurred",
        });
      } else if (!resLogin.success && !resLogin.message) {
        setIsLoading(false);
        toggleMessage({
          type: "error",
          message: "Your email or password is incorrect!",
          title: "Error Occurred",
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{"Login"}</title>
      </Helmet>
      <LoginWrapper>
        <div className="login-box-left">
          <Link to="/" className="left-logo">
            <ImageCustom
              src={LogoIcon}
              alt="LOGIN_LOGO"
              width={"246px"}
              height={"36px"}
            />
          </Link>
          <h2>Đăng nhập</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputForm
              placeholder="Email"
              required
              name="username"
              errors={errors}
              control={control}
              startIcon={EmailIcon}
              size="medium"
            />
            <InputPassword
              placeholder="Mật khẩu"
              name="password"
              errors={errors}
              control={control}
              startIcon={PasswordIcon}
            />
            <Button
              color="primary"
              fullWidth
              size="medium"
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
              className="button-submit"
            >
              {isLoading && <span className="loader"></span>}
              <p className={`text-submit ${isLoading ? "is-loading" : ""}`}>
                Đăng nhập
              </p>
            </Button>
          </form>
          {/* <Button size="large" variant="text" className="forgot-button">
            Forgot password?
          </Button> */}
          {/* <Divider className="left-divider">or</Divider> */}
          {/* <Button
            size="medium"
            variant="outlined"
            className="left-google-button"
          >
            <ImageCustom
              src={GoogleIcon}
              alt="Google_Icon"
              width={"24px"}
              height={"24px"}
            />
            Đăng nhập bằng Google
          </Button>
          */}
          <div className="sign-up-question">
            <p>Bạn chưa có tài khoản?</p>
            <Button
              size="large"
              variant="text"
              onClick={() => {
                navigate(ROUTE_PATH.REGISTER);
              }}
            >
              Đăng kí
            </Button>
          </div>
        </div>
        <div className="login-box-right">
          <p className="right-text">
            We’re here to Increase
            <br />
            your Productivity
          </p>
          <ImageCustom
            src={Banner}
            alt="LOGIN_BANNER"
            width={"726px"}
            height={"479px"}
            className="right-banner"
          />
        </div>
      </LoginWrapper>
    </>
  );
};

export default Login;
