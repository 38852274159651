import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const PricingWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  position: "relative",
  padding: "24px",
  maxWidth: "1180px",
  margin: "0 auto",
  "&::after": {
    content: '""',
    width: "634px",
    height: "446px",
    backgroundColor: variableStyles.GreenPrimaryColor400,
    filter: "blur(1000px)",
    position: "absolute",
    top: "0",
    right: "0",
    pointerEvents: "none",
  },
  "& .pricing-title": {
    color: variableStyles.NaturalColor1000,
    fontWeight: variableStyles.fwBold,
    fontSize: "54px",
    lineHeight: "73px",
    textAlign: "center",
    marginBottom: "10px",
  },
  "& .pricing-description": {
    color: variableStyles.NaturalColor950,
    fontWeight: variableStyles.fwRegular,
    fontSize: "18px",
    lineHeight: "21.78px",
    textAlign: "center",
    marginBottom: "56px",
  },
  "& .pricing-tabs": {
    padding: "7px",
    boxShadow: "0px 4px 9px 0px #0000000D",
    backgroundColor: variableStyles.colorWhite,
    borderRadius: "10px",
    gap: "4px",
    position: "relative",
    zIndex: "1",
    marginBottom: "57px",
    "& .tabs-tab": {
      minWidth: "160px",
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "18px",
      lineHeight: "30px",
      padding: "13px",
      backgroundColor: "transparent",
      borderRadius: "10px",
      transition: "all .2s linear",
      "&:hover": { backgroundColor: variableStyles.GreenPrimaryColor200 },
      "&.active": {
        color: variableStyles.colorWhite,
        backgroundColor: variableStyles.GreenPrimaryColor400,
      },
    },
  },
  "& .box-empty": {
    "& .text-empty": {
      color: variableStyles.NaturalColor1000,
      fontWeight: variableStyles.fwMedium,
      fontSize: "20px",
      lineHeight: "24px",
    },
  },
  "@keyframes growAnimation": {
    "0%": { transform: "translate(-50%, -50%) scale(1)" },
    "100%": {
      transform: "translate(-50%, -50%) scale(1.5)",
    },
  },
  "@keyframes fadeAnimation": {
    "0%": { transform: "translate(-50%, -50%) scale(1.5)" },
    "100%": {
      transform: "translate(-50%, -50%) scale(1)",
    },
  },
  "& .pricing-plan-list": {
    position: "relative",
    zIndex: "1",
    minWidth: "374px",
    display: "grid",
    width: "100%",
    "@media (min-width:1240px)": {
      gap: "29px",
      gridTemplateColumns: "1fr 1fr 1fr",
      "&.nodata": { gridTemplateColumns: "1fr" },
    },
    "@media (max-width:1239.98px) and (min-width: 950px)": {
      gap: "14px",
      gridTemplateColumns: "1fr 1fr",
      "&.nodata": { gridTemplateColumns: "1fr" },
    },
    "@media (max-width:949.98px)": {
      gap: "14px",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  },
}));
