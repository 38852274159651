import React from "react";
import moment from "moment";
import { DEFAULT_BASE_PAGING_RES } from "../../../../common/constants/value.constant";
import {
  ImageCustom,
  TableNavDetailNoPagination,
} from "../../../../components";
import { ITableHeader } from "../../../../components/CollapseHandleError";
import { DATETIME_FORMAT } from "../../../../config/constants";
import { HistoryBoxWrapper } from "../../styles";
import { EReportStatus } from "../../../../models/common/models.enum";
import IConDetail from "../../../../assets/images/common/icon_eye_line_grey.svg";
import IconDownload from "../../../../assets/images/common/icon_download_line_grey.svg";

interface ProfileHistoryBoxProps {
  className?: string;
}

export default function ProfileHistoryBox(props: ProfileHistoryBoxProps) {
  const { className } = props;

  const headersTable: ITableHeader<any>[] = [
    {
      field: "id",
      title: `Mã số`,
      width: "90px",
      className: "table-cell-link",
      isSticky: true,
    },
    {
      field: "createdAt",
      title: `Ngày`,
      width: "120px",
    },
    {
      field: "createdAt",
      title: `Tên gói`,
      width: "180px",
      handleItem: (item: any) => {
        return <>{onRenderTag(item.status)}</>;
      },
    },
    {
      field: "companyName",
      title: `Công ty`,
      width: "180px",
    },
    {
      field: "status",
      title: `Trạng thái`,
      width: "130px",
      handleItem: (item: any) => {
        return (
          <p className="fit-one-line">
            {moment(item.createdAt).format(DATETIME_FORMAT)}
          </p>
        );
      },
    },
    {
      field: "action",
      title: `Action`,
      width: "120px",
      handleItem: (item: any) => {
        return (
          <div className="history-action-wrapper flex-start-align-center">
            <button>
              <ImageCustom
                isAvatar
                src={IConDetail}
                alt={"Detail-icon"}
                width={"20px"}
                height={"20px"}
              />
            </button>
            <button>
              <ImageCustom
                isAvatar
                src={IconDownload}
                alt={"Download-icon"}
                width={"20px"}
                height={"20px"}
              />
            </button>
          </div>
        );
      },
    },
  ];

  const onRenderTag = (status: EReportStatus) => {
    return (
      <div
        className={`history-status-tag ${status === EReportStatus.Done ? "done" : "fail"}`}
      >
        <p>
          {status === EReportStatus.Done ? "Đã thanh toán" : "Chưa thanh toán"}
        </p>
      </div>
    );
  };

  return (
    <HistoryBoxWrapper className={className}>
      <h2 className="history-title">Lịch sử thanh toán</h2>
      <TableNavDetailNoPagination
        data={{ ...DEFAULT_BASE_PAGING_RES, items: [{}] }}
        headersTable={headersTable}
        maxHeight={"calc(100vh - 301px)"}
      />
    </HistoryBoxWrapper>
  );
}
