import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DEFAULT_BASE_PAGING_RES } from "../../../../../../../common/constants/value.constant";
import CommonHandle from "../../../../../../../common/handles/common.handles";
import {
  CollapseHandleError,
  ImageCustom,
  LoadingIndicator,
  TableNavDetailNoPagination,
} from "../../../../../../../components";
import { ICrawlPageRes } from "../../../../../../../models/audit-report";
import { IAuditReportData } from "../../../../../../../models/audit-report-data";
import { EAuditErrorStatus } from "../../../../../../../models/common/models.enum";
import { IStateApp } from "../../../../../../../models/redux";
import { IGetReportsRes } from "../../../../../../../models/report";
import AuditReportService from "../../../../../../../services/api/audit.report.service";
import variableStyles from "../../../../../../../theme/variable-styles";
import CrawlDataRenderBox from "../CrawlDataRenderBox";

interface CrawlPageDataBoxProps {
  status: boolean;
  title: string;
  description: string;
  data?: {
    typeError: EAuditErrorStatus;
    title: string;
    dataArray?: React.ReactNode;
    infor?: string;
    recommendAction?: any;
  }[];
  mainImg?: string;
}

export default function CrawlPageDataBox(props: {
  dataMobilePerformance: {
    performance: boolean;
    "color-contrast": boolean;
    "paste-preventing-inputs": boolean;
    "target-size": boolean;
    "image-aspect-ratio": boolean;
    "image-size-responsive": boolean;
    "font-size": boolean;
  };
  auditReportData: IAuditReportData;
}) {
  const { dataMobilePerformance, auditReportData } = props;
  const [resCrawlPageData, setResCrawlPageData] = useState<any>();

  const dataReport = useSelector<IStateApp, IGetReportsRes>(
    appState => appState.project?.dataReport
  );

  useEffect(() => {
    if (!!auditReportData) {
      checkPerformance();
    }
  }, [auditReportData]);

  const checkPerformance = () => {
    if (!!auditReportData && Object.keys(auditReportData.uxDomain).length > 0) {
      setResCrawlPageData(auditReportData.uxDomain);
    }
  };

  const crawlPageData: CrawlPageDataBoxProps[] = [
    {
      status: true,
      title: "Xây dựng bộ nhận diện thương hiệu",
      description:
        "Bộ nhận diện thương hiệu là tập hợp các yếu tố đồ họa đại diện cho một thương hiệu như logo, màu sắc chủ đạo, kiểu chữ, hình ảnh, ... Xây dựng bộ nhận diện thương hiệu giúp khách hàng dễ dàng nhận diện và ghi nhớ thương hiệu của doanh nghiệp.",
      data: [
        {
          typeError: !!resCrawlPageData?.logo?.Answers?.isReviews
            ? EAuditErrorStatus.Success
            : EAuditErrorStatus.Error,
          title: "Có logo",
          dataArray: !!resCrawlPageData?.logo?.src ? (
            <CrawlDataRenderBox
              commentText={resCrawlPageData?.logo?.Answers?.answerImgs}
            >
              <div className="flex-start-align-center" style={{ gap: "12px" }}>
                <p className="common-text">Logo:</p>
                <ImageCustom
                  src={resCrawlPageData?.logo?.src || ""}
                  alt="logo"
                  width={92}
                  height={53}
                  objectFit="contain"
                />
              </div>
            </CrawlDataRenderBox>
          ) : undefined,
          recommendAction: (
            <ul>
              <li>
                Logo cần thiết kế rõ ràng, sắc nét, và phù hợp với ngành nghề
                kinh doanh của doanh nghiệp.
              </li>
            </ul>
          ),
          infor:
            "Logo là biểu tượng đồ họa đại diện cho thương hiệu, công ty hoặc tổ chức. Logo thường được thiết kế để giúp nhận diện thương hiệu và tạo ấn tượng mạnh mẽ với khách hàng.",
        },
        {
          typeError: resCrawlPageData?.mainColor?.Answers?.isReviews
            ? EAuditErrorStatus.Success
            : EAuditErrorStatus.Error,
          title: "Màu sắc",
          recommendAction:
            "Ưu tiên sử dụng màu sắc đồng nhất, dễ nhận diện, phù hợp với ngành nghề và phong cách thương hiệu.",
          infor:
            "Màu sắc là yếu tố tạo ấn tượng mạnh mẽ, gợi cảm xúc và liên kết với giá trị thương hiệu.",
          dataArray: !!resCrawlPageData?.mainColor ? (
            <CrawlDataRenderBox
              commentText={
                !!resCrawlPageData?.mainColor?.Answers
                  ? resCrawlPageData?.mainColor?.Answers?.answerImgs
                  : ""
              }
            >
              <TableNavDetailNoPagination
                headersTable={[
                  {
                    field: "color",
                    title: `Màu sắc`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return (
                        <div
                          className="flex-start-align-center"
                          style={{ gap: "12px" }}
                        >
                          <p className="common-text">{item?.color}</p>
                          <div
                            className="color-box"
                            style={{
                              backgroundColor: item?.color,
                              width: "20px",
                              height: "20px",
                              borderRadius: "6px",
                            }}
                          ></div>
                        </div>
                      );
                    },
                  },
                  {
                    field: "count",
                    title: `Số lần sử dụng`,
                    width: "100px",
                    handleItem: (item: any) => {
                      return <p>{item?.count || 0}</p>;
                    },
                  },
                  {
                    field: "totalArea",
                    title: `Khu vực xuất hiện`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return (
                        <p>
                          {CommonHandle.formatNumber(
                            Math.round(item?.totalArea)
                          ) || 0}
                          px
                        </p>
                      );
                    },
                  },
                ]}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                  items: new Array(
                    resCrawlPageData?.mainColor?.mostSpaces,
                    resCrawlPageData?.mainColor?.mostUsed
                  ),
                }}
              />
            </CrawlDataRenderBox>
          ) : undefined,
        },
        {
          typeError:
            !!resCrawlPageData?.mostUsedFont?.Answers &&
            resCrawlPageData?.mostUsedFont?.Answers.isReviews
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Kiểu chữ",
          infor:
            "Kiểu chữ có ảnh hưởng lớn đến nhận diện thương hiệu, góp phần tạo nên phong cách và cá tính cho thương hiệu.",
          recommendAction:
            "Sử dụng kiểu chữ dễ đọc, phù hợp với phong cách và thông điệp của thương hiệu, ví dụ: kiểu chữ mạnh mẽ cho công ty công nghệ, hay chữ viết tay cho thương hiệu về sáng tạo, nghệ thuật,...",
          dataArray: !!resCrawlPageData?.mostUsedFont ? (
            <CrawlDataRenderBox
              commentText={
                !!resCrawlPageData?.mostUsedFont?.Answers
                  ? resCrawlPageData?.mostUsedFont?.Answers?.answerImgs
                  : ""
              }
            >
              <TableNavDetailNoPagination
                headersTable={[
                  {
                    field: "font",
                    title: `Kiểu chữ`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return <p className="common-text">{item?.font}</p>;
                    },
                  },
                  {
                    field: "count",
                    title: `Số lượt sử dụng`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return <p className="common-text">{item?.count}</p>;
                    },
                  },
                  {
                    field: "size",
                    title: `Kích cỡ chữ`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return <p className="common-text">{item?.size}px</p>;
                    },
                  },
                  {
                    field: "weight",
                    title: `Độ dày chữ`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return <p className="common-text">{item?.weight}</p>;
                    },
                  },
                ]}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                  items: new Array(resCrawlPageData?.mostUsedFont),
                }}
              />
            </CrawlDataRenderBox>
          ) : undefined,
        },
        {
          typeError:
            !!resCrawlPageData?.img?.Answers &&
            resCrawlPageData?.img?.Answers?.isReviews
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Hình ảnh",
          infor:
            "Hình ảnh là các yếu tố thị giác giúp truyền tải thông điệp và giá trị của thương hiệu thay cho văn bản, tạo sự nổi bật và ghi nhớ thương hiệu trong tâm trí khách hàng.",
          recommendAction:
            "Sử dụng hình ảnh rõ ràng, sắc nét mang thông điệp phù hợp với lĩnh vực kinh doanh và phong cách thương hiệu.",
          dataArray: !!resCrawlPageData?.img ? (
            <CrawlDataRenderBox
              commentText={
                !!resCrawlPageData?.img?.Answers
                  ? resCrawlPageData?.img?.Answers.answerImgs
                  : ""
              }
            >
              <TableNavDetailNoPagination
                headersTable={[
                  {
                    field: "img",
                    title: `Đường dẫn`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return (
                        <a
                          className="fit-one-line"
                          href={item.src}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item?.src}
                        </a>
                      );
                    },
                  },
                  {
                    field: "img",
                    title: `Hình ảnh`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return (
                        <ImageCustom
                          src={item?.src || ""}
                          alt="logo"
                          width={60}
                          height={60}
                          objectFit="contain"
                        />
                      );
                    },
                  },
                ]}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                  items:
                    resCrawlPageData?.img?.list.map((img: any) => ({
                      src: img,
                    })) || [],
                }}
              />
            </CrawlDataRenderBox>
          ) : undefined,
        },
        {
          typeError: resCrawlPageData?.protect?.isReviews
            ? EAuditErrorStatus.Success
            : EAuditErrorStatus.Error,
          title: "Bảo mật",
          infor:
            "Vấn đề bảo mật liên quan trực tiếp đến uy tín thương hiệu. Website được bảo mật tốt giúp khách hàng yên tâm khi cung cấp thông tin cá nhân, gia tăng lòng tin và sự trung thành với thương hiệu. ",
          recommendAction:
            "Đảm bảo website sử dụng chứng chỉ SSL để mã hóa mọi giao dịch và thông tin người dùng. Thường xuyên kiểm tra và cập nhật hệ thống bảo mật trên website, tránh để lỗ hổng bảo mật bị khai thác.",
          dataArray: !!resCrawlPageData?.protect ? (
            <CrawlDataRenderBox
              commentText={
                !!resCrawlPageData?.protect
                  ? resCrawlPageData?.protect.answerImgs
                  : ""
              }
            />
          ) : undefined,
        },
      ],
    },
    {
      status: false,
      title: "Tính khả dụng và Tính dễ đọc",
      description:
        "Tính khả dụng và Tính dễ đọc là hai yếu tố quan trọng trong thiết kế website. Tính khả dụng đảm bảo người dùng có thể dễ dàng tương tác và hoàn thành các tác vụ, trong khi tính dễ đọc đảm bảo người dùng dễ dàng đọc hiểu và tiếp nhận các thông tin trên website.",
      data: [
        {
          typeError: resCrawlPageData?.minimumFont?.IsMinimumFontSize
            ? EAuditErrorStatus.Success
            : EAuditErrorStatus.Error,
          title: "Cỡ chữ",
          dataArray: !!resCrawlPageData?.minimumFont ? (
            <CrawlDataRenderBox commentText="Kết quả đánh giá bên dưới dựa theo cỡ chữ được sử dụng nhỏ nhất trên website.">
              <TableNavDetailNoPagination
                headersTable={[
                  {
                    field: "font",
                    title: `Kiểu chữ`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return <p className="common-text">{item?.font}</p>;
                    },
                  },
                  {
                    field: "type",
                    title: `Loại`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return <p className="common-text">{item?.type}</p>;
                    },
                  },
                  {
                    field: "count",
                    title: `Số lượt sử dụng`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return <p className="common-text">{item?.count}</p>;
                    },
                  },
                  {
                    field: "size",
                    title: `Kích cỡ chữ`,
                    width: "120px",
                    handleItem: (item: any) => {
                      return <p className="common-text">{item?.size}px</p>;
                    },
                  },
                  {
                    field: "weight",
                    title: `Độ dày chữ`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return <p className="common-text">{item?.weight}</p>;
                    },
                  },
                ]}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                  items: new Array(resCrawlPageData?.minimumFont),
                }}
              />
            </CrawlDataRenderBox>
          ) : undefined,
          recommendAction:
            "Chọn cỡ chữ dễ đọc trên cả máy tính và các thiết bị di động. Cỡ chữ quá nhỏ có thể làm giảm khả năng tiếp nhận thông tin của người dùng.",
          infor:
            "Cỡ chữ (font size) là kích thước của chữ trong văn bản, thường được đo bằng đơn vị như px. Cỡ chữ ảnh hưởng đến tính dễ đọc và thẩm mỹ của nội dung.",
        },
        {
          typeError: resCrawlPageData?.lineHeight?.IsMinimumLineHeight
            ? EAuditErrorStatus.Success
            : EAuditErrorStatus.Error,
          title: "Khoảng cách dòng",
          dataArray: !!resCrawlPageData?.lineHeight ? (
            <CrawlDataRenderBox commentText="Kết quả đánh giá bên dưới dựa theo khoảng cách dòng nhỏ nhất kiểm tra được trên website.">
              <TableNavDetailNoPagination
                headersTable={[
                  {
                    field: "font",
                    title: `Khoảng cách`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return <p className="common-text">{item?.Height}px</p>;
                    },
                  },
                  {
                    field: "fontSize",
                    title: `Cỡ chữ`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return <p className="common-text">{item?.fontSize}px</p>;
                    },
                  },
                  {
                    field: "size",
                    title: `Kiểu chữ`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return <p className="common-text">{item?.fontFamily}</p>;
                    },
                  },
                ]}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                  items: new Array(resCrawlPageData?.lineHeight),
                }}
              />
            </CrawlDataRenderBox>
          ) : undefined,
          recommendAction:
            "Khoảng cách dòng nên từ 1.4 đến 1.6 lần kích cỡ font chữ để giúp người dùng dễ dàng đọc và tiếp nhận thông tin.",
          infor:
            "Khoảng cách dòng là khoảng cách giữa các dòng văn bản trong một đoạn văn. Khoảng cách dòng quá nhỏ hoặc quá lớn đều sẽ gây khó khăn trong việc đọc hiểu thông tin.",
        },
        {
          typeError: resCrawlPageData?.fontFamily?.IsMinimumFontFamily
            ? EAuditErrorStatus.Success
            : EAuditErrorStatus.Error,
          title: "Số lượng kiểu chữ",
          dataArray: !!resCrawlPageData?.fontFamily ? (
            <CrawlDataRenderBox commentText="Kết quả đánh giá bên dưới dựa theo số lượng kiểu chữ đang được sử dụng trên website.">
              <TableNavDetailNoPagination
                headersTable={[
                  {
                    field: "fontFamily",
                    title: `Kiểu chữ`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return <p className="common-text">{item?.fontFamily}</p>;
                    },
                  },
                ]}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                  items:
                    resCrawlPageData?.fontFamily?.List?.map((font: any) => ({
                      fontFamily: font,
                    })) || [],
                }}
              />
            </CrawlDataRenderBox>
          ) : undefined,
          recommendAction:
            "Mặc dù hoàn toàn có thể sử dụng nhiều kiểu chữ trong thiết kế website, tuy nhiên nên giới hạn từ 2 đến 3 kiểu chữ chính để tạo sự nhất quán và dễ đọc.",
          infor:
            "Số lượng kiểu chữ là số lượng các kiểu chữ khác nhau được sử dụng trên website.",
        },
        {
          typeError: resCrawlPageData?.button?.IsMinimumButton
            ? EAuditErrorStatus.Success
            : EAuditErrorStatus.Error,
          title: "Kích cỡ nút bấm",
          dataArray: !!resCrawlPageData?.button ? (
            <CrawlDataRenderBox commentText="Kết quả đánh giá bên dưới dựa theo kích cỡ nút bấm nhỏ nhất được sử dụng trên website.">
              <TableNavDetailNoPagination
                headersTable={[
                  {
                    field: "fontFamily",
                    title: `Width`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return (
                        <p className="common-text">
                          {Math.round(item?.width)}px
                        </p>
                      );
                    },
                  },
                  {
                    field: "fontFamily",
                    title: `Height`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return (
                        <p className="common-text">
                          {" "}
                          {Math.round(item?.height)}px
                        </p>
                      );
                    },
                  },
                  {
                    field: "fontFamily",
                    title: `Kí tự`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return <p className="common-text">{item?.text}</p>;
                    },
                  },
                ]}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                  items: new Array(resCrawlPageData?.button),
                }}
              />
            </CrawlDataRenderBox>
          ) : undefined,
          recommendAction:
            "Nút bấm cần đủ lớn để người dùng dễ dàng bấm vào, đặc biệt trên thiết bị di động. Các nút bấm nhỏ hoặc khó bấm sẽ gây khó chịu cho người dùng và ảnh hưởng đến tỷ lệ chuyển đổi.",
          infor:
            "Kích cỡ nút bấm bao gồm chiều rộng và chiều cao của nút bấm trên website. Kích cỡ nút bấm ảnh hưởng đến sự thuận tiện và khả năng thao tác của người dùng.",
        },
      ],
    },
    {
      status: true,
      title: "Khả năng điều hướng",
      description:
        "Khả năng điều hướng là một hệ thống hướng dẫn người dùng thực hiện các hoạt động trên website. Một hệ thống điều hướng đơn giản giúp người dùng dễ dàng di chuyển trên website của bạn, giúp họ biết đang ở trang nào trên website, có thể đi đến trang nào cũng như dễ dàng quay lại các trang mong muốn.",
      data: [
        {
          typeError:
            !!resCrawlPageData &&
            !!resCrawlPageData.extractLinkHighlight &&
            resCrawlPageData.extractLinkHighlight.length > 0
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Liên kết có màu phân biệt",
          infor:
            "Liên kết là các văn bản được gắn link dẫn đến các nội dung khác trên website hoặc dẫn đến các website khác. Sử dụng màu sắc phân biệt giúp ngừời dùng dễ dàng nhận ra đâu là liên kết và đâu là văn bản thường.",
          recommendAction:
            "Sử dụng màu sắc cho phần văn bản liên kết khác với các phần văn bản xung quanh. Có thể chọn màu xanh lam làm màu mặc định cho liên kết hoặc chọn màu liên kết phù hợp với màu chủ đạo của website.",
          dataArray: !!resCrawlPageData?.extractLinkHighlight ? (
            <CrawlDataRenderBox>
              <TableNavDetailNoPagination
                headersTable={[
                  {
                    field: "url",
                    title: `Liên kết`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return (
                        <div
                          className="flex-start-align-center"
                          style={{ gap: "12px" }}
                        >
                          <a
                            href={item?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="fit-one-line"
                          >
                            {item?.url}
                          </a>
                        </div>
                      );
                    },
                  },
                  {
                    field: "color",
                    title: `Màu sắc`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return (
                        <div
                          className="flex-start-align-center"
                          style={{ gap: "12px" }}
                        >
                          <p className="common-text">{item?.color}</p>
                          <div
                            className="color-box"
                            style={{
                              backgroundColor: item?.color,
                              width: "20px",
                              height: "20px",
                              borderRadius: "6px",
                              border: `1px solid ${variableStyles.NaturalColor300}`,
                            }}
                          ></div>
                        </div>
                      );
                    },
                  },
                  {
                    field: "backgroundColor",
                    title: `Màu nền`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return (
                        <div
                          className="flex-start-align-center"
                          style={{ gap: "12px" }}
                        >
                          <p className="common-text">{item?.backgroundColor}</p>
                          <div
                            className="color-box"
                            style={{
                              backgroundColor: item?.backgroundColor,
                              width: "20px",
                              height: "20px",
                              borderRadius: "6px",
                              border: `1px solid ${variableStyles.NaturalColor300}`,
                            }}
                          ></div>
                        </div>
                      );
                    },
                  },
                ]}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                  items: resCrawlPageData?.extractLinkHighlight || [],
                }}
              />
            </CrawlDataRenderBox>
          ) : undefined,
        },
        {
          typeError:
            !!resCrawlPageData && !!resCrawlPageData.hasSearchBar
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Có khung tìm kiếm",
          recommendAction:
            "Một khung tìm kiếm ở vị trí dễ thấy trên trang web cho phép người dùng nhập vào những từ khóa họ mong muốn từ đó giúp người dùng tìm kiếm thông tin một cách nhanh chóng và dễ dàng.",
          infor:
            "Đặt khung tìm kiếm ở vị trí dễ thấy và dễ truy cập đồng thời đảm bảo kết quả tìm kiếm chính xác và phù hợp với từ khóa tìm kiếm.",
        },
        {
          typeError:
            !!resCrawlPageData && !!resCrawlPageData.hasBreadcrumbs
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Có breadcrumbs",
          infor:
            "Breadcrumb là chuỗi các liên kết giúp người dùng biết vị trí của họ trên website và dễ dàng di chuyển giữa các trang. Breadcrumb cũng giúp công cụ tìm kiếm hiểu cấu trúc website từ đó cải thiện khả năng xếp hạng website trên kết quả tìm kiếm.",
          recommendAction: (
            <ul>
              <li>
                Đặt breadcrumb ở vị trí dễ thấy thường là phía trên tiêu đề bài
                viết hoặc phía dưới thanh menu.
              </li>
              <li>
                Dùng màu sắc tương phản để nổi bật breadcrumb và dùng biểu tượng
                như mũi tên hoặc gạch ngang để phân cách liên kết.
              </li>
            </ul>
          ),
        },
        {
          typeError:
            !!resCrawlPageData && !!resCrawlPageData.hasBackToTopButton
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Có nút quay lại đầu trang",
          infor:
            "Nút quay lại đầu trang (thường được biểu thị bằng một mũi tên hướng lên) cho phép người dùng nhanh chóng quay trở lại đầu trang chỉ bằng một cú click chuột.",
          recommendAction: (
            <ul>
              <li>
                Đặt nút quay lại đầu trang ở góc dưới bên phải màn hình - vị trí
                người dùng thường đưa chuột đến khi muốn di chuyển lên trên.
              </li>
              <li>
                Có thể tạo dạng nút tĩnh (cố định) hoặc nút động (chỉ xuất hiện
                khi cuộn xuống dưới một ngưỡng nhất định).
              </li>
            </ul>
          ),
        },
        {
          typeError:
            !!resCrawlPageData && !!resCrawlPageData.hasPagination
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Có phân trang",
          infor:
            "Phân trang là việc chia nhỏ nội dung trên một trang  thành các trang con, giúp giảm thiểu tốc độ tải trang đồng thời giúp người dùng dễ dàng tìm kiếm và tập trung vào thông tin họ quan tâm mà không bị quá tải bởi lượng thông tin quá lớn.",
          recommendAction: (
            <ul>
              <li>
                Dùng phân trang cho blog hoặc trang sản phẩm. Hiển thị danh sách
                các trang con tại cuối mỗi trang để người dùng tùy chọn.
              </li>
              <li>
                Lưu ý: Sử dụng thẻ rel="next" và rel="prev" tại các trang con để
                hỗ trợ tối ưu SEO.
              </li>
            </ul>
          ),
        },
        {
          typeError:
            !!resCrawlPageData && !!resCrawlPageData.hasbuttonWithLinks
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Có button điều hướng",
          infor: `Button điều hướng là các nút bấm dùng để di chuyển giữa các trang hoặc khám phá thêm các nội dung cần thiết trên website, giúp người dùng duyệt web nhanh chóng và dễ dàng hơn.`,
          recommendAction: `Button cần có màu sắc nổi bật và đặt ở các vị trí dễ tiếp cận như cuối bài viết hoặc ở góc dưới bên phải của trang. Đảm bảo button có kích thước đủ lớn và dễ bấm ngay cả trên thiết bị di động.`,
        },
        {
          typeError:
            !!resCrawlPageData && !!resCrawlPageData.hasMenu
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Có menu",
          infor:
            "Menu là một tập hợp các liên kết trên website được sắp xếp một cách logic và hệ thống, giúp người dùng dễ dàng nắm bắt cấu trúc của website từ đó dễ dàng tìm thấy sản phẩm, dịch vụ hoặc thông tin cần thiết.",
          recommendAction: (
            <ul>
              <li>
                Đặt menu ở vị trí phù hợp: menu ngang (thường đặt ở đầu trang)
                hoặc menu dọc (thường ở bên trái)...
              </li>
              <li>
                Sắp xếp các mục trong menu theo thứ tự quan trọng hoặc theo cấu
                trúc website.
              </li>
            </ul>
          ),
        },
      ],
    },
    {
      status: true,
      title: "Tối ưu chuyển đổi",
      description:
        "Tối ưu hóa chuyển đổi việc cải thiện các yếu tố trên trang web nhằm tăng tỷ lệ người dùng thực hiện hành động mong muốn như mua hàng, đăng ký, điền vào form, nhấp vào một liên kết cụ thể, v.v.",
      data: [
        {
          typeError:
            !!resCrawlPageData && !!resCrawlPageData.hasContactForm
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Form liên hệ",
          infor:
            "Form liên hệ là nơi cho phép người dùng nhập các thông tin cần thiết nhằm thực hiện các hoạt động khảo sát, liên hệ tư vấn, nhận thông tin hoặc đặt hàng trên website.",
          recommendAction: (
            <ul>
              <li>
                Chỉ yêu cầu người dùng điền các thông tin cần thiết đồng thời
                hiển thị thông báo lỗi rõ ràng khi nhập sai.
              </li>
              <li>
                Các trường nhập liệu cần đủ lớn để dễ dàng thao tác ngay cả trên
                di động.
              </li>
            </ul>
          ),
        },
        {
          typeError:
            !!resCrawlPageData &&
            !!resCrawlPageData.phoneNumbers &&
            resCrawlPageData.phoneNumbers.length > 0
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Số điện thoại",
          infor:
            "Số điện thoại trên website giúp khách hàng liên hệ nhanh chóng, tăng cơ hội chuyển đổi và thể hiện sự chuyên nghiệp, đáng tin cậy của doanh nghiệp.",
          recommendAction: (
            <ul>
              <li>
                Số điện thoại nên được thiết kế nổi bật, đặt ở vị trí dễ thấy
                như đầu trang hoặc góc dưới phải màn hình.
              </li>
              <li>
                Đảm bảo số điện thoại chính xác và luôn hoạt động để khách hàng
                dễ dàng liên hệ.
              </li>
            </ul>
          ),
          dataArray: !!resCrawlPageData?.phoneNumbers ? (
            <CrawlDataRenderBox>
              <TableNavDetailNoPagination
                headersTable={[
                  {
                    field: "phoneNumbers",
                    title: `Số điện thoại`,
                    width: "140px",
                    handleItem: (item: any) => {
                      return (
                        <div
                          className="flex-start-align-center"
                          style={{ gap: "12px" }}
                        >
                          <p className="common-text">{item?.phoneNumber}</p>
                        </div>
                      );
                    },
                  },
                ]}
                data={{
                  ...DEFAULT_BASE_PAGING_RES,
                  items:
                    resCrawlPageData?.phoneNumbers.map((phone: any) => ({
                      phoneNumber: phone,
                    })) || [],
                }}
              />
            </CrawlDataRenderBox>
          ) : undefined,
        },
        {
          typeError:
            !!resCrawlPageData && !!resCrawlPageData.hasCTA.hasCTA
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Lời kêu gọi hành động",
          infor:
            "Lời kêu gọi hành động giúp thúc đẩy người dùng thực hiện hành động cụ thể như liên hệ tư vấn, đặt lịch hoặc mua hàng,...Đồng thời, theo dõi lượt bấm vào các nút kêu gọi hành động giúp đo lường hiệu quả marketing.",
          recommendAction: (
            <ul>
              <li>
                Tạo sự cấp bách với các từ ngữ như "Hạn chót", "Chỉ còn vài
                ngày", "Số lượng có hạn",....
              </li>
              <li>
                Nút CTA phải đủ lớn để dễ dàng nhấp vào đồng thời sử dụng màu
                sắc tương phản để tạo sự thu hút.
              </li>
            </ul>
          ),
          dataArray:
            !!resCrawlPageData?.hasCTA &&
            resCrawlPageData?.hasCTA.CTAData.length > 0 ? (
              <CrawlDataRenderBox>
                <TableNavDetailNoPagination
                  headersTable={[
                    {
                      field: "text",
                      title: `CTA`,
                      width: "140px",
                      handleItem: (item: any) => {
                        return (
                          <div
                            className="flex-start-align-center"
                            style={{ gap: "12px" }}
                          >
                            <p className="common-text">{item?.text}</p>
                          </div>
                        );
                      },
                    },
                  ]}
                  data={{
                    ...DEFAULT_BASE_PAGING_RES,
                    items: resCrawlPageData?.hasCTA.CTAData || [],
                  }}
                />
              </CrawlDataRenderBox>
            ) : undefined,
        },
        {
          typeError:
            !!resCrawlPageData && !!resCrawlPageData.hasChatbot
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Tính năng chat",
          infor:
            "Tính năng chat giúp trả lời nhanh các câu hỏi của người dùng dựa trên kịch bản hoặc AI mà không cần sự hỗ trợ của con người.",
          recommendAction: (
            <ul>
              <li>
                Đặt nút chat ở vị trí dễ thấy như góc dưới bên phải màn hình.
              </li>
              <li>
                Chatbot cần phản hồi nhanh chóng, chính xác và có thể chuyển các
                câu hỏi cá nhân hóa đến nhân viên khi cần.
              </li>
            </ul>
          ),
        },
        {
          typeError:
            !!resCrawlPageData && !!resCrawlPageData?.hasReview
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Review khách hàng",
          infor:
            "Đánh giá từ khách hàng thực tế giúp tăng độ tin cậy của sản phẩm/dịch vụ. Khi thấy nhiều phản hồi tích cực, khách hàng tiềm năng yên tâm hơn khi quyết định mua hàng.",
          recommendAction: (
            <ul>
              <li>
                Đặt phần đánh giá khách hàng ở cuối các trang quan trọng như
                trang chủ, trang sản phẩm/dịch vụ.
              </li>
              <li>
                Cung cấp thông tin rõ ràng về người đánh giá, bao gồm tên, ảnh
                đại diện, chức vụ/ công ty,... để tăng độ tin cậy.
              </li>
            </ul>
          ),
        },
      ],
    },
    {
      status: true,
      title: "Trang 404 tuỳ chỉnh",
      description:
        "Trang 404 là một trang thông báo lỗi khi người dùng truy cập vào một trang không tồn tại trên website. Khi người dùng truy cập vào một trang không tồn tại trên website, họ dễ cảm thấy thất vọng và muốn rời đi. Việc thiết kế một trang 404 tùy chỉnh giúp cải thiện trải nghiệm người dùng, giúp giữ chân họ ở lại và thu hút họ bấm vào xem các nội dung mong muốn khác trên website.",
      mainImg: `${!!resCrawlPageData && !!resCrawlPageData.page404 && resCrawlPageData.page404?.list?.length > 0 ? resCrawlPageData.page404?.list[0]?.src : ""}`,
      data: [
        {
          typeError:
            !!resCrawlPageData &&
            resCrawlPageData.page404?.list?.length > 0 &&
            !!resCrawlPageData.page404?.list[0]?.Answers?.isReviews
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Thiết kế thân thiện",
          infor:
            "Thiết kế trang 404 thân thiện là tạo ra một trang thông báo lỗi rõ ràng, giúp người dùng cảm thấy dễ chịu thay vì bối rối hoặc thất vọng khi không tìm thấy trang họ đang tìm kiếm.",
          recommendAction: `Thiết kế trang 404 đồng bộ với màu sắc và chủ đề của website, giúp duy trì tính nhất quán trong giao diện. Thông báo lỗi nên rõ ràng, ví dụ: "Trang bạn tìm không tồn tại" hoặc "Không tìm thấy trang yêu cầu".`,
          dataArray:
            !!resCrawlPageData?.page404 &&
            resCrawlPageData.page404?.list?.length > 0 ? (
              <CrawlDataRenderBox
                commentText={
                  resCrawlPageData.page404?.list[0]?.Answers?.answerImgs
                }
              ></CrawlDataRenderBox>
            ) : undefined,
        },
        {
          typeError:
            !!resCrawlPageData &&
            resCrawlPageData.page404?.list?.length > 0 &&
            !!resCrawlPageData.page404?.list[1]?.Answers?.isReviews
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Cung cấp liên kết hữu ích",
          infor:
            "Cung cấp liên kết hữu ích trên trang lỗi 404 là việc thêm các liên kết đến các phần quan trọng hoặc phổ biến của trang web, như Trang chủ, Công cụ tìm kiếm, Danh mục sản phẩm,...giúp cải thiện trải nghiệm và giữ chân người dùng.",
          recommendAction: (
            <>
              <p>Một số liên kết nên thêm vào trang 404:</p>
              <ul>
                <li>- Liên kết tới trang chủ</li>
                <li>- Liên kết tới các trang phổ biến</li>
                <li>- Liên kết đến sản phẩm nổi bật</li>
                <li>- Khung tìm kiếm thông tin</li>
              </ul>
            </>
          ),
          dataArray:
            !!resCrawlPageData?.page404 &&
            resCrawlPageData.page404?.list?.length > 0 ? (
              <CrawlDataRenderBox
                commentText={
                  resCrawlPageData.page404?.list[1]?.Answers?.answerImgs
                }
              ></CrawlDataRenderBox>
            ) : undefined,
        },
        {
          typeError:
            !!resCrawlPageData &&
            resCrawlPageData.page404?.list?.length > 0 &&
            !!resCrawlPageData.page404?.list[2]?.Answers?.isReviews
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Yếu tố sáng tạo và giải trí",
          infor:
            "Yếu tố sáng tạo và giải trí trên trang 404 là những hình ảnh vui nhộn hoặc nội dung độc đáo nhằm làm dịu sự thất vọng của người dùng khi gặp lỗi, đồng thời mang lại trải nghiệm thú vị và đáng nhớ.",
          recommendAction:
            "Sử dụng hình ảnh hoạt hình hoặc biểu tượng thú vị, như một con vật dễ thương hoặc một nhân vật hài hước, để tạo cảm giác dễ chịu. Nếu được có thể thêm một trò chơi nhỏ hoặc mini game trên trang 404 để tăng tính giải trí.",
          dataArray:
            !!resCrawlPageData?.page404 &&
            resCrawlPageData.page404?.list?.length > 0 ? (
              <CrawlDataRenderBox
                commentText={
                  resCrawlPageData?.page404?.list[2]?.Answers?.answerImgs
                }
              ></CrawlDataRenderBox>
            ) : undefined,
        },
        {
          typeError:
            !!resCrawlPageData && resCrawlPageData?.page404?.statusCode === 404
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Mã trạng thái không tìm thấy (404)",
          infor:
            "Đảm bảo trang web trả về mã 404 khi trang không tồn tại giúp hệ thống kích hoạt trang lỗi thân thiện, hướng dẫn người dùng tiếp tục khám phá nội dung khác trên trang web thay vì gặp một trang trắng hoặc lỗi máy chủ không rõ ràng.",
          recommendAction:
            "Cấu hình lại máy chủ web hoặc framework để đảm bảo rằng khi tài nguyên không tồn tại, máy chủ trả về mã trạng thái HTTP 404.",
          dataArray: !!resCrawlPageData?.page404 ? (
            <CrawlDataRenderBox
              commentText={`Status code: ${resCrawlPageData?.page404?.statusCode}`}
            ></CrawlDataRenderBox>
          ) : undefined,
        },
      ],
    },
    {
      status: true,
      title: "Thân thiện với di động",
      description:
        "Hiện nay khách hàng dành rất nhiều thời gian trên di động. Việc tối ưu trang web thân thiện với di động là một yếu tố quan trọng giúp gia tăng trải nghiệm của khách hàng cũng như cải thiện vị trí website trên công cụ tìm kiếm.",
      data: [
        {
          typeError:
            !!dataMobilePerformance && !!dataMobilePerformance.performance
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Hiệu suất trên di động",
          infor:
            "Hiệu suất trên di động đề cập đến tốc độ tải trang và khả năng tương tác của website khi truy cập từ thiết bị di động. Tối ưu hiệu suất di động giúp tăng trải nghiệm người dùng và cải thiện thứ hạng website trên kết quả tìm kiếm.",
          recommendAction: `Tối ưu tốc độ tải trang, nén hình ảnh, giảm kích thước file và áp dụng caching, CDN để cải thiện hiệu suất trên di động và trải nghiệm người dùng.`,
        },
        {
          typeError:
            !!dataMobilePerformance && !!dataMobilePerformance["color-contrast"]
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Độ tương phản giữa văn bản và nền",
          infor:
            "Độ tương phản giữa văn bản và nền là sự chênh lệch độ sáng giữa màu văn bản và màu nền. Tối ưu đôh tương phản giúp văn bản dễ đọc hơn, đặc biệt với những người có vấn đề về thị lực, tăng khả năng tiếp cận và trải nghiệm người dùng.",
          recommendAction:
            "Chọn màu nền và văn bản có độ chênh lệch rõ ràng. Tỷ lệ tương phản tối thiểu được khuyến nghị là 4.5:1 đối với văn bản nhỏ và 3:1 đối với văn bản lớn hoặc các yếu tố đồ họa.",
        },
        {
          typeError:
            !!dataMobilePerformance &&
            !!dataMobilePerformance["paste-preventing-inputs"]
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Cho phép dán văn bản",
          infor:
            "Cho phép dán văn bản là tính năng cho phép sao chép nội dung có sẵn vào các ô nhập liệu trên website. Tính năng này giúp người dùng tiết kiệm thời gian từ đó cải thiện trải nghiệm và gia tăng khả năng chuyển đổi.",
          recommendAction:
            "Đảm bảo rằng tất cả các ô nhập liệu trên website đều hỗ trợ chức năng dán văn bản .",
        },
        {
          typeError:
            !!dataMobilePerformance && !!dataMobilePerformance["target-size"]
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Kích thước và khoảng cách vùng chạm",
          infor:
            "Kích thước và khoảng cách vùng chạm là kích cỡ và khoảng cách giữa các phần tử có thể nhấn trên website (như nút bấm, liên kết, biểu tượng,...). Kích thước và khoảng cách vùng chạm đủ lớn giúp giảm sai sót khi nhấn, cải thiện trải nghiệm người dùng.",
          recommendAction:
            "Đảm bảo các vùng chạm (như nút, liên kết) có kích thước đủ lớn và khoảng cách hợp lý để người dùng dễ dàng tương tác.",
        },
        {
          typeError:
            !!dataMobilePerformance &&
            !!dataMobilePerformance["image-aspect-ratio"]
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Hiển thị hình ảnh đúng tỷ lệ khung hình",
          infor:
            "Hình ảnh hiện thị đúng tỷ lệ khung hình là việc đảm bảo hình ảnh được hiển thị đúng với tỷ lệ khung hình gốc, không bị kéo giãn hay méo mó gây khó chịu cho người dùng.",
          recommendAction: (
            <ul>
              <li>
                -Sử dụng CDN hình ảnh: Tự động tạo các phiên bản hình ảnh có
                kích thước khác nhau cho các thiết bị.
              </li>
              <li>
                - Đặt rõ thuộc tính width và height trong HTML: Giúp trình duyệt
                phân bổ không gian trước khi hình ảnh tải.
              </li>
            </ul>
          ),
        },
        {
          typeError:
            !!dataMobilePerformance &&
            !!dataMobilePerformance["image-size-responsive"]
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Độ phân giải hình ảnh thích hợp",
          infor:
            "Độ phân giải hình ảnh thích hợp đề cập đến việc cung cấp các kích thước hình ảnh khác nhau tùy theo loại màn hình thiết bị. Điều này đảm bảo hình ảnh phù hợp với các thiết bị khác nhau, tiết kiệm băng thông và cải thiện tốc độ tải trang.",
          recommendAction:
            "Thông thường nên sử dụng 3 đến 5 kích thước hình ảnh khác nhau tùy theo loại thiết bị. Việc phân phát nhiều kích thước hình ảnh hơn sẽ tốt hơn cho hiệu suất, nhưng sẽ chiếm nhiều dung lượng hơn trên máy chủ.",
        },
        {
          typeError:
            !!dataMobilePerformance && !!dataMobilePerformance["font-size"]
              ? EAuditErrorStatus.Success
              : EAuditErrorStatus.Error,
          title: "Kích cỡ font chữ văn bản",
          infor:
            "Kích thước font chữ văn bản phải đủ lớn để dễ đọc, đặc biệt trên các thiết bị di động. Kích thước chữ quá nhỏ có thể gây khó khăn trong quá trình đọc, làm giảm trải nghiệm người dùng và tăng tỉ lệ thoát trang.",
          recommendAction:
            "Kiểm tra kích thước phông chữ trong CSS. Cố gắng đặt kích thước phông chữ tối thiểu là 12 px trên ít nhất 60% văn bản trên trang.",
        },
      ],
    },
  ];

  return (
    <>
      {crawlPageData.map((item, i) => (
        <CrawlDataBox
          key={i}
          status={item.status}
          title={item.title}
          description={item.description}
          data={item.data}
          mainImg={item.mainImg}
        />
      ))}
    </>
  );
}

const CrawlDataBox = (props: CrawlPageDataBoxProps) => {
  const { data, status, title, description, mainImg } = props;

  return (
    <div className="box-wrapper">
      <div className="box-header flex-between-align-center">
        <Typography variant="h3">{title}</Typography>
        <div className={`header-status ${status ? "passed" : "failed"}`}>
          <p className="status-text">Status</p>
          <p className="status-content">{status ? "Passed" : "Failed"}</p>
        </div>
      </div>
      <p className="box-description">{description}</p>

      {!!mainImg && (
        <figure className="screenshot-404-img">
          <ImageCustom
            src={mainImg}
            alt="main-Img"
            width={"100%"}
            height={"100%"}
          />
        </figure>
      )}
      {!!data && (
        <ul className="list-box">
          {data.map(item => (
            <li key={item.title}>
              <CollapseHandleError
                typeError={item.typeError}
                title={item.title}
                type={"crawl"}
                dataArray={item.dataArray}
                infor={item.infor}
                recommendAction={item.recommendAction}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
