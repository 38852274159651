import { EReportStatus } from "../../common/models.enum";

export interface IReduxSignalRState {
  audit: {
    auditId: string;
    status: EReportStatus | null;
  };
  auditCrawl: {
    auditId: string;
    status: EReportStatus | null;
  };
  paymentAudit: {
    orderId: string;
    status: string | null;
  };
}

export enum ESignalRType {
  SET_SIGNALR_STATE = "SET_SIGNALR_STATE",
  CLEAR_SIGNALR = "CLEAR_SIGNALR",
  SET_SOCKET_CRAWL_STATE = "SET_SOCKET_CRAWL_STATE",
  CLEAR_SOCKET_CRAWL = "CLEAR_SOCKET_CRAWL",
  SET_PAYMENT_SIGNALR_STATE = "SET_PAYMENT_SIGNALR_STATE",
  CLEAR_PAYMENT_SIGNALR = "CLEAR_PAYMENT_SIGNALR",
}
export interface IActionSetSignalRState {
  type: ESignalRType.SET_SIGNALR_STATE;
  payload: { auditId: string; status: EReportStatus };
}

export interface IActionClearSignalR {
  type: ESignalRType.CLEAR_SIGNALR;
  payload: {};
}

export interface IActionSetSockerCrawlState {
  type: ESignalRType.SET_SOCKET_CRAWL_STATE;
  payload: { auditId: string; status: EReportStatus };
}

export interface IActionClearSockerCrawl {
  type: ESignalRType.CLEAR_SOCKET_CRAWL;
  payload: {};
}

export interface IActionSetPaymentSignalRState {
  type: ESignalRType.SET_PAYMENT_SIGNALR_STATE;
  payload: { orderId: string; status: string | null };
}

export interface IActionClearPaymentSignalR {
  type: ESignalRType.CLEAR_PAYMENT_SIGNALR;
  payload: {};
}

export type IActionSignalR =
  | IActionSetSignalRState
  | IActionClearSignalR
  | IActionSetSockerCrawlState
  | IActionClearSockerCrawl
  | IActionSetPaymentSignalRState
  | IActionClearPaymentSignalR;
