export const ROUTE_PATH = {
  //not authorization
  NOTFOUND: "404",
  LOGIN: "/login",
  REGISTER: "/register",

  INDEX: "/",
  MY_PROJECT: "/my-project",
  MY_PROJECT_DETAIL: "/my-project/:id",
  ABOUTUS: "/about-us",
  SERVICE: "/service",
  PRICING: "/pricing",
  PRICING_DETAIL: "/pricing/:id",
  PROFILE: "/profile",
  PROJECT: "/project",
  FREE_TOOLS: "/free-tools",
  DOMAIN_AUDIT: "/domain-audit",
  DOMAIN_AUDIT_SEO: "/domain-audit-seo",
  DOMAIN_AUDIT_UX: "/domain-audit-ux",
  DOMAIN_AUDIT_SEO_DETAIL: "/domain-audit-seo/:id",
  DOMAIN_AUDIT_UX_DETAIL: "/domain-audit-ux/:id",
  DOMAIN_AUDIT_ERROR_DETAIL: "/domain-audit/error/:id",
  DOMAIN_COMPETITIVE: "/domain-competitive",
  PAGE_AUDIT: "/page-audit",
  PAGE_COMPETITIVE: "/page-competitive",
  DOMAIN_AUDIT_DETAIL: "/domain-audit/:id",
  DOMAIN_COMPETITIVE_DETAIL: "/domain-competitive/:id",
  PAGE_AUDIT_DETAIL: "/page-audit/:id",
  PAGE_COMPETITIVE_DETAIL: "/page-competitive/:id",
  OPTIMIZE_HEADER: "/optimize-header",
  KEYWORD_DENSITY: "/keyword-density",
  POTENTIAL_KEYWORD: "/potential-keyword",
  KEYWORD_GROUP: "/keyword-group",
  COMPRESS_PHOTO: "/compress-photo",
  OUTLINE: "/outline",
  OUTLINE_POSTS: "/outline/:project_id",
  OUTLINE_POST_DETAIL: "/outline/:project_id/:id",
  DOMAIN_OVERVIEW: "/",
  BACKLINK: "/backlink",
  ORGANIC_KEYWORD: "/organic-keyword",
  DOMAIN_OVERVIEW_CHECK: "/check",
  BACKLINK_CHECK: "/backlink/check",
  ORGANIC_KEYWORD_CHECK: "/organic-keyword/check",
  PAYMENT: "/payment",
  MATERIAL: "/material",
};
