import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import {
  ImageCustom,
  LoadingIndicator,
  NoDataBox,
  NoSearchDataBox,
  TableDataGrey,
} from "../../components";
import InputSearch from "../../components/controls/InputSearch";
import { HomeWrapper } from "./styles";
import IconAdd from "../../assets/images/common/icon_plus_line_white.svg";
import { IBasePagingRes } from "../../models/common/models.type";
import { DEFAULT_BASE_PAGING_RES } from "../../common/constants/value.constant";
import { toggleConfirmModal } from "../../components/ConfirmModal/ConfirmModal";
import IconFile from "../../assets/images/common/icon_file_fill_green.png";
import moment from "moment";
import { DATETIME_FORMAT } from "../../config/constants";
import { toggleCreateProjectModal } from "../../components/Modal/CreateProjectModal";
import { IGetProjectReq, IProject } from "../../models/project";
import ProjectService from "../../services/api/project.service";
import { toggleMessage } from "../../components/Toast/Toast";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { ITableHeader } from "../../components/Table/TableDataGrey";
import { useTranslation } from "react-i18next";

const MyProject = () => {
  const timerOut = useRef<number>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataRes, setDataRes] = useState<IBasePagingRes<IProject>>({
    ...DEFAULT_BASE_PAGING_RES,
  });
  const [params, setParams] = useState<IGetProjectReq>({
    pageIndex: 1,
    pageSize: 10,
    keySearch: "",
  });
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    getProjectList();
  }, [params]);

  const onChangeSearch = (e: any) => {
    const value = e?.target?.value;
    if (timerOut.current) {
      clearTimeout(timerOut.current);
    }
    timerOut.current = window.setTimeout(() => {
      setParams({
        pageIndex: 1,
        pageSize: 10,
        keySearch: value,
      });
    }, 700);
  };

  const asyncFuncDelete: (id: string) => Promise<void> = async (id: string) => {
    const res = await ProjectService.deleteProject({ id: id });
    if (!!res && !res.isError) {
      toggleMessage({
        type: "success",
        title: `${t("Common.DeleteSuccessTitle")}`,
        message: `${t("Common.DeleteProjectSuccessDescription")}`,
      });
      setParams({
        pageIndex: 1,
        pageSize: 10,
        keySearch: "",
      });
    } else {
      toggleMessage({
        type: "error",
        title: `${t("Common.DeleteFailTitle")}`,
        message: `${t("Common.DeleteProjectFailDescription")}`,
      });
    }
  };

  const headersTable: ITableHeader<any>[] = [
    {
      field: "projectName",
      title: `${t("Common.ProjectName")}`,
      width: "350px",
      isSticky: true,
      handleItem: item => {
        return (
          <Button
            className="table-name-project"
            onClick={() => {
              if (!!item.projectId) {
                handleNavigateDetail(item.projectId);
              }
            }}
          >
            <ImageCustom
              src={IconFile}
              alt="Icon-File"
              width={"20px"}
              height={"20px"}
            />
            <span className="fit-one-line">{item.projectName}</span>
          </Button>
        );
      },
    },
    {
      field: "createdAt",
      title: `${t("Common.CreatedAt")}`,
      width: "200px",
      handleItem: item => {
        return moment(item.createdAt).format(DATETIME_FORMAT);
      },
    },
    {
      field: "totalReport",
      title: `${t("Common.NumbersReport")}`,
      width: "250px",
    },
  ];

  const getProjectList = async () => {
    setIsLoading(true);
    const resData = await ProjectService.getProjectList(params);
    if (!!resData && !resData.isError) {
      setDataRes(resData.data);
      setIsLoading(false);
    } else {
      setDataRes({
        ...DEFAULT_BASE_PAGING_RES,
      });
      toggleMessage({
        type: "error",
        title: `${t("Common.Fail")}`,
        message: `${t("Common.GetProjectsFail")}`,
      });
      setIsLoading(false);
    }
  };
  const handleNavigateDetail = (id: string) => {
    const pathResult = generatePath(ROUTE_PATH.MY_PROJECT_DETAIL, {
      id: id,
    });
    navigate(pathResult);
  };

  return (
    <HomeWrapper>
      <Helmet>
        <title>{t("Common.Project")}</title>
      </Helmet>
      <div className="myPJ-header">
        <div className="myPJ-title-wrapper">
          <div className="myPJ-title">
            <p className="header-name fit-one-line">{t("Common.MyProject")}</p>
            <p className="header-count">
              {dataRes.total} {t("Common.Project")}
            </p>
          </div>
        </div>
        <div className="header-action">
          <InputSearch
            placeholder={t("Common.Search")}
            onChange={e => {
              onChangeSearch(e);
              setSearchText(e.target.value);
            }}
            value={searchText}
            size={"medium"}
            width={"198px"}
            typeStyle="outlined"
          />
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={_ =>
              toggleCreateProjectModal({
                open: true,
                onCreateSuccess: () => {
                  setParams({
                    pageIndex: 1,
                    pageSize: 10,
                    keySearch: "",
                  });
                },
              })
            }
            className="add-button"
          >
            <ImageCustom
              src={IconAdd}
              alt="Icon-Add"
              width={"18px"}
              height={"18px"}
            />
            <p>{t("Common.CreateProject")}</p>
          </Button>
        </div>
      </div>
      <div className="list-project">
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {dataRes.items.length > 0 ? (
              <TableDataGrey
                data={dataRes}
                keyField={"projectId"}
                headersTable={headersTable}
                loading={isLoading}
                handleDeleteData={(id: string) => {
                  toggleConfirmModal({
                    open: true,
                    title: `${t("ModalText.DeleteProjectTitle")}`,
                    content: `${t("ModalText.DeleteProjectDescription")}`,
                    onSubmit: () => asyncFuncDelete(id.toString()),
                    submitText: `${t("ModalText.Delete")}`,
                    type: "warning-red",
                  });
                }}
                handleEditData={(id: string) => {
                  const dataAction = dataRes.items.find(
                    item => item.projectId === id
                  );
                  if (!!dataAction) {
                    toggleCreateProjectModal({
                      open: true,
                      onUpdateSuccess: () => {
                        setParams({
                          pageIndex: 1,
                          pageSize: 10,
                          keySearch: "",
                        });
                      },
                      dataProject: dataAction,
                    });
                  }
                }}
                handleOpenDetail={(id: string) => {
                  handleNavigateDetail(id);
                }}
                handleChangeSearchParams={(page: number) =>
                  setParams({ ...params, pageIndex: page })
                }
              />
            ) : dataRes.items.length <= 0 &&
              !!params.keySearch &&
              params.keySearch?.length > 0 ? (
              <NoSearchDataBox
                inputSearch={params.keySearch}
                onClearSearch={() => {
                  setSearchText("");
                  setParams(prev => ({ ...prev, keySearch: "" }));
                }}
                openCreateModal={() =>
                  toggleCreateProjectModal({
                    open: true,
                    onCreateSuccess: () => {
                      setParams({
                        pageIndex: 1,
                        pageSize: 10,
                        keySearch: "",
                      });
                    },
                  })
                }
                openText={t("Common.CreateProject")}
              />
            ) : (
              <NoDataBox
                title="Chưa có dự án nào được tạo"
                description="Tạo dự án để sử dụng các báo cáo của chúng tôi"
                onClick={() => {
                  toggleCreateProjectModal({
                    open: true,
                    onCreateSuccess: () => {
                      setParams({
                        pageIndex: 1,
                        pageSize: 10,
                        keySearch: "",
                      });
                    },
                  });
                }}
                buttonText={t("Common.CreateProject")}
              />
            )}
          </>
        )}
      </div>
    </HomeWrapper>
  );
};

export default MyProject;
