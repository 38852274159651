import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PlanPopoverWrapper, SidebarWrapper } from "./styles";
import { Avatar, ImageCustom, PlanBox } from "..";
import { Button, Tooltip, useMediaQuery } from "@mui/material";
import ArrowLeft from "../../assets/images/common/icon_arrow_left_line_black.svg";
import IconPlan from "../../assets/images/common/icon_plan_fill_blue.svg";
import { sidebarItems } from "../../routes";
import { INavItem } from "../../models/app";
import { useSelector } from "react-redux";
import { IStateApp } from "../../models/redux";
import { IUser } from "../../models/user";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import CommonHandle from "../../common/handles/common.handles";

const DashboardSidebar = () => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const matches830 = useMediaQuery("(max-width:830px)");
  const matches1070 = useMediaQuery("(max-width:1070px)");

  const currentUser = useSelector<IStateApp, IUser>(
    appState => appState.user.data
  );
  const [notExpaned, setNotExpaned] = useState<boolean>(
    matches830 ? true : false
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (matches1070 && !notExpaned) {
      setNotExpaned(true);
    }
  }, []);

  const sidebarData = sidebarItems();
  const checkActive = (data: INavItem) => {
    const paths = location.pathname.split("/").filter(path => path !== id);
    if (
      (location.pathname === "/" || paths.length === 1) &&
      data.code === "domain-overview"
    ) {
      return true;
    } else if (
      location.pathname === ROUTE_PATH.DOMAIN_OVERVIEW_CHECK &&
      paths.length === 2 &&
      data.code === "domain-overview"
    ) {
      return true;
    } else {
      const result = paths.find(
        path => path === data.code && data.code !== "domain-overview"
      );
      if (result !== undefined && result.length > 0) {
        return true;
      }
    }
    return false;
  };
  return (
    <>
      <PlanPopoverWrapper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PlanBox />
      </PlanPopoverWrapper>
      <SidebarWrapper notexpanded={notExpaned}>
        <Button
          className={`expanded-button ${notExpaned ? "notExpanded" : ""}`}
          size="large"
          variant="text"
          onClick={() => setNotExpaned(!notExpaned)}
        >
          <ImageCustom
            src={ArrowLeft}
            alt="Arrow-Left"
            width={"24px"}
            height={"24px"}
          />
        </Button>
        <div className="sidebar-box">
          <div className="user-infor-wrapper">
            <div className={`user-infor ${notExpaned ? "notExpanded" : ""}`}>
              <div className="infor-box">
                <figure className="user-avatar">
                  <Avatar src={currentUser.avatar} alt="Avatar-Image" />
                </figure>
                <div className="user-name">
                  <p className="name-user fit-one-line">
                    {currentUser.companyName || currentUser.fullName}
                  </p>
                  <Tooltip title={currentUser.email} placement="top-start">
                    <p className="user-email fit-one-line">
                      {CommonHandle.truncateText(currentUser.email || "", 22)}
                    </p>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <ul
            className={`sidebar-menu ${notExpaned ? "notExpanded" : ""} scrollbar-small`}
          >
            {sidebarData.map(sidebar => (
              <li
                key={sidebar.title}
                className={`sidebar-item ${notExpaned ? "notExpanded" : ""} ${sidebar.title.length > 0 ? "has-text" : ""}`}
              >
                {sidebar.title.length > 0 && (
                  <p
                    className={`sidebar-title ${notExpaned ? "notExpanded" : ""}`}
                  >
                    {sidebar.title}
                  </p>
                )}
                <ul className="sidebar-children">
                  {sidebar.children &&
                    sidebar.children.map((child, idx) => (
                      <li
                        key={idx}
                        className={`children-item ${checkActive(child) ? "active" : ""}`}
                      >
                        <Tooltip
                          title={!notExpaned ? "" : child?.title}
                          placement="right"
                        >
                          <button
                            onClick={() => {
                              if (
                                !!child.href &&
                                child.href !== location.pathname
                              ) {
                                navigate(child.href || "");
                              }
                              if (matches1070) {
                                setNotExpaned(true);
                              }
                            }}
                          >
                            <div className="icon-box">
                              <ImageCustom
                                src={child?.icon?.default}
                                alt="Sidebar-Icon"
                                width={"20px"}
                                height={"20px"}
                                className={`box-default ${checkActive(child) ? "active" : ""}`}
                              />
                              <ImageCustom
                                src={child?.icon?.active}
                                alt="Sidebar-Icon"
                                width={"20px"}
                                height={"20px"}
                                className={`box-active ${checkActive(child) ? "active" : ""}`}
                              />
                            </div>
                            <p className={`${notExpaned ? "notExpanded" : ""}`}>
                              {child?.title}
                            </p>
                          </button>
                        </Tooltip>
                      </li>
                    ))}
                </ul>
              </li>
            ))}
          </ul>
          <div
            className={`plan-box-wrapper ${notExpaned ? "notExpanded" : ""}`}
          >
            <PlanBox className="plan-box" />
            <Button
              size="xsmall"
              variant="contained"
              className="not-expanded-button"
              onClick={event => setAnchorEl(event.currentTarget)}
              color="secondary"
            >
              <ImageCustom
                src={IconPlan}
                alt="Plan-Icon"
                width={"18px"}
                height={"18px"}
              />
            </Button>
          </div>
        </div>
      </SidebarWrapper>
    </>
  );
};

export default DashboardSidebar;
