import { ILang } from "../i18n";

const EN: ILang = {
  Lang: {
    Code: "EN",
    TextEN: "English",
    TextVN: "Vietnamese",
  },
  Common: {
    Update: "Update",
    Logout: "Log out",
    Login: "Log in",
    Profile: "Profile",
    Setting: "Setting",
    Support: "Support",
    Overview: "Overview",
    Project: "Project",
    MyProject: "My Project",
    CreateProject: "Create project",
    EnterLink: "Enter URL",
    Type: "Type",
    ChooseType: "Choose type",
    Search: "Search",
    CreatedAt: "Creation date",
    Status: "Status",
    ChooseStatus: "Choose status",
    Domain: "Domain",
    ReportType: "Report type",
    Report: "Report",
    CreateReport: "Create Report",
    DownloadReport: "Download Report",
    Share: "Share",
    Filter: "Filter",
    DisplayBy: "Display by",
    Delete: "Delete",
    Open: "Open",
    ChooseTime: "Select time",
    DeleteFilter: "Delete filter",
    Apply: "Apply",
    ProjectName: "Project name",
    NumbersReport: "Number of reports",
    Success: "Success",
    Fail: "Fail",
  },
  HeaderMenu: {
    Aboutus: "About us",
    Service: "Service",
    Pricing: "Pricing",
    Project: "Project",
    FreeTools: "Free tools",
  },
  Login: {
    ErrorEmail: "Please enter email",
    LoginDescription: "Enter your email and password to login",
  },
  ReportHeader: {},
  FooterHeader: {},
  ModalText: {
    LogoutModalTitle: "Log out",
    LogoutModalDescription: "Do you want to log out of this device?",
    Cancel: "Cancel",
    Confirm: "Confirm",
    LoginSessionTitle: "Login Session",
    LoginSessionDescription:
      "Your session has expired, please log in again to continue!",
    DeleteSuccessTitle: "Delete successful",
    DeleteReportSuccessDescription: "Report deleted successfully!",
    DeleteFailTitle: "Delete failed",
    DeleteReportFailDescription: "Delete report failed!",
    DeleteReportTitle: "Delete report",
    DeleteReportDescription: "Are you sure you want to delete this report?",
    DeleteProjectTitle: "Xoá dự án",
    DeleteProjectDescription: "Bạn có chắc chắc muốn xoá dự án này không?",
    GetProjectsFail: "Get list of failed projects!",
  },
  NoData: {
    NoProjectCreatedTitle: "No reports have been created yet.",
    NoProjectCreatedDescription: "Create reports to use our reports",
  },
};

export default EN;
