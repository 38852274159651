import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ControlForm } from "..";
import InputForm from "../controls/InputForm";
import { FormCheckLinkWrapper } from "./styles";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectCustom from "../SelectCustom";
import { Button } from "@mui/material";
import { ETypeLinkCheck } from "../../models/common/models.enum";
import { generatePath, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

interface FormCheckLinkProps {
  title: string;
  description: string;
  link: string;
}

export default function FormCheckLink(props: FormCheckLinkProps) {
  const { title, description, link } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [typeLink, setTypeLink] = useState<ETypeLinkCheck>(
    ETypeLinkCheck.RootDomain
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const rexgexPage =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  const validationDomainCompetitiveSchema = Yup.object().shape({
    target: Yup.string()
      .matches(rexgexPage, "Is not in correct format")
      .required(`${"Please enter your domain"}`),
  }) as any;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      target: "",
    },
    resolver: yupResolver(validationDomainCompetitiveSchema),
  });

  const onSubmit = async (value: any) => {
    const path = generatePath(link);
    const newParams = queryString.stringify({ ...value, type: typeLink });
    navigate({
      pathname: `${path}`,
      search: `${newParams}`,
    });
  };

  return (
    <FormCheckLinkWrapper>
      <div className="form-wrapper">
        <div className="input-wrapper flex-start-align-bottom">
          <ControlForm
            title={t("Common.EnterLink")}
            isRequired
            classname="control-form-link"
          >
            <InputForm
              placeholder="https://..."
              required
              name={"target"}
              errors={errors}
              control={control}
              size="small"
            />
          </ControlForm>
          <ControlForm
            title={t("Common.Type")}
            isRequired
            classname="control-form-type"
          >
            <SelectCustom
              options={[
                { title: "Root domain", value: ETypeLinkCheck.RootDomain },
                { title: "Exact url", value: ETypeLinkCheck.ExactUrl },
              ]}
              onChange={event => {
                setTypeLink(event.target.value);
              }}
              placeholder={t("Common.ChooseType")}
              isSelectedIcon
              value={typeLink}
            />
          </ControlForm>
          <Button
            size="small"
            variant="contained"
            className="button-submit"
            onClick={handleSubmit(onSubmit)}
          >
            {isLoading && <span className="loader"></span>}
            <p className={`text-submit ${isLoading ? "is-loading" : ""}`}>
              {t("Common.Search")}
            </p>
          </Button>
        </div>
      </div>
    </FormCheckLinkWrapper>
  );
}
