import { EReportStatus } from "../../models/common/models.enum";
import {
  ESignalRType,
  IActionClearPaymentSignalR,
  IActionClearSignalR,
  IActionClearSockerCrawl,
  IActionSetPaymentSignalRState,
  IActionSetSignalRState,
  IActionSetSockerCrawlState,
  IActionSignalR,
  IReduxSignalRState,
} from "../../models/redux/signalR";

export const setSignalRState = (
  auditId: string,
  status: EReportStatus
): IActionSetSignalRState => ({
  type: ESignalRType.SET_SIGNALR_STATE,
  payload: { auditId, status },
});

export const clearSignalR = (): IActionClearSignalR => ({
  type: ESignalRType.CLEAR_SIGNALR,
  payload: {},
});

export const setSocketCrawlState = (
  auditId: string,
  status: EReportStatus
): IActionSetSockerCrawlState => ({
  type: ESignalRType.SET_SOCKET_CRAWL_STATE,
  payload: { auditId, status },
});

export const clearSocketCrawl = (): IActionClearSockerCrawl => ({
  type: ESignalRType.CLEAR_SOCKET_CRAWL,
  payload: {},
});

export const setPaymentSignalRState = (
  orderId: string,
  status: string
): IActionSetPaymentSignalRState => ({
  type: ESignalRType.SET_PAYMENT_SIGNALR_STATE,
  payload: { orderId, status },
});

export const clearPaymentSignalR = (): IActionClearPaymentSignalR => ({
  type: ESignalRType.CLEAR_PAYMENT_SIGNALR,
  payload: {},
});

const defaultState: IReduxSignalRState = {
  audit: {
    auditId: "",
    status: null,
  },
  auditCrawl: {
    auditId: "",
    status: null,
  },
  paymentAudit: {
    orderId: "",
    status: null,
  },
};

export default function signalRReducer(
  state: IReduxSignalRState = defaultState,
  action: IActionSignalR
): IReduxSignalRState {
  switch (action.type) {
    case ESignalRType.SET_SIGNALR_STATE:
      return { ...state, audit: action.payload };
    case ESignalRType.CLEAR_SIGNALR:
      return { ...state, audit: defaultState.audit };
    case ESignalRType.SET_SOCKET_CRAWL_STATE:
      return { ...state, auditCrawl: action.payload };
    case ESignalRType.CLEAR_SOCKET_CRAWL:
      return { ...state, auditCrawl: defaultState.audit };
    case ESignalRType.SET_PAYMENT_SIGNALR_STATE:
      return { ...state, paymentAudit: action.payload };
    case ESignalRType.CLEAR_PAYMENT_SIGNALR:
      return { ...state, paymentAudit: defaultState.paymentAudit };
    default:
      return state;
  }
}
