import React, { forwardRef, useEffect, useState } from "react";
import OverviewCommonCaption from "../../OverviewCommonCaption";
import OverviewCommonSubTitle from "../../OverviewCommonSubTitle";
import OverviewCommonTitle from "../../OverviewCommonTitle";
import { UXSectionWrapper } from "./styles";
import { Box, Divider } from "@mui/material";
import Quote from "../../Quote";
import Google from "../../../../../assets/images/domai-audit/security-section/google.png";
import { ImageCustom, LoadingIndicator } from "../../../../../components";
import ScreenshotOnDevice, { EStrategy } from "./components/ScreenshotOnDevice";
import FullWidthTabs from "../../../../../components/FullWidthTabsTime";
import moment from "moment";
import { DATETIME_FORMAT } from "../../../../../config/constants";
import PageSpeedOnDevice from "./components/PageSpeedOnDevice";
import { IGooglePageSpeed } from "../../../../../models/google/google.page.speed";
import IconDesktop from "../../../../../assets/images/common/icon_desktop_line_grey_audit.svg";
import IconPhone from "../../../../../assets/images/common/icon_phone_line_grey_audit.svg";
import IconDesktopActive from "../../../../../assets/images/common/icon_desktop_fill_blue_audit.svg";
import IconPhoneActive from "../../../../../assets/images/common/icon_phone_fill_blue_audit.svg";
import AuditReportService from "../../../../../services/api/audit.report.service";
import { EAuditErrorStatus } from "../../../../../models/common/models.enum";
import { useSelector } from "react-redux";
import { IStateApp } from "../../../../../models/redux";
import { IGetReportsRes } from "../../../../../models/report";
import CrawlPageDataBox from "./components/CrawlPageDataBox";
import { getAuditStatus } from "../../../../../common/handles/common.handles";
import { useLocation } from "react-router-dom";
import { ROUTE_PATH } from "../../../../../common/constants/app.constant";

enum EStrategyTab {
  desktop,
  mobile,
}

const UXSection = forwardRef<any, any>(function (props, ref) {
  const [activeTab, setActiveTab] = useState<EStrategyTab>(
    EStrategyTab.desktop
  );
  const [pageSpeedDesktopData, setPageSpeedDesktopData] =
    useState<IGooglePageSpeed>();
  const [pageSpeedMobileData, setPageSpeedMobileData] =
    useState<IGooglePageSpeed>();

  useEffect(() => {
    if (!!props.props) {
      checkPerformance(props.props);
    }
  }, [props.props]);

  const checkPerformance = (data: any) => {
    // const language = "vi"; //Lang.language.toLowerCase();
    if (!!data.pageSpeed) {
      setPageSpeedDesktopData(data.pageSpeed.desktop);
      setPageSpeedMobileData(data.pageSpeed.mobile);
      if (activeTab !== EStrategyTab.desktop && !!data.pageSpeed.desktop) {
        setActiveTab(EStrategyTab.desktop);
      } else if (
        activeTab === EStrategyTab.desktop &&
        !data.pageSpeed.desktop &&
        !!data.pageSpeed.mobile
      ) {
        setActiveTab(EStrategyTab.mobile);
      }
    }
  };

  const score = (pageSpeedData?: IGooglePageSpeed) => {
    let value = 0;
    if (pageSpeedData) {
      value = pageSpeedData.lighthouseResult.categories.performance?.score;
    }

    return value;
  };

  const desktopScore = score(pageSpeedDesktopData);
  const mobileScore = score(pageSpeedMobileData);

  const listMobileFriendlyId = {
    performance: mobileScore >= 50,
    "color-contrast": !!pageSpeedMobileData
      ? getAuditStatus(
          pageSpeedMobileData?.lighthouseResult.audits["color-contrast"],
          0
        ) === EAuditErrorStatus.Success
      : false,
    "paste-preventing-inputs": !!pageSpeedMobileData
      ? getAuditStatus(
          pageSpeedMobileData?.lighthouseResult.audits[
            "paste-preventing-inputs"
          ],
          0
        ) === EAuditErrorStatus.Success
      : false,
    "target-size": !!pageSpeedMobileData
      ? getAuditStatus(
          pageSpeedMobileData?.lighthouseResult.audits["target-size"],
          0
        ) === EAuditErrorStatus.Success
      : false,
    "image-aspect-ratio": !!pageSpeedMobileData
      ? getAuditStatus(
          pageSpeedMobileData?.lighthouseResult.audits["image-aspect-ratio"],
          0
        ) === EAuditErrorStatus.Success
      : false,
    "image-size-responsive": !!pageSpeedMobileData
      ? getAuditStatus(
          pageSpeedMobileData?.lighthouseResult.audits["image-size-responsive"],
          0
        ) === EAuditErrorStatus.Success
      : false,
    "font-size": !!pageSpeedMobileData
      ? getAuditStatus(
          pageSpeedMobileData?.lighthouseResult.audits["font-size"],
          0
        ) === EAuditErrorStatus.Success
      : false,
  };

  return (
    <UXSectionWrapper
      className="common-layout"
      id={"security-section"}
      ref={ref}
    >
      <OverviewCommonTitle
        title="Trải nghiệm người dùng"
        description="Trải nghiệm người dùng đề cập đến tổng thể quá trình tương tác, trải nghiệm của người dùng trên website từ việc tìm kiếm thông tin, mua sắm hay các hành động khác. Tối ưu trải nghiệm người dùng giúp tăng tương tác, cải thiện tỷ lệ chuyển đổi, giữ chân khách truy cập và xây dựng uy tín thương hiệu."
        className="security-title"
        scores={Math.round(((desktopScore + mobileScore) / 2) * 100)}
      />
      <div className="security-wrapper">
        <OverviewCommonSubTitle title="Hiệu suất Website" />
        <Quote
          content="Hiệu suất website là một thước đo phản ánh tốc độ, độ mượt mà và khả năng đáp ứng của một trang web khi người dùng truy cập. Nó ảnh hưởng trực tiếp đến trải nghiệm người dùng, tỷ lệ chuyển đổi và xếp hạng SEO của trang web."
          sx={{
            marginTop: "15px",
            borderRadius: "8px",
          }}
        />
        <div className="security-index-wrapper">
          <div className="security-index-box">
            <Box
              className="flex-between-align-center"
              sx={{ width: "100%", padding: "24px" }}
            >
              <OverviewCommonCaption
                className="index-box-caption"
                width="420px"
                caption="Hiệu suất website trên loại thiết bị"
                description="Tùy vào mỗi loại thiết bị khác nhau như máy tính hay điện thoại di động, tốc độ tải trang có thể chênh lệch đáng kể do sự khác biệt về cấu hình phần cứng, kết nối mạng và kích thước màn hình. Điều này đòi hỏi website cần được tối ưu hóa nhằm đảm bảo trải nghiệm người dùng tốt nhất trên mọi thiết bị."
              />
              <ImageCustom
                src={Google}
                alt="Google logo"
                width={92}
                height={53}
              />
            </Box>
            <Divider />
            <Box className="flex-center" sx={{ mt: "32px" }}>
              <ScreenshotOnDevice
                strategy={EStrategy.desktop}
                score={desktopScore}
                screenshot={
                  pageSpeedDesktopData?.lighthouseResult?.fullPageScreenshot
                    .screenshot.data
                }
              />
              <ScreenshotOnDevice
                strategy={EStrategy.mobile}
                score={mobileScore}
                screenshot={
                  pageSpeedMobileData?.lighthouseResult?.fullPageScreenshot
                    .screenshot.data
                }
              />
            </Box>
          </div>
        </div>
      </div>
      {(!!pageSpeedDesktopData || !!pageSpeedMobileData) && (
        <Box className="performance-on-strategy-wrapper">
          <FullWidthTabs
            className="tabs"
            time={moment(pageSpeedDesktopData?.analysisUTCTimestamp).format(
              DATETIME_FORMAT
            )}
            tabs={[
              {
                lable: {
                  text: "Máy tính",
                  icon: IconDesktop,
                  iconActive: IconDesktopActive,
                },
                value: EStrategyTab.desktop,
                content: (
                  <PageSpeedOnDevice
                    strategy={EStrategy.desktop}
                    pageSpeedData={pageSpeedDesktopData}
                  />
                ),
              },
              {
                lable: {
                  text: "Di động",
                  icon: IconPhone,
                  iconActive: IconPhoneActive,
                },
                value: EStrategyTab.mobile,
                content: (
                  <PageSpeedOnDevice
                    strategy={EStrategy.mobile}
                    pageSpeedData={pageSpeedMobileData}
                  />
                ),
              },
            ]}
            value={activeTab}
            onChange={tab => setActiveTab(tab)}
          />
        </Box>
      )}
      <div className="error-crawl-page-wrapper">
        <CrawlPageDataBox
          auditReportData={props.props}
          dataMobilePerformance={listMobileFriendlyId}
        />
      </div>
    </UXSectionWrapper>
  );
});
export default UXSection;
